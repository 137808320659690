header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 102px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: relative;
  max-width: 100%;
  width: 100%;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .header__logo {
      margin-right: 30px;

      .header__logo-link {
        .header__logo-link-image {
          width: 186px;
          height: auto;
        }
      }
    }

    .toggle__menu {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      #horizontal-multilevel-menu {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        transition: $transition;

        & > li {
          height: 101px;
          margin-right: 15px;
          font-size: 18px;

          &:nth-child(5) {
            display: none;
          }

          .root-item {
            height: 100%;
            color: $main;
            font-family: $main-font;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
          }

          .root-item-selected {
            height: 100%;
            font-family: $main-font;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #3162b9;
            text-decoration: none;
          }

          &.parent-item {
            position: relative;
            transition: $transition;

            &::after {
              position: absolute;
              bottom: 20px;
              left: 50%;
              transform: translateX(-50%);
              content: '';
              width: 12px;
              height: 12px;
              background-image: url('/bitrix/templates/trendspb_enerpro/images/svg/caret.svg');
              background-color: transparent;
              background-size: 100%;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              transition: $transition;
            }

            .sub-menu {
              display: none;
            }

            &:hover {
              &::after {
                transform: translateX(-50%) rotate(180deg);
              }

              .sub-menu {
                display: flex;
              }
            }

            .sub-menu {
              width: auto;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              background-color: #fff;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
              border-bottom-right-radius: 5px;
              border-bottom-left-radius: 5px;
              position: absolute;
              top: auto;
              z-index: 500;
              height: auto;

              li {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                min-width: 300px;
                min-height: 40px;
                padding: 15px 15px 15px 10px;
                position: relative;
                transition: $transition;
                order: 2;

                &.parent-item {
                  order: 1;

                  .parent {
                    position: relative;
                    transition: $transition;

                    &::after {
                      position: absolute;
                      top: 50%;
                      right: 0;
                      transform: translateY(-50%) rotate(-90deg);
                      content: '';
                      width: 12px;
                      height: 12px;
                      background-image: url('/bitrix/templates/trendspb_enerpro/images/svg/caret.svg');
                      background-color: transparent;
                      background-size: 100%;
                      background-position: 50% 50%;
                      background-repeat: no-repeat;
                      transition: $transition;
                    }

                    &:hover {
                      &::after {
                        transform: translateY(-50%) rotate(90deg);
                      }
                    }
                  }

                  &.item-selected {
                    .parent {
                      color: #3162b9;
                    }
                  }
                }

                a {
                  height: 100%;
                  text-align: left;
                  color: #3162b9;
                  font-family: $main-font;
                  text-decoration: none;
                }

                &.item-selected > a {
                  color: #3162b9;
                }

                .sub-menu-2 {
                  width: auto;
                  display: none;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  background-color: #fff;
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                  top: 0;
                  right: -100%;
                  position: absolute;
                  z-index: 500;
                  height: auto;

                  li {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    min-width: 300px;
                    min-height: 40px;
                    padding: 15px 25px 15px 10px;
                    transition: $transition;

                    &:hover {
                      background-color: #e5e5e5;
                    }

                    a {
                      height: 100%;
                      text-align: left;
                      color: #3162b9;
                      font-family: $main-font;
                    }

                    &.item-selected > a {
                      color: #3162b9;
                    }
                  }
                }

                &:hover {
                  background-color: #e5e5e5;
                   
                  .sub-menu-2 {
                    display: flex;
                  }
                }
              }
            }
          }
        }
      }

      #search_top {
        height: 101px;

        form {
          position: relative;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          .search-suggest {
            position: absolute;
            right: 0;
            height: 40px;
            line-height: 40px;
            width: 300px;
            box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
            background-color: rgba(255, 255, 255, 0.8);
            outline: none;
            border: 1px solid #e5e5e5;
            bottom: 0;
            transform: translateY(100%);
            padding-left: 15px;
            font-family: $main-font;
            color: $main;
            opacity: 0;
            visibility: hidden;
            z-index: -999;
            transition: 1s ease-out;

            &::placeholder {
              color: $main;
              height: 40px;
              line-height: 40px;
            }

            &:focus {
              opacity: 1;
              visibility: visible;
              z-index: 999;
            }
          }

          .search-button {
            background-image: url('/bitrix/templates/trendspb_enerpro/images/svg/search.svg');
            background-color: transparent;
            background-size: 19px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            border: none;
            border-radius: 50%;
            color: transparent;
            font-size: 0;
            width: 30px;
            height: 30px;
            outline: none;
            transition: 0.2s ease-in-out;
            user-select: none;
            padding: 0;
            cursor: pointer;
          }

          &:hover .search-suggest {
            opacity: 1;
            visibility: visible;
            z-index: 999;
          }
        }
      }
    }

    .header__contacts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      .header__contacts-phone a {
        color: #3162b9;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        text-decoration: none;
      }

      .header__contacts-email a {
        color: #3162b9;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
}