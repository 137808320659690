.not-found-page__wrapper {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 252px);

  .container {
    padding: 50px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .not-found-page__title {
      color: #000;
      font-family: $main-font;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      max-width: 100%;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 45px;
    }

    .not-found-page__subtitle {
      color: #000;
      font-family: $main-font;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      max-width: 100%;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 25px;
    }

    .map-columns {
      max-width: 75%;
      width: 100%;

      tbody {
        tr {
          td {
            padding: 0 25px;

            ul {
              li {
                text-align: center;

                a {
                  font-family: $main-font;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}