@font-face {
  font-family: 'FuturaFuturisC';
  src: url('../../fonts/FuturaFuturisC-Italic.eot');
  src: local('../../fonts/FuturaFuturisC Italic'), local('../../fonts/FuturaFuturisC-Italic'),
  url('../../fonts/FuturaFuturisC-Italic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/FuturaFuturisC-Italic.woff') format('woff'),
  url('../../fonts/FuturaFuturisC-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'FuturaFuturisBlackC';
  src: url('../../fonts/FuturaFuturisBlackC.eot');
  src: local('../../fonts/FuturaFuturisBlackC'),
  url('../../fonts/FuturaFuturisBlackC.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/FuturaFuturisBlackC.woff') format('woff'),
  url('../../fonts/FuturaFuturisBlackC.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaFuturisC';
  src: url('../../fonts/FuturaFuturisC.eot');
  src: local('../../fonts/FuturaFuturisC'),
  url('../../fonts/FuturaFuturisC.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/FuturaFuturisC.woff') format('woff'),
  url('../../fonts/FuturaFuturisC.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaFuturisShadowC';
  src: url('../../fonts/FuturaFuturisShadowC.eot');
  src: local('../../fonts/FuturaFuturisShadowC'),
  url('../../fonts/FuturaFuturisShadowC.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/FuturaFuturisShadowC.woff') format('woff'),
  url('../../fonts/FuturaFuturisShadowC.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaFuturisVolumeLightC';
  src: url('../../fonts/FuturaFuturisVolumeLightC.eot');
  src: local('../../fonts/FuturaFuturisVolumeLightC'),
  url('../../fonts/FuturaFuturisVolumeLightC.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/FuturaFuturisVolumeLightC.woff') format('woff'),
  url('../../fonts/FuturaFuturisVolumeLightC.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaFuturisC';
  src: url('../../fonts/FuturaFuturisC-BoldItalic.eot');
  src: local('../../fonts/FuturaFuturisC Bold Italic'), local('FuturaFuturisC-BoldItalic'),
  url('../../fonts/FuturaFuturisC-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/FuturaFuturisC-BoldItalic.woff') format('woff'),
  url('../../fonts/FuturaFuturisC-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'FuturaFuturisCondBoldC';
  src: url('../../fonts/FuturaFuturisCondBoldC.eot');
  src: local('FuturaFuturisCondBoldC'),
  url('../../fonts/FuturaFuturisCondBoldC.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/FuturaFuturisCondBoldC.woff') format('woff'),
  url('../../fonts/FuturaFuturisCondBoldC.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaFuturisShadowC';
  src: url('../../fonts/FuturaFuturisShadowC-Bold.eot');
  src: local('../../fonts/FuturaFuturisShadowC Bold'), local('FuturaFuturisShadowC-Bold'),
  url('../../fonts/FuturaFuturisShadowC-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/FuturaFuturisShadowC-Bold.woff') format('woff'),
  url('../../fonts/FuturaFuturisShadowC-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaFuturisLightC';
  src: url('../../fonts/FuturaFuturisLightC.eot');
  src: local('../../fonts/FuturaFuturisLightC'),
  url('../../fonts/FuturaFuturisLightC.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/FuturaFuturisLightC.woff') format('woff'),
  url('../../fonts/FuturaFuturisLightC.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaFuturisCameoExtraBoldC';
  src: url('../../fonts/FuturaFuturisCameoExtraBoldC.eot');
  src: local('../../fonts/FuturaFuturisCameoExtraBoldC'),
  url('../../fonts/FuturaFuturisCameoExtraBoldC.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/FuturaFuturisCameoExtraBoldC.woff') format('woff'),
  url('../../fonts/FuturaFuturisCameoExtraBoldC.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaFuturisC';
  src: url('../../fonts/FuturaFuturisC-Bold.eot');
  src: local('../../fonts/FuturaFuturisC Bold'), local('../../fonts/FuturaFuturisC-Bold'),
  url('../../fonts/FuturaFuturisC-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/FuturaFuturisC-Bold.woff') format('woff'),
  url('../../fonts/FuturaFuturisC-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaFuturisCondExtraBoldC';
  src: url('../../fonts/FuturaFuturisCondExtraBoldC.eot');
  src: local('../../fonts/FuturaFuturisCondExtraBoldC'),
  url('../../fonts/FuturaFuturisCondExtraBoldC.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/FuturaFuturisCondExtraBoldC.woff') format('woff'),
  url('../../fonts/FuturaFuturisCondExtraBoldC.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
