.page__subtitle-button {
  display: none;
}

@media screen and (max-width: 425px) {
  .hangar-page__wrapper {
    padding-top: 40px;

    .container {
      .page__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
      }

      .page__sizes {
        .page__sizes-links {
          max-width: 100%;
          width: 100%;

          .page__sizes-links-row {
            max-width: 100%;
            width: 100%;
            display: block;
            flex-direction: column;
            align-items: flex-start;
            height: 44px;
            border-radius: 30px;
            border: 1px solid #cecdca;
            overflow: hidden;
            position: relative;
            transition: $transition-01;

            &::after {
              position: absolute;
              right: 22px;
              top: 22px;
              transform: translateY(-50%);
              content: '';
              background-image: url(../../images/svg/caret-litle.svg);
              background-position: 50% 50%;
              -webkit-background-size: contain;
              background-size: contain;
              background-repeat: no-repeat;
              width: 9px;
              height: 6px;
              transition: $transition-01;
            }

            &.active {
              height: auto;

              &::after {
                transform: translateY(-50%) rotate(180deg);
              }
            }

            .page__sizes-links-title {
              max-width: 100%;
              width: 100%;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              height: 44px;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              padding-left: 20px;
            }

            .page__sizes-links-item {
              max-width: 100%;
              width: 100%;
              height: 44px;
              border-radius: 0;
            }

            .cancel {
              max-width: 100%;
              width: 100%;
              height: 44px;
              line-height: 44px;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }
          }
        }

        &-button {
          display: none;
        }
      }

      .page__subtitle {
        .page__subtitle-button {
          display: flex;
          max-width: 100%;
          width: 100%;

          .page__subtitle-button-item {
            width: 100%;
            height: 40px;
            border-radius: 40px;
            background-color: #737373;
            color: #fff;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            transition: $transition-01;
            cursor: pointer;

            &:hover {
              transform: translateY(-5px) scale(1.05);
              background-color: #3162b9;
            }
          }
        }
      }

      .page__content {
        .page__content-item {
          max-width: 100%;

          .page__content-item-wrapper {
            flex-direction: column;

            .page__content-item-descr {
              max-width: 100%;

              ul {
                margin-top: 25px;
              }
            }

            .page__content-item-image {
              max-width: 100%;
              margin-top: 25px;

              .active {
                transform: scale(1.3);
              }
            }
          }

          .page__content-item-footer {
            height: auto;
            flex-direction: column;
            padding: 20px 15px;

            .page__content-item-footer-price {
              max-width: 100%;
            }

            .page__content-item-footer-link {
              max-width: 100%;
              width: 100%;
              margin-top: 20px;

              .button {
                max-width: 100%;
                width: 100%;
                text-align: center;
              }
            }
          }
        }
      }

      .form {
        margin-bottom: 40px;

        & > div {
          form {
            padding: 35px 15px 0;

            & > p {
              max-width: 100%;
              width: 100%;

              .errortext {
                color: red;
              }

              .notetext {
                color: #3162b9;
              }
            }

            .form__title {
              font-size: 18px;
              font-weight: 700;
              line-height: 21px;
              text-transform: uppercase;
              margin-bottom: 13px;
            }

            .form__wrapper {
              .form__row {
                flex-direction: column;
                max-width: 100%;
                width: 100%;

                .form__item {
                  height: unset !important;
                  margin-bottom: 25px;

                  &.name {
                    order: 1;
                  }

                  &.phone {
                    order: 2;
                  }

                  &.email {
                    order: 3;
                  }

                  &.captcha {
                    order: 4;
                    max-width: 100%;
                  }

                  &.privacy {
                    order: 5;
                    max-width: 100%;
                  }

                  &.submit-btn {
                    max-width: 100%;
                    order: 6;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}