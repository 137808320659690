.front-page-banner {
  height: 450px;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url('/bitrix/templates/trendspb_enerpro/images/1.jpg');
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    z-index: 5;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    padding-left: 66px;
    position: relative;
    z-index: 10;
    padding-top: 30px;

    .front-page-banner__title {
      max-width: 75%;
      width: 100%;
      color: #fff;
      font-family: $main-font;
      font-size: 33px;
      font-weight: 700;
      line-height: 39px;
      margin-bottom: 25px;
    }

    .front-page-banner__subtitle {
      max-width: 75%;
      width: 100%;
      color: #fff;
      font-family: $main-font;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 45px;
    }

    .front-page-banner__btn {
      width: 194px;
      height: 40px;
      box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
      border-radius: 50px;
      background-color: #3162b9;
      color: #fff;
      font-family: $main-font;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      text-decoration: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        transform: translateY(-5px) scale(1.05);
      }
    }
  }
}