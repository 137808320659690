.front-page-news {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .front-page-news__heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      max-width: 100%;
      width: 100%;
      margin-bottom: 25px;

      &-title {
        color: #000;
        font-family: $main-font;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        text-transform: uppercase;
      }

      &-link {
        color: #3162b9;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-decoration: underline;
      }
    }

    .news-list {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 100%;
      width: 100%;

      .news-item {
        max-width: 25%;
        width: 100%;
        display: flex;
        flex-direction: column !important;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 25px 0 25px;

        &:first-of-type {
          padding: 0 25px 0 0;
        }

        &:last-of-type {
          padding: 0 0 0 25px;
        }

        &:not(:last-of-type) {
          border-right: 1px solid #e5e5e5;
        }

        .news-date-time {
          color: #3162b9;
          font-family: $main-font;
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
          margin-bottom: 15px;
        }

        .news-title-link {
          color: #000;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
          margin-bottom: 15px;
          text-decoration: none;
          min-height: 38px;
        }

        .news-preview-text {
          color: #737373;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          margin-bottom: 25px;
        }

        .news-showmore-link {
          color: #3162b9;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          text-decoration: underline;
        }
      }
    }
  }
}