.front-page-form {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  .container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & > div {
      max-width: 100%;
      width: 100%;

      form {
        max-width: 100%;
        width: 100%;
        border: 3px solid #3162b9;
        min-height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 15px 28px;

        & > p {
          max-width: 100%;
          width: 100%;

          .errortext {
            font-family: $main-font;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: red;
          }

          .notetext {
            font-family: $main-font;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: #3162b9;
          }
        }

        .form__title {
          color: #000;
          font-family: $main-font;
          font-size: 20px;
          font-weight: 700;
          line-height: 23px;
          margin-bottom: 10px;
          max-width: 100%;
          width: 100%;
          text-align: left;
          text-transform: uppercase;
        }

        .form__subtitle {
          color: #000;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          margin-bottom: 20px;
          max-width: 100%;
          width: 100%;
          text-align: left;
        }

        .form__wrapper {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          max-width: 100%;
          width: 100%;

          .form__row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            max-width: 100%;
            width: 100%;
            flex-wrap: wrap;

            .form__item {
              max-width: 270px;
              width: 100%;
              position: relative;
              height: 25px;

              &-label {
                color: #000;
                font-family: $main-font;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                position: absolute;
                transition: 0.2s;
                top: 50%;
                left: 0;
                z-index: 5;
                transform: translateY(-50%);

                .form-required {
                  color: #3162b9;
                }

                &.active {
                  font-size: 10px;
                  top: 0;
                  line-height: 1;
                  transform: translateY(-100%);
                }
              }

              .inputtext {
                max-width: 100%;
                width: 100%;
                border: none;
                border-bottom: 2px solid #3162b9;
                height: 100%;
                outline: none;
                font-family: $main-font;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                position: relative;
                z-index: 5;
                background-color: transparent;
              }

              &.submit-btn {
                max-width: 200px;
                width: 100%;
                height: 40px;

                input[type="submit"] {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  max-width: 100%;
                  width: 100%;
                  height: 40px;
                  border-radius: 50px;
                  border: none;
                  background: $main-color;
                  color: #fff;
                  font-family: $main-font;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19px;
                  text-decoration: none;
                  cursor: pointer;
                  transition: 0.2s ease-in-out;
                  outline: none;

                  &:hover {
                    transform: translateY(-5px) scale(1.05);
                  }
                }
              }

              &.captcha {
                max-width: 50%;
                width: 100%;
                height: 40px;
                display: none;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-end;
                margin-top: 10px;

                &.active {
                  display: flex;
                }

                .inputtext {
                  max-width: 270px;
                  margin-left: 10px;
                }
              }

              &.privacy {
                max-width: 100%;
                width: 100%;
                color: #737373;
                font-family: $main-font;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                padding-top: 20px;

                a {
                  color: #000000;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 14px;
                }
              }

              &.text {
                max-width: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-top: 10px;

                textarea {
                  max-width: 100%;
                  width: 100%;
                  border: none;
                  border-bottom: 2px solid #3162b9;
                  height: 100%;
                  outline: none;
                  font-family: $main-font;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19px;
                  position: relative;
                  z-index: 5;
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}