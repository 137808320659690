.price-page__wrapper {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .price-page__title {
      max-width: 100%;
      width: 100%;
      text-align: left;
      color: #000;
      font-family: $main-font;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      margin: 35px 0;

      strong {
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        margin-right: 20px;
        text-transform: uppercase;
      }
    }

    .price-page__links {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 35px;

      .price-page__links-row {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .price-page__links-item {
          height: 32px;
          background-color: #e5e5e5;
          color: #000;
          font-family: $main-font;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          text-decoration: none;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          border-radius: 50px;
          padding: 0 10px;
          cursor: pointer;
          transition: $transition-01;

          &.active {
            background-color: #3162b9;
            color: #fff;
          }

          &:hover {
            background-color: #3162b9;
            color: #fff;
          }
        }
      }
    }

    .table__wrapper {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 15px;

      &.hidden {
        display: none;
      }

      &.active {
        display: flex;
      }

      table.table__price {
        width: 100%;
        margin-top: 23px;
        border: 1px solid #cecdca;

        tr {
          border: 1px solid #cecdca;

          td {
            border: 1px solid #cecdca;
            text-align: center;
            vertical-align: middle;

            .table__title {
              color: #000;
              font-family: $main-font;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              max-width: 100%;
              width: 100%;
            }
          }
        }

        thead {
          color: #000;
          font-family: $main-font;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          height: 60px;

          tr {
            height: 30px;

            &:first-of-type {
              td {
                width: 25%;
              }
            }

            &:nth-of-type(2) {
              td {
                width: 143px;
              }
            }
          }
        }

        tbody {
          tr td {
            color: #000;
            font-family: $main-font;
            font-size: 18px;
            font-weight: 400;
            line-height: 16px;
            height: 30px;
          }

          tr td[rowspan="3"] {
            color: #000;
            font-family: $main-font;
            font-size: 18px;
            font-weight: 700;
            line-height: 16px;
            height: 60px;
          }

          tr,
          tr td {
            transition: $transition-01;
          }

          .hover {
            background-color: #e6ecf6;
          }
        }
      }

      .table__subtitle {
        margin-top: 40px;
        max-width: 100%;
        width: 100%;
        text-align: left;
        color: #000;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
}