.front-page-contacts {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .contacts__block {
      max-width: calc(100% - 875px);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .contacts__block-title {
        max-width: 100%;
        width: 100%;
        text-align: left;
        color: #000;
        font-family: $main-font;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        text-transform: uppercase;
        margin-bottom: 25px;
      }

      .contacts__block-subtitle {
        color: #000;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        margin-bottom: 10px;
      }

      .contacts__block-text,
      .contacts__block-text a {
        color: #000;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-decoration: none;
        margin-bottom: 20px;
      }
    }

    .contacts__map {
      max-width: 875px;
      width: 100%;
    }
  }
}