* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.container {
  max-width: 1200px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto; }

@font-face {
  font-family: 'FuturaFuturisC';
  src: url("../../fonts/FuturaFuturisC-Italic.eot");
  src: local("../../fonts/FuturaFuturisC Italic"), local("../../fonts/FuturaFuturisC-Italic"), url("../../fonts/FuturaFuturisC-Italic.eot?#iefix") format("embedded-opentype"), url("../../fonts/FuturaFuturisC-Italic.woff") format("woff"), url("../../fonts/FuturaFuturisC-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'FuturaFuturisBlackC';
  src: url("../../fonts/FuturaFuturisBlackC.eot");
  src: local("../../fonts/FuturaFuturisBlackC"), url("../../fonts/FuturaFuturisBlackC.eot?#iefix") format("embedded-opentype"), url("../../fonts/FuturaFuturisBlackC.woff") format("woff"), url("../../fonts/FuturaFuturisBlackC.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'FuturaFuturisC';
  src: url("../../fonts/FuturaFuturisC.eot");
  src: local("../../fonts/FuturaFuturisC"), url("../../fonts/FuturaFuturisC.eot?#iefix") format("embedded-opentype"), url("../../fonts/FuturaFuturisC.woff") format("woff"), url("../../fonts/FuturaFuturisC.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FuturaFuturisShadowC';
  src: url("../../fonts/FuturaFuturisShadowC.eot");
  src: local("../../fonts/FuturaFuturisShadowC"), url("../../fonts/FuturaFuturisShadowC.eot?#iefix") format("embedded-opentype"), url("../../fonts/FuturaFuturisShadowC.woff") format("woff"), url("../../fonts/FuturaFuturisShadowC.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FuturaFuturisVolumeLightC';
  src: url("../../fonts/FuturaFuturisVolumeLightC.eot");
  src: local("../../fonts/FuturaFuturisVolumeLightC"), url("../../fonts/FuturaFuturisVolumeLightC.eot?#iefix") format("embedded-opentype"), url("../../fonts/FuturaFuturisVolumeLightC.woff") format("woff"), url("../../fonts/FuturaFuturisVolumeLightC.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'FuturaFuturisC';
  src: url("../../fonts/FuturaFuturisC-BoldItalic.eot");
  src: local("../../fonts/FuturaFuturisC Bold Italic"), local("FuturaFuturisC-BoldItalic"), url("../../fonts/FuturaFuturisC-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../../fonts/FuturaFuturisC-BoldItalic.woff") format("woff"), url("../../fonts/FuturaFuturisC-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'FuturaFuturisCondBoldC';
  src: url("../../fonts/FuturaFuturisCondBoldC.eot");
  src: local("FuturaFuturisCondBoldC"), url("../../fonts/FuturaFuturisCondBoldC.eot?#iefix") format("embedded-opentype"), url("../../fonts/FuturaFuturisCondBoldC.woff") format("woff"), url("../../fonts/FuturaFuturisCondBoldC.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'FuturaFuturisShadowC';
  src: url("../../fonts/FuturaFuturisShadowC-Bold.eot");
  src: local("../../fonts/FuturaFuturisShadowC Bold"), local("FuturaFuturisShadowC-Bold"), url("../../fonts/FuturaFuturisShadowC-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/FuturaFuturisShadowC-Bold.woff") format("woff"), url("../../fonts/FuturaFuturisShadowC-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'FuturaFuturisLightC';
  src: url("../../fonts/FuturaFuturisLightC.eot");
  src: local("../../fonts/FuturaFuturisLightC"), url("../../fonts/FuturaFuturisLightC.eot?#iefix") format("embedded-opentype"), url("../../fonts/FuturaFuturisLightC.woff") format("woff"), url("../../fonts/FuturaFuturisLightC.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'FuturaFuturisCameoExtraBoldC';
  src: url("../../fonts/FuturaFuturisCameoExtraBoldC.eot");
  src: local("../../fonts/FuturaFuturisCameoExtraBoldC"), url("../../fonts/FuturaFuturisCameoExtraBoldC.eot?#iefix") format("embedded-opentype"), url("../../fonts/FuturaFuturisCameoExtraBoldC.woff") format("woff"), url("../../fonts/FuturaFuturisCameoExtraBoldC.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'FuturaFuturisC';
  src: url("../../fonts/FuturaFuturisC-Bold.eot");
  src: local("../../fonts/FuturaFuturisC Bold"), local("../../fonts/FuturaFuturisC-Bold"), url("../../fonts/FuturaFuturisC-Bold.eot?#iefix") format("embedded-opentype"), url("../../fonts/FuturaFuturisC-Bold.woff") format("woff"), url("../../fonts/FuturaFuturisC-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'FuturaFuturisCondExtraBoldC';
  src: url("../../fonts/FuturaFuturisCondExtraBoldC.eot");
  src: local("../../fonts/FuturaFuturisCondExtraBoldC"), url("../../fonts/FuturaFuturisCondExtraBoldC.eot?#iefix") format("embedded-opentype"), url("../../fonts/FuturaFuturisCondExtraBoldC.woff") format("woff"), url("../../fonts/FuturaFuturisCondExtraBoldC.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input,
textarea,
select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0; }

.breadcrumbs__wrapper {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 51px;
  background-color: #e5e5e5; }
  .breadcrumbs__wrapper .container .bx-breadcrumb {
    max-width: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative; }
    .breadcrumbs__wrapper .container .bx-breadcrumb .bx-breadcrumb-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      color: #737373;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      position: relative; }
      .breadcrumbs__wrapper .container .bx-breadcrumb .bx-breadcrumb-item a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-right: 15px;
        position: relative;
        color: #737373;
        text-decoration: none; }
        .breadcrumbs__wrapper .container .bx-breadcrumb .bx-breadcrumb-item a::after {
          content: '/';
          position: absolute;
          right: -12px;
          top: 0;
          font-size: 16px;
          color: #737373;
          font-family: "FuturaFuturisC", sans-serif; }
      .breadcrumbs__wrapper .container .bx-breadcrumb .bx-breadcrumb-item span.last {
        text-decoration: underline !important; }

header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 102px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: relative;
  max-width: 100%;
  width: 100%; }
  header .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%; }
    header .container .header__logo {
      margin-right: 30px; }
      header .container .header__logo .header__logo-link .header__logo-link-image {
        width: 186px;
        height: auto; }
    header .container .toggle__menu {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      height: 100%; }
      header .container .toggle__menu #horizontal-multilevel-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out; }
        header .container .toggle__menu #horizontal-multilevel-menu > li {
          height: 101px;
          margin-right: 15px;
          font-size: 18px; }
          header .container .toggle__menu #horizontal-multilevel-menu > li:nth-child(5) {
            display: none; }
          header .container .toggle__menu #horizontal-multilevel-menu > li .root-item {
            height: 100%;
            color: #737373;
            font-family: "FuturaFuturisC", sans-serif;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            text-decoration: none; }
          header .container .toggle__menu #horizontal-multilevel-menu > li .root-item-selected {
            height: 100%;
            font-family: "FuturaFuturisC", sans-serif;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            color: #3162b9;
            text-decoration: none; }
          header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item {
            position: relative;
            -webkit-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out; }
            header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item::after {
              position: absolute;
              bottom: 20px;
              left: 50%;
              -webkit-transform: translateX(-50%);
                  -ms-transform: translateX(-50%);
                      transform: translateX(-50%);
              content: '';
              width: 12px;
              height: 12px;
              background-image: url("/bitrix/templates/trendspb_enerpro/images/svg/caret.svg");
              background-color: transparent;
              background-size: 100%;
              background-position: 50% 50%;
              background-repeat: no-repeat;
              -webkit-transition: 0.5s ease-in-out;
              -o-transition: 0.5s ease-in-out;
              transition: 0.5s ease-in-out; }
            header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu {
              display: none; }
            header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item:hover::after {
              -webkit-transform: translateX(-50%) rotate(180deg);
                  -ms-transform: translateX(-50%) rotate(180deg);
                      transform: translateX(-50%) rotate(180deg); }
            header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item:hover .sub-menu {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex; }
            header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu {
              width: auto;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
                  -ms-flex-direction: column;
                      flex-direction: column;
              -webkit-box-pack: start;
                  -ms-flex-pack: start;
                      justify-content: flex-start;
              -webkit-box-align: start;
                  -ms-flex-align: start;
                      align-items: flex-start;
              background-color: #fff;
              -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
              border-bottom-right-radius: 5px;
              border-bottom-left-radius: 5px;
              position: absolute;
              top: auto;
              z-index: 500;
              height: auto; }
              header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                        flex-direction: row;
                -webkit-box-pack: start;
                    -ms-flex-pack: start;
                        justify-content: flex-start;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                min-width: 300px;
                min-height: 40px;
                padding: 15px 15px 15px 10px;
                position: relative;
                -webkit-transition: 0.5s ease-in-out;
                -o-transition: 0.5s ease-in-out;
                transition: 0.5s ease-in-out;
                -webkit-box-ordinal-group: 3;
                    -ms-flex-order: 2;
                        order: 2; }
                header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li.parent-item {
                  -webkit-box-ordinal-group: 2;
                      -ms-flex-order: 1;
                          order: 1; }
                  header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li.parent-item .parent {
                    position: relative;
                    -webkit-transition: 0.5s ease-in-out;
                    -o-transition: 0.5s ease-in-out;
                    transition: 0.5s ease-in-out; }
                    header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li.parent-item .parent::after {
                      position: absolute;
                      top: 50%;
                      right: 0;
                      -webkit-transform: translateY(-50%) rotate(-90deg);
                          -ms-transform: translateY(-50%) rotate(-90deg);
                              transform: translateY(-50%) rotate(-90deg);
                      content: '';
                      width: 12px;
                      height: 12px;
                      background-image: url("/bitrix/templates/trendspb_enerpro/images/svg/caret.svg");
                      background-color: transparent;
                      background-size: 100%;
                      background-position: 50% 50%;
                      background-repeat: no-repeat;
                      -webkit-transition: 0.5s ease-in-out;
                      -o-transition: 0.5s ease-in-out;
                      transition: 0.5s ease-in-out; }
                    header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li.parent-item .parent:hover::after {
                      -webkit-transform: translateY(-50%) rotate(90deg);
                          -ms-transform: translateY(-50%) rotate(90deg);
                              transform: translateY(-50%) rotate(90deg); }
                  header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li.parent-item.item-selected .parent {
                    color: #3162b9; }
                header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li a {
                  height: 100%;
                  text-align: left;
                  color: #3162b9;
                  font-family: "FuturaFuturisC", sans-serif;
                  text-decoration: none; }
                header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li.item-selected > a {
                  color: #3162b9; }
                header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li .sub-menu-2 {
                  width: auto;
                  display: none;
                  -webkit-box-orient: vertical;
                  -webkit-box-direction: normal;
                      -ms-flex-direction: column;
                          flex-direction: column;
                  -webkit-box-pack: start;
                      -ms-flex-pack: start;
                          justify-content: flex-start;
                  -webkit-box-align: start;
                      -ms-flex-align: start;
                          align-items: flex-start;
                  background-color: #fff;
                  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                  top: 0;
                  right: -100%;
                  position: absolute;
                  z-index: 500;
                  height: auto; }
                  header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li .sub-menu-2 li {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                        -ms-flex-direction: row;
                            flex-direction: row;
                    -webkit-box-pack: start;
                        -ms-flex-pack: start;
                            justify-content: flex-start;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;
                    min-width: 300px;
                    min-height: 40px;
                    padding: 15px 25px 15px 10px;
                    -webkit-transition: 0.5s ease-in-out;
                    -o-transition: 0.5s ease-in-out;
                    transition: 0.5s ease-in-out; }
                    header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li .sub-menu-2 li:hover {
                      background-color: #e5e5e5; }
                    header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li .sub-menu-2 li a {
                      height: 100%;
                      text-align: left;
                      color: #3162b9;
                      font-family: "FuturaFuturisC", sans-serif; }
                    header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li .sub-menu-2 li.item-selected > a {
                      color: #3162b9; }
                header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li:hover {
                  background-color: #e5e5e5; }
                  header .container .toggle__menu #horizontal-multilevel-menu > li.parent-item .sub-menu li:hover .sub-menu-2 {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex; }
      header .container .toggle__menu #search_top {
        height: 101px; }
        header .container .toggle__menu #search_top form {
          position: relative;
          height: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          header .container .toggle__menu #search_top form .search-suggest {
            position: absolute;
            right: 0;
            height: 40px;
            line-height: 40px;
            width: 300px;
            -webkit-box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                    box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
            background-color: rgba(255, 255, 255, 0.8);
            outline: none;
            border: 1px solid #e5e5e5;
            bottom: 0;
            -webkit-transform: translateY(100%);
                -ms-transform: translateY(100%);
                    transform: translateY(100%);
            padding-left: 15px;
            font-family: "FuturaFuturisC", sans-serif;
            color: #737373;
            opacity: 0;
            visibility: hidden;
            z-index: -999;
            -webkit-transition: 1s ease-out;
            -o-transition: 1s ease-out;
            transition: 1s ease-out; }
            header .container .toggle__menu #search_top form .search-suggest::-webkit-input-placeholder {
              color: #737373;
              height: 40px;
              line-height: 40px; }
            header .container .toggle__menu #search_top form .search-suggest:-ms-input-placeholder {
              color: #737373;
              height: 40px;
              line-height: 40px; }
            header .container .toggle__menu #search_top form .search-suggest::-ms-input-placeholder {
              color: #737373;
              height: 40px;
              line-height: 40px; }
            header .container .toggle__menu #search_top form .search-suggest::placeholder {
              color: #737373;
              height: 40px;
              line-height: 40px; }
            header .container .toggle__menu #search_top form .search-suggest:focus {
              opacity: 1;
              visibility: visible;
              z-index: 999; }
          header .container .toggle__menu #search_top form .search-button {
            background-image: url("/bitrix/templates/trendspb_enerpro/images/svg/search.svg");
            background-color: transparent;
            background-size: 19px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            border: none;
            border-radius: 50%;
            color: transparent;
            font-size: 0;
            width: 30px;
            height: 30px;
            outline: none;
            -webkit-transition: 0.2s ease-in-out;
            -o-transition: 0.2s ease-in-out;
            transition: 0.2s ease-in-out;
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
            padding: 0;
            cursor: pointer; }
          header .container .toggle__menu #search_top form:hover .search-suggest {
            opacity: 1;
            visibility: visible;
            z-index: 999; }
    header .container .header__contacts {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: end;
          -ms-flex-align: end;
              align-items: flex-end; }
      header .container .header__contacts .header__contacts-phone a {
        color: #3162b9;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        text-decoration: none; }
      header .container .header__contacts .header__contacts-email a {
        color: #3162b9;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px; }

.footer {
  max-width: 100%;
  width: 100%;
  height: 150px;
  background-color: #0c1d3b;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative; }
  .footer .footer__wrapper {
    max-width: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: calc(100% - 33px); }
    .footer .footer__wrapper .container {
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .footer .footer__wrapper .container .footer__menu {
        max-width: 56%;
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .footer .footer__wrapper .container .footer__menu ul {
          max-width: 100%;
          width: 100%;
          height: 50%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
          .footer .footer__wrapper .container .footer__menu ul li {
            max-width: 25%;
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center; }
            .footer .footer__wrapper .container .footer__menu ul li a {
              color: #cecdca;
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              text-decoration: none; }
              .footer .footer__wrapper .container .footer__menu ul li a:visited {
                color: #cecdca;
                text-decoration: none; }
      .footer .footer__wrapper .container .footer__contacts {
        max-width: 44%;
        width: 100%;
        height: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
        .footer .footer__wrapper .container .footer__contacts .footer__contacts-item--adress {
          max-width: 100%;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          .footer .footer__wrapper .container .footer__contacts .footer__contacts-item--adress .footer__contacts-subtitle {
            color: #cecdca;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            margin-right: 12px; }
          .footer .footer__wrapper .container .footer__contacts .footer__contacts-item--adress .footer__contacts-adress {
            color: #cecdca;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px; }
        .footer .footer__wrapper .container .footer__contacts .footer__contacts-item--phone {
          max-width: 50%;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          .footer .footer__wrapper .container .footer__contacts .footer__contacts-item--phone .footer__contacts-subtitle {
            color: #cecdca;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            margin-right: 12px; }
          .footer .footer__wrapper .container .footer__contacts .footer__contacts-item--phone .footer__contacts-text a {
            color: #3162b9;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            text-decoration: none; }
        .footer .footer__wrapper .container .footer__contacts .footer__contacts-item--email {
          max-width: 50%;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          .footer .footer__wrapper .container .footer__contacts .footer__contacts-item--email .footer__contacts-subtitle {
            color: #cecdca;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            margin-right: 12px; }
          .footer .footer__wrapper .container .footer__contacts .footer__contacts-item--email .footer__contacts-text a {
            color: #3162b9;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px; }
  .footer .copyrights {
    max-width: 100%;
    width: 100%;
    border-top: 1px solid #737373;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 33px; }
    .footer .copyrights .container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      height: 100%; }
      .footer .copyrights .container .copyrights__year {
        max-width: 60%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        color: #cecdca;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px; }
      .footer .copyrights .container .copyrights__trend {
        max-width: 40%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        color: #cecdca;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px; }
        .footer .copyrights .container .copyrights__trend a {
          color: #cecdca;
          text-decoration: none; }

.modals__wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5); }
  .modals__wrapper .modal__buy {
    margin: 200px auto 0;
    display: none; }
    .modals__wrapper .modal__buy .container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .modals__wrapper .modal__buy .container > div {
        max-width: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .modals__wrapper .modal__buy .container > div form {
          max-width: 50%;
          width: 100%;
          background-color: #fff;
          padding: 25px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          position: relative; }
          .modals__wrapper .modal__buy .container > div form .close {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 10px;
            height: 10px;
            cursor: pointer;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            background-image: url(/bitrix/templates/trendspb_enerpro/images/svg/cancel.svg);
            background-position: 50% 50%;
            background-size: 100%;
            background-repeat: no-repeat; }
          .modals__wrapper .modal__buy .container > div form > p {
            max-width: 100%;
            width: 100%; }
            .modals__wrapper .modal__buy .container > div form > p .errortext {
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px; }
            .modals__wrapper .modal__buy .container > div form > p .notetext {
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              color: #3162b9; }
          .modals__wrapper .modal__buy .container > div form .form-title {
            max-width: 100%;
            width: 100%;
            text-align: left;
            color: #000;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 20px;
            font-weight: 700;
            line-height: 23px;
            margin-bottom: 15px; }
          .modals__wrapper .modal__buy .container > div form .form-descr {
            max-width: 100%;
            width: 100%;
            text-align: left;
            color: #000;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            margin-bottom: 15px; }
          .modals__wrapper .modal__buy .container > div form .form-ask {
            max-width: 100%;
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            margin-top: 15px; }
            .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item {
              max-width: 100%;
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: horizontal;
              -webkit-box-direction: normal;
                  -ms-flex-direction: row;
                      flex-direction: row;
              -webkit-box-pack: justify;
                  -ms-flex-pack: justify;
                      justify-content: space-between;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              margin-bottom: 15px;
              min-height: 30px; }
              .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item.captcha {
                display: none; }
                .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item.captcha.active {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex; }
              .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item br {
                display: none; }
              .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item img {
                max-width: 23%;
                width: 100%;
                height: 100%; }
              .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item span {
                max-width: 25%;
                width: 100%;
                text-align: left;
                color: #000;
                font-family: "FuturaFuturisC", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px; }
                .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item span font {
                  color: #3162b9; }
              .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item select,
              .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item input,
              .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item textarea {
                max-width: 75%;
                width: 100%;
                border: none;
                border-bottom: 2px solid #3162b9;
                height: 100%;
                outline: none;
                font-family: "FuturaFuturisC", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                position: relative;
                z-index: 5;
                background-color: transparent; }
              .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item input {
                padding-left: 7px; }
              .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item input[type="submit"] {
                max-width: 50%;
                width: 100%;
                height: 40px;
                -webkit-box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                        box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                border-radius: 50px;
                background-color: #3162b9;
                color: #fff;
                font-family: "FuturaFuturisC", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                text-decoration: none;
                display: block;
                text-align: center;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                        flex-direction: row;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                border: none;
                cursor: pointer;
                outline: none;
                margin: 25px auto 0;
                padding: 0; }
  .modals__wrapper .modal__request {
    margin: 100px auto 0;
    display: none; }
    .modals__wrapper .modal__request .container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .modals__wrapper .modal__request .container > div {
        max-width: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .modals__wrapper .modal__request .container > div form {
          max-width: 50%;
          width: 100%;
          background-color: #fff;
          padding: 25px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          position: relative; }
          .modals__wrapper .modal__request .container > div form .close {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 10px;
            height: 10px;
            cursor: pointer;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            background-image: url(/bitrix/templates/trendspb_enerpro/images/svg/cancel.svg);
            background-position: 50% 50%;
            background-size: 100%;
            background-repeat: no-repeat; }
          .modals__wrapper .modal__request .container > div form > p {
            max-width: 100%;
            width: 100%; }
            .modals__wrapper .modal__request .container > div form > p .errortext {
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px; }
            .modals__wrapper .modal__request .container > div form > p .notetext {
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              color: #3162b9; }
          .modals__wrapper .modal__request .container > div form .form-title {
            max-width: 100%;
            width: 100%;
            text-align: left;
            color: #000;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 20px;
            font-weight: 700;
            line-height: 23px;
            margin-bottom: 15px; }
          .modals__wrapper .modal__request .container > div form .form-descr {
            max-width: 100%;
            width: 100%;
            text-align: left;
            color: #000;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            margin-bottom: 15px; }
          .modals__wrapper .modal__request .container > div form .form-ask {
            max-width: 100%;
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            margin-top: 15px; }
            .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item {
              max-width: 100%;
              width: 100%;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: horizontal;
              -webkit-box-direction: normal;
                  -ms-flex-direction: row;
                      flex-direction: row;
              -webkit-box-pack: justify;
                  -ms-flex-pack: justify;
                      justify-content: space-between;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              margin-bottom: 15px;
              min-height: 30px; }
              .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item.captcha {
                display: none; }
                .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item.captcha.active {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex; }
              .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item br {
                display: none; }
              .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item img {
                max-width: 23%;
                width: 100%;
                height: 100%; }
              .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item span {
                max-width: 25%;
                width: 100%;
                text-align: left;
                color: #000;
                font-family: "FuturaFuturisC", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px; }
                .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item span font .form-required {
                  color: #3162b9 !important; }
              .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item select,
              .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item input,
              .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item textarea {
                max-width: 75%;
                width: 100%;
                border: none;
                border-bottom: 2px solid #3162b9;
                height: 100%;
                outline: none;
                font-family: "FuturaFuturisC", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                position: relative;
                z-index: 5;
                background-color: transparent; }
              .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item input {
                padding-left: 7px; }
              .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item input[type="submit"] {
                max-width: 50%;
                width: 100%;
                height: 40px;
                -webkit-box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                        box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                border-radius: 50px;
                background-color: #3162b9;
                color: #fff;
                font-family: "FuturaFuturisC", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                text-decoration: none;
                display: block;
                text-align: center;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                        flex-direction: row;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                border: none;
                cursor: pointer;
                outline: none;
                margin: 25px auto 0;
                padding: 0; }

.front-page-banner {
  height: 450px;
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-image: url("/bitrix/templates/trendspb_enerpro/images/1.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative; }
  .front-page-banner::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    z-index: 5; }
  .front-page-banner .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    height: 100%;
    padding-left: 66px;
    position: relative;
    z-index: 10;
    padding-top: 30px; }
    .front-page-banner .container .front-page-banner__title {
      max-width: 75%;
      width: 100%;
      color: #fff;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 33px;
      font-weight: 700;
      line-height: 39px;
      margin-bottom: 25px; }
    .front-page-banner .container .front-page-banner__subtitle {
      max-width: 75%;
      width: 100%;
      color: #fff;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      margin-bottom: 45px; }
    .front-page-banner .container .front-page-banner__btn {
      width: 194px;
      height: 40px;
      -webkit-box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
              box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
      border-radius: 50px;
      background-color: #3162b9;
      color: #fff;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      text-decoration: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-transition: 0.2s ease-in-out;
      -o-transition: 0.2s ease-in-out;
      transition: 0.2s ease-in-out;
      cursor: pointer; }
      .front-page-banner .container .front-page-banner__btn:hover {
        -webkit-transform: translateY(-5px) scale(1.05);
            -ms-transform: translateY(-5px) scale(1.05);
                transform: translateY(-5px) scale(1.05); }

.front-page-prefences {
  max-width: 100%;
  width: 100%;
  height: 208px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .front-page-prefences .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%; }
    .front-page-prefences .container .front-page-prefences__item {
      max-width: 25%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
          -ms-flex-direction: column !important;
              flex-direction: column !important;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      height: 60%;
      padding: 0 25px 0 25px; }
      .front-page-prefences .container .front-page-prefences__item:first-of-type {
        padding: 0 25px 0 0; }
      .front-page-prefences .container .front-page-prefences__item:not(:last-of-type) {
        border-right: 1px solid #e5e5e5; }
      .front-page-prefences .container .front-page-prefences__item .front-page-prefences__item-title {
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.7px;
        line-height: 16px;
        margin-bottom: 20px;
        text-transform: uppercase; }
      .front-page-prefences .container .front-page-prefences__item .front-page-prefences__item-text {
        color: #737373;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px; }

.front-page-about {
  max-width: 100%;
  width: 100%;
  height: 530px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .front-page-about .container {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
    .front-page-about .container .front-page-about__text {
      height: 100%;
      max-width: 475px;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
          -ms-flex-direction: column !important;
              flex-direction: column !important;
      -webkit-box-pack: center !important;
          -ms-flex-pack: center !important;
              justify-content: center !important;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
      .front-page-about .container .front-page-about__text-title {
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        margin-bottom: 30px;
        text-transform: uppercase; }
      .front-page-about .container .front-page-about__text-subtitle {
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 20px; }
      .front-page-about .container .front-page-about__text-content {
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #737373;
        margin-bottom: 40px; }
      .front-page-about .container .front-page-about__text-btn {
        width: 194px;
        height: 40px;
        -webkit-box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
        border-radius: 50px;
        background-color: #3162b9;
        color: #fff;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-decoration: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-transition: 0.2s ease-in-out;
        -o-transition: 0.2s ease-in-out;
        transition: 0.2s ease-in-out;
        cursor: pointer; }
        .front-page-about .container .front-page-about__text-btn:hover {
          -webkit-transform: translateY(-5px) scale(1.05);
              -ms-transform: translateY(-5px) scale(1.05);
                  transform: translateY(-5px) scale(1.05); }
    .front-page-about .container .front-page-about__pics {
      max-width: 665px;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
      .front-page-about .container .front-page-about__pics .front-page-about__pics-item {
        max-width: 32%;
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        border-radius: 2px;
        overflow: hidden; }
        .front-page-about .container .front-page-about__pics .front-page-about__pics-item:hover {
          -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }
        .front-page-about .container .front-page-about__pics .front-page-about__pics-item-image {
          max-width: 100%;
          width: 100%;
          height: 394px;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          margin-bottom: 25px; }
          .front-page-about .container .front-page-about__pics .front-page-about__pics-item-image.manufacturing {
            background-image: url("/bitrix/templates/trendspb_enerpro/images/2.jpg"); }
          .front-page-about .container .front-page-about__pics .front-page-about__pics-item-image.projecting {
            background-image: url("/bitrix/templates/trendspb_enerpro/images/3.jpg"); }
          .front-page-about .container .front-page-about__pics .front-page-about__pics-item-image.mounting {
            background-image: url("/bitrix/templates/trendspb_enerpro/images/4.jpg"); }
        .front-page-about .container .front-page-about__pics .front-page-about__pics-item-title {
          max-width: 100%;
          width: 100%;
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          text-align: left;
          padding-left: 10px;
          margin-bottom: 10px;
          text-decoration: none; }
        .front-page-about .container .front-page-about__pics .front-page-about__pics-item-text {
          max-width: 100%;
          width: 100%;
          color: #737373;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: 19px;
          text-align: left;
          padding-left: 10px;
          margin-bottom: 15px; }

.front-page-form {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 50px; }
  .front-page-form .container {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .front-page-form .container > div {
      max-width: 100%;
      width: 100%; }
      .front-page-form .container > div form {
        max-width: 100%;
        width: 100%;
        border: 3px solid #3162b9;
        min-height: 220px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        padding: 15px 28px; }
        .front-page-form .container > div form > p {
          max-width: 100%;
          width: 100%; }
          .front-page-form .container > div form > p .errortext {
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: red; }
          .front-page-form .container > div form > p .notetext {
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: #3162b9; }
        .front-page-form .container > div form .form__title {
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 20px;
          font-weight: 700;
          line-height: 23px;
          margin-bottom: 10px;
          max-width: 100%;
          width: 100%;
          text-align: left;
          text-transform: uppercase; }
        .front-page-form .container > div form .form__subtitle {
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          margin-bottom: 20px;
          max-width: 100%;
          width: 100%;
          text-align: left; }
        .front-page-form .container > div form .form__wrapper {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          max-width: 100%;
          width: 100%; }
          .front-page-form .container > div form .form__wrapper .form__row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            max-width: 100%;
            width: 100%;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap; }
            .front-page-form .container > div form .form__wrapper .form__row .form__item {
              max-width: 270px;
              width: 100%;
              position: relative;
              height: 25px; }
              .front-page-form .container > div form .form__wrapper .form__row .form__item-label {
                color: #000;
                font-family: "FuturaFuturisC", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                position: absolute;
                -webkit-transition: 0.2s;
                -o-transition: 0.2s;
                transition: 0.2s;
                top: 50%;
                left: 0;
                z-index: 5;
                -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                        transform: translateY(-50%); }
                .front-page-form .container > div form .form__wrapper .form__row .form__item-label .form-required {
                  color: #3162b9; }
                .front-page-form .container > div form .form__wrapper .form__row .form__item-label.active {
                  font-size: 10px;
                  top: 0;
                  line-height: 1;
                  -webkit-transform: translateY(-100%);
                      -ms-transform: translateY(-100%);
                          transform: translateY(-100%); }
              .front-page-form .container > div form .form__wrapper .form__row .form__item .inputtext {
                max-width: 100%;
                width: 100%;
                border: none;
                border-bottom: 2px solid #3162b9;
                height: 100%;
                outline: none;
                font-family: "FuturaFuturisC", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                position: relative;
                z-index: 5;
                background-color: transparent; }
              .front-page-form .container > div form .form__wrapper .form__row .form__item.submit-btn {
                max-width: 200px;
                width: 100%;
                height: 40px; }
                .front-page-form .container > div form .form__wrapper .form__row .form__item.submit-btn input[type="submit"] {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-orient: horizontal;
                  -webkit-box-direction: normal;
                      -ms-flex-direction: row;
                          flex-direction: row;
                  -webkit-box-pack: center;
                      -ms-flex-pack: center;
                          justify-content: center;
                  -webkit-box-align: center;
                      -ms-flex-align: center;
                          align-items: center;
                  max-width: 100%;
                  width: 100%;
                  height: 40px;
                  border-radius: 50px;
                  border: none;
                  background: #3162b9;
                  color: #fff;
                  font-family: "FuturaFuturisC", sans-serif;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19px;
                  text-decoration: none;
                  cursor: pointer;
                  -webkit-transition: 0.2s ease-in-out;
                  -o-transition: 0.2s ease-in-out;
                  transition: 0.2s ease-in-out;
                  outline: none; }
                  .front-page-form .container > div form .form__wrapper .form__row .form__item.submit-btn input[type="submit"]:hover {
                    -webkit-transform: translateY(-5px) scale(1.05);
                        -ms-transform: translateY(-5px) scale(1.05);
                            transform: translateY(-5px) scale(1.05); }
              .front-page-form .container > div form .form__wrapper .form__row .form__item.captcha {
                max-width: 50%;
                width: 100%;
                height: 40px;
                display: none;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                        flex-direction: row;
                -webkit-box-pack: start;
                    -ms-flex-pack: start;
                        justify-content: flex-start;
                -webkit-box-align: end;
                    -ms-flex-align: end;
                        align-items: flex-end;
                margin-top: 10px; }
                .front-page-form .container > div form .form__wrapper .form__row .form__item.captcha.active {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex; }
                .front-page-form .container > div form .form__wrapper .form__row .form__item.captcha .inputtext {
                  max-width: 270px;
                  margin-left: 10px; }
              .front-page-form .container > div form .form__wrapper .form__row .form__item.privacy {
                max-width: 100%;
                width: 100%;
                color: #737373;
                font-family: "FuturaFuturisC", sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                padding-top: 20px; }
                .front-page-form .container > div form .form__wrapper .form__row .form__item.privacy a {
                  color: #000000;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 14px; }
              .front-page-form .container > div form .form__wrapper .form__row .form__item.text {
                max-width: 100%;
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                        flex-direction: row;
                -webkit-box-pack: start;
                    -ms-flex-pack: start;
                        justify-content: flex-start;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                margin-top: 10px; }
                .front-page-form .container > div form .form__wrapper .form__row .form__item.text textarea {
                  max-width: 100%;
                  width: 100%;
                  border: none;
                  border-bottom: 2px solid #3162b9;
                  height: 100%;
                  outline: none;
                  font-family: "FuturaFuturisC", sans-serif;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19px;
                  position: relative;
                  z-index: 5;
                  background-color: transparent; }

.front-page-gallery {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 50px; }
  .front-page-gallery .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .front-page-gallery .container .front-page-gallery__heading {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: baseline;
          -ms-flex-align: baseline;
              align-items: baseline;
      max-width: 100%;
      width: 100%;
      margin-bottom: 25px; }
      .front-page-gallery .container .front-page-gallery__heading-title {
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        text-transform: uppercase; }
      .front-page-gallery .container .front-page-gallery__heading-link {
        color: #3162b9;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-decoration: underline; }
    .front-page-gallery .container .news-list {
      display: grid;
      max-width: 100%;
      width: 100%; }
      .front-page-gallery .container .news-list .grid-sizer {
        width: 15%; }
      .front-page-gallery .container .news-list .gutter-sizer {
        width: 2%; }
      .front-page-gallery .container .news-list .news-item {
        max-width: 15%;
        width: 100%;
        height: 127px;
        margin-bottom: 15px;
        /*&:nth-of-type(3n+3) {
          max-width: 32%;
          width: 100%;
          height: 263px;
        }*/ }
        .front-page-gallery .container .news-list .news-item:nth-of-type(0), .front-page-gallery .container .news-list .news-item:nth-of-type(3), .front-page-gallery .container .news-list .news-item:nth-of-type(6), .front-page-gallery .container .news-list .news-item:nth-of-type(9), .front-page-gallery .container .news-list .news-item:nth-of-type(12) {
          max-width: 32%;
          width: 100%;
          height: 263px; }
        .front-page-gallery .container .news-list .news-item a {
          width: 100%;
          height: 100%; }
          .front-page-gallery .container .news-list .news-item a .preview_picture {
            width: 100%;
            height: 100%;
            background-position: 50% 50%;
            background-size: 100%;
            background-repeat: no-repeat;
            -webkit-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out; }
        .front-page-gallery .container .news-list .news-item:hover a .preview_picture {
          background-size: 105%;
          -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }

.front-page-news {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 50px; }
  .front-page-news .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .front-page-news .container .front-page-news__heading {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: baseline;
          -ms-flex-align: baseline;
              align-items: baseline;
      max-width: 100%;
      width: 100%;
      margin-bottom: 25px; }
      .front-page-news .container .front-page-news__heading-title {
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        text-transform: uppercase; }
      .front-page-news .container .front-page-news__heading-link {
        color: #3162b9;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-decoration: underline; }
    .front-page-news .container .news-list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      max-width: 100%;
      width: 100%; }
      .front-page-news .container .news-list .news-item {
        max-width: 25%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
            -ms-flex-direction: column !important;
                flex-direction: column !important;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        padding: 0 25px 0 25px; }
        .front-page-news .container .news-list .news-item:first-of-type {
          padding: 0 25px 0 0; }
        .front-page-news .container .news-list .news-item:last-of-type {
          padding: 0 0 0 25px; }
        .front-page-news .container .news-list .news-item:not(:last-of-type) {
          border-right: 1px solid #e5e5e5; }
        .front-page-news .container .news-list .news-item .news-date-time {
          color: #3162b9;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
          margin-bottom: 15px; }
        .front-page-news .container .news-list .news-item .news-title-link {
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
          margin-bottom: 15px;
          text-decoration: none;
          min-height: 38px; }
        .front-page-news .container .news-list .news-item .news-preview-text {
          color: #737373;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          margin-bottom: 25px; }
        .front-page-news .container .news-list .news-item .news-showmore-link {
          color: #3162b9;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          text-decoration: underline; }

.front-page-contacts {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 50px; }
  .front-page-contacts .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .front-page-contacts .container .contacts__block {
      max-width: calc(100% - 875px);
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
      .front-page-contacts .container .contacts__block .contacts__block-title {
        max-width: 100%;
        width: 100%;
        text-align: left;
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        text-transform: uppercase;
        margin-bottom: 25px; }
      .front-page-contacts .container .contacts__block .contacts__block-subtitle {
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        margin-bottom: 10px; }
      .front-page-contacts .container .contacts__block .contacts__block-text,
      .front-page-contacts .container .contacts__block .contacts__block-text a {
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-decoration: none;
        margin-bottom: 20px; }
    .front-page-contacts .container .contacts__map {
      max-width: 875px;
      width: 100%; }

.hangar-page__wrapper {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding-top: 50px; }
  .hangar-page__wrapper .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .hangar-page__wrapper .container .page__title {
      max-width: 100%;
      width: 100%;
      text-align: left;
      color: #000;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      text-transform: uppercase;
      margin-bottom: 25px; }
    .hangar-page__wrapper .container .page__sizes {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 25px; }
      .hangar-page__wrapper .container .page__sizes .page__sizes-button {
        max-width: 240px;
        width: 100%; }
        .hangar-page__wrapper .container .page__sizes .page__sizes-button .page__sizes-button-item {
          width: 100%;
          height: 40px;
          border-radius: 40px;
          background-color: #737373;
          color: #fff;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          text-decoration: none;
          -webkit-transition: 0.1s ease-in-out;
          -o-transition: 0.1s ease-in-out;
          transition: 0.1s ease-in-out;
          cursor: pointer; }
          .hangar-page__wrapper .container .page__sizes .page__sizes-button .page__sizes-button-item:hover {
            -webkit-transform: translateY(-5px) scale(1.05);
                -ms-transform: translateY(-5px) scale(1.05);
                    transform: translateY(-5px) scale(1.05);
            background-color: #3162b9; }
      .hangar-page__wrapper .container .page__sizes .page__sizes-links {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row .page__sizes-links-title {
            color: #000;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            margin-right: 20px; }
          .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row .cancel {
            color: #000;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            margin-right: 20px;
            text-decoration: underline;
            cursor: pointer;
            display: none; }
            .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row .cancel.active {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex; }
          .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row .page__sizes-links-item {
            width: 66px;
            height: 32px;
            background-color: #e5e5e5;
            color: #000;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-decoration: none;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            margin-right: 10px;
            border-radius: 50px;
            -webkit-transition: 0.1s ease-in-out;
            -o-transition: 0.1s ease-in-out;
            transition: 0.1s ease-in-out; }
            .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row .page__sizes-links-item.active {
              background-color: #3162b9;
              color: #fff; }
            .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row .page__sizes-links-item:hover {
              background-color: #3162b9;
              color: #fff; }
    .hangar-page__wrapper .container .page__subtitle {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 35px; }
      .hangar-page__wrapper .container .page__subtitle .page__subtitle-item {
        max-width: 100%;
        width: 100%;
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 15px; }
    .hangar-page__wrapper .container .form {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 50px; }
      .hangar-page__wrapper .container .form > div {
        max-width: 100%;
        width: 100%; }
        .hangar-page__wrapper .container .form > div form {
          max-width: 100%;
          width: 100%;
          border: 3px solid #3162b9;
          min-height: 220px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start;
          padding: 15px 28px; }
          .hangar-page__wrapper .container .form > div form > p {
            max-width: 100%;
            width: 100%; }
            .hangar-page__wrapper .container .form > div form > p .errortext {
              color: red;
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px; }
            .hangar-page__wrapper .container .form > div form > p .notetext {
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              color: #3162b9; }
          .hangar-page__wrapper .container .form > div form .form__title {
            color: #000;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 20px;
            font-weight: 700;
            line-height: 23px;
            margin-bottom: 10px;
            max-width: 100%;
            width: 100%;
            text-align: left;
            text-transform: uppercase; }
          .hangar-page__wrapper .container .form > div form .form__subtitle {
            color: #000;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            margin-bottom: 20px;
            max-width: 100%;
            width: 100%;
            text-align: left; }
          .hangar-page__wrapper .container .form > div form .form__wrapper {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            max-width: 100%;
            width: 100%; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: horizontal;
              -webkit-box-direction: normal;
                  -ms-flex-direction: row;
                      flex-direction: row;
              -webkit-box-pack: justify;
                  -ms-flex-pack: justify;
                      justify-content: space-between;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              max-width: 100%;
              width: 100%;
              -ms-flex-wrap: wrap;
                  flex-wrap: wrap; }
              .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item {
                max-width: 270px;
                width: 100%;
                position: relative;
                height: 25px; }
                .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item-label {
                  color: #000;
                  font-family: "FuturaFuturisC", sans-serif;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19px;
                  position: absolute;
                  -webkit-transition: 0.2s;
                  -o-transition: 0.2s;
                  transition: 0.2s;
                  top: 50%;
                  left: 0;
                  z-index: 5;
                  -webkit-transform: translateY(-50%);
                      -ms-transform: translateY(-50%);
                          transform: translateY(-50%); }
                  .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item-label .form-required {
                    color: #3162b9; }
                  .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item-label.active {
                    font-size: 10px;
                    top: 0;
                    line-height: 1;
                    -webkit-transform: translateY(-100%);
                        -ms-transform: translateY(-100%);
                            transform: translateY(-100%); }
                .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item .inputtext {
                  max-width: 100%;
                  width: 100%;
                  border: none;
                  border-bottom: 2px solid #3162b9;
                  height: 100%;
                  outline: none;
                  font-family: "FuturaFuturisC", sans-serif;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19px;
                  position: relative;
                  z-index: 5;
                  background-color: transparent; }
                .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.submit-btn {
                  max-width: 200px;
                  width: 100%;
                  height: 40px; }
                  .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.submit-btn input[type="submit"] {
                    max-width: 100%;
                    width: 100%;
                    height: 40px;
                    -webkit-box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                            box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                    border-radius: 50px;
                    background-color: #3162b9;
                    color: #fff;
                    font-family: "FuturaFuturisC", sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    text-decoration: none;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                        -ms-flex-direction: row;
                            flex-direction: row;
                    -webkit-box-pack: center;
                        -ms-flex-pack: center;
                            justify-content: center;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;
                    border: none;
                    cursor: pointer;
                    -webkit-transition: 0.2s ease-in-out;
                    -o-transition: 0.2s ease-in-out;
                    transition: 0.2s ease-in-out;
                    outline: none; }
                    .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.submit-btn input[type="submit"]:hover {
                      -webkit-transform: translateY(-5px) scale(1.05);
                          -ms-transform: translateY(-5px) scale(1.05);
                              transform: translateY(-5px) scale(1.05); }
                .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.captcha {
                  max-width: 50%;
                  width: 100%;
                  height: 40px;
                  display: none;
                  -webkit-box-orient: horizontal;
                  -webkit-box-direction: normal;
                      -ms-flex-direction: row;
                          flex-direction: row;
                  -webkit-box-pack: start;
                      -ms-flex-pack: start;
                          justify-content: flex-start;
                  -webkit-box-align: end;
                      -ms-flex-align: end;
                          align-items: flex-end;
                  margin-top: 10px; }
                  .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.captcha.active {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex; }
                  .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.captcha .inputtext {
                    max-width: 270px;
                    margin-left: 10px; }
                .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.privacy {
                  max-width: 100%;
                  width: 100%;
                  color: #737373;
                  font-family: "FuturaFuturisC", sans-serif;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 14px;
                  padding-top: 20px; }
                  .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.privacy a {
                    color: #000000;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px; }
                .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.text {
                  max-width: 100%;
                  width: 100%;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-orient: horizontal;
                  -webkit-box-direction: normal;
                      -ms-flex-direction: row;
                          flex-direction: row;
                  -webkit-box-pack: start;
                      -ms-flex-pack: start;
                          justify-content: flex-start;
                  -webkit-box-align: center;
                      -ms-flex-align: center;
                          align-items: center;
                  margin-top: 10px; }
                  .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.text textarea {
                    max-width: 100%;
                    width: 100%;
                    border: none;
                    border-bottom: 2px solid #3162b9;
                    height: 100%;
                    outline: none;
                    font-family: "FuturaFuturisC", sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    position: relative;
                    z-index: 5;
                    background-color: transparent; }
    .hangar-page__wrapper .container .page__content {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      margin-bottom: 40px; }
      .hangar-page__wrapper .container .page__content-item {
        max-width: calc((100% - 10px) / 2);
        width: 100%;
        border: 1px solid #e5e5e5;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        margin-bottom: 10px;
        -webkit-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out; }
        .hangar-page__wrapper .container .page__content-item.hidden {
          display: none; }
        .hangar-page__wrapper .container .page__content-item.active {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; }
        .hangar-page__wrapper .container .page__content-item:hover {
          -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }
        .hangar-page__wrapper .container .page__content-item .page__content-item-title {
          max-width: 100%;
          width: 100%;
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          margin-bottom: 15px;
          text-transform: uppercase;
          margin-top: 23px;
          padding: 0 18px; }
        .hangar-page__wrapper .container .page__content-item .page__content-item-wrapper {
          max-width: 100%;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start;
          padding: 0 18px;
          margin-bottom: 25px; }
          .hangar-page__wrapper .container .page__content-item .page__content-item-wrapper .page__content-item-descr {
            max-width: 45%;
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start;
            -webkit-box-align: start;
                -ms-flex-align: start;
                    align-items: flex-start; }
            .hangar-page__wrapper .container .page__content-item .page__content-item-wrapper .page__content-item-descr p {
              color: #000;
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 14px;
              font-weight: 400;
              line-height: 19px; }
            .hangar-page__wrapper .container .page__content-item .page__content-item-wrapper .page__content-item-descr ul {
              margin-top: 35px;
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 14px;
              font-weight: 700;
              line-height: 19px; }
              .hangar-page__wrapper .container .page__content-item .page__content-item-wrapper .page__content-item-descr ul li {
                font-weight: 400; }
          .hangar-page__wrapper .container .page__content-item .page__content-item-wrapper .page__content-item-image {
            max-width: 55%;
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start;
            -webkit-box-align: start;
                -ms-flex-align: start;
                    align-items: flex-start;
            position: relative; }
            .hangar-page__wrapper .container .page__content-item .page__content-item-wrapper .page__content-item-image img {
              max-width: 100%;
              width: 100%;
              -webkit-transition: 0.1s ease-in-out;
              -o-transition: 0.1s ease-in-out;
              transition: 0.1s ease-in-out;
              cursor: pointer; }
              .hangar-page__wrapper .container .page__content-item .page__content-item-wrapper .page__content-item-image img.active {
                -webkit-transform: scale(2);
                    -ms-transform: scale(2);
                        transform: scale(2);
                -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                overflow: hidden; }
        .hangar-page__wrapper .container .page__content-item .page__content-item-footer {
          max-width: 100%;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          height: 77px;
          border-top: 1px solid #e5e5e5;
          background-color: #f5f5f5;
          padding: 0 18px; }
          .hangar-page__wrapper .container .page__content-item .page__content-item-footer .page__content-item-footer-price {
            max-width: 355px;
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: start;
                -ms-flex-align: start;
                    align-items: flex-start; }
            .hangar-page__wrapper .container .page__content-item .page__content-item-footer .page__content-item-footer-price .price strong {
              color: #000;
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 16px;
              font-weight: 700;
              line-height: 22px;
              margin-right: 20px; }
            .hangar-page__wrapper .container .page__content-item .page__content-item-footer .page__content-item-footer-price .price .price-count {
              color: #3162b9;
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 18px;
              font-weight: 700;
              line-height: 24px; }
            .hangar-page__wrapper .container .page__content-item .page__content-item-footer .page__content-item-footer-price .small-text {
              color: #737373;
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px; }
          .hangar-page__wrapper .container .page__content-item .page__content-item-footer .page__content-item-footer-link .button {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            width: 177px;
            height: 40px;
            -webkit-box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                    box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
            border-radius: 50px;
            background-color: #3162b9;
            color: #fff;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            text-decoration: none;
            cursor: pointer;
            -webkit-transition: 0.1s ease-in-out;
            -o-transition: 0.1s ease-in-out;
            transition: 0.1s ease-in-out; }
            .hangar-page__wrapper .container .page__content-item .page__content-item-footer .page__content-item-footer-link .button:hover {
              -webkit-transform: translateY(-5px) scale(1.05);
                  -ms-transform: translateY(-5px) scale(1.05);
                      transform: translateY(-5px) scale(1.05); }

.contacts-page__wrapper {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100vh - 252px); }
  .contacts-page__wrapper .contacts__map {
    max-width: 100%;
    width: 100%; }
  .contacts-page__wrapper .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-top: 50px;
    margin-bottom: 50px; }
    .contacts-page__wrapper .container .contacts__content {
      max-width: 50%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
      .contacts-page__wrapper .container .contacts__content .contacts__content-title {
        max-width: 100%;
        width: 100%;
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        margin-bottom: 15px;
        text-align: left;
        text-transform: none; }
      .contacts-page__wrapper .container .contacts__content .contacts__content-item {
        max-width: 100%;
        width: 100%;
        text-align: left;
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px; }
        .contacts-page__wrapper .container .contacts__content .contacts__content-item:last-of-type {
          margin-top: 35px; }
        .contacts-page__wrapper .container .contacts__content .contacts__content-item a {
          color: #000;
          text-decoration: none; }
    .contacts-page__wrapper .container .contacts__form {
      max-width: 50%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      margin-top: 40px; }
      .contacts-page__wrapper .container .contacts__form form {
        max-width: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start; }
        .contacts-page__wrapper .container .contacts__form form > p {
          max-width: 100%;
          width: 100%; }
          .contacts-page__wrapper .container .contacts__form form > p .errortext {
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px; }
          .contacts-page__wrapper .container .contacts__form form > p .notetext {
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: #3162b9; }
        .contacts-page__wrapper .container .contacts__form form .form-ask {
          max-width: 100%;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: justify;
              -ms-flex-pack: justify;
                  justify-content: space-between;
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; }
        .contacts-page__wrapper .container .contacts__form form .form-ask__item {
          max-width: calc((100% - 18px) / 2);
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start;
          margin-bottom: 16px; }
          .contacts-page__wrapper .container .contacts__form form .form-ask__item.textarea {
            max-width: 100%; }
            .contacts-page__wrapper .container .contacts__form form .form-ask__item.textarea .inputtextarea {
              max-width: 100%;
              width: 100%;
              border-radius: 5px;
              border: 1px solid #e5e5e5;
              background-color: transparent;
              padding-left: 12px;
              outline: none;
              color: #000;
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 14px;
              font-weight: 400;
              line-height: 19px; }
          .contacts-page__wrapper .container .contacts__form form .form-ask__item.privacy {
            display: inline;
            max-width: 100%;
            color: #737373;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px; }
            .contacts-page__wrapper .container .contacts__form form .form-ask__item.privacy a {
              color: #000;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px; }
          .contacts-page__wrapper .container .contacts__form form .form-ask__item span {
            color: #000;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px; }
            .contacts-page__wrapper .container .contacts__form form .form-ask__item span font .form-required {
              color: #3162b9; }
          .contacts-page__wrapper .container .contacts__form form .form-ask__item .inputtext {
            max-width: 100%;
            width: 100%;
            height: 33px;
            border-radius: 5px;
            border: 1px solid #e5e5e5;
            background-color: transparent;
            padding-left: 12px;
            outline: none;
            color: #000;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px; }
          .contacts-page__wrapper .container .contacts__form form .form-ask__item.button {
            -webkit-box-align: end;
                -ms-flex-align: end;
                    align-items: flex-end; }
            .contacts-page__wrapper .container .contacts__form form .form-ask__item.button input[type="submit"] {
              width: 248px;
              height: 40px;
              -webkit-box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                      box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
              border-radius: 50px;
              background-color: #3162b9;
              color: #fff;
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              text-decoration: none;
              border: none;
              outline: none; }

.gallery-page-item__wrapper {
  max-width: 100%;
  width: 100%;
  min-height: calc(100vh - 307px); }
  .gallery-page-item__wrapper .container {
    padding-top: 50px; }
    .gallery-page-item__wrapper .container .news-detail {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 55px; }
      .gallery-page-item__wrapper .container .news-detail h3 {
        max-width: 100%;
        width: 100%;
        text-align: left;
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        text-transform: uppercase;
        margin-bottom: 25px; }
      .gallery-page-item__wrapper .container .news-detail .news-detail-wrapper {
        max-width: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
        .gallery-page-item__wrapper .container .news-detail .news-detail-wrapper .news-detail__item {
          max-width: calc((100% - 21px) / 4);
          width: 100%;
          margin-bottom: 7px;
          height: 191px;
          overflow: hidden;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          cursor: pointer; }
          .gallery-page-item__wrapper .container .news-detail .news-detail-wrapper .news-detail__item:not(:nth-of-type(4n+4)) {
            margin-right: 7px; }
      .gallery-page-item__wrapper .container .news-detail .news-detail__text {
        max-width: 100%;
        width: 100%;
        margin-top: 25px;
        text-align: left;
        color: #737373;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px; }

.gallery-page__wrapper {
  max-width: 100%;
  width: 100%; }
  .gallery-page__wrapper .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
    .gallery-page__wrapper .container .gallery-page__title {
      max-width: 100%;
      width: 100%;
      text-align: left;
      color: #000;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      margin: 35px 0;
      text-transform: uppercase; }
    .gallery-page__wrapper .container .gallery-page__links {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 35px; }
      .gallery-page__wrapper .container .gallery-page__links .gallery-page__links-item {
        height: 32px;
        background-color: #e5e5e5;
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-decoration: none;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin-right: 10px;
        border-radius: 50px;
        -webkit-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out;
        padding: 0 15px; }
        .gallery-page__wrapper .container .gallery-page__links .gallery-page__links-item.active {
          background-color: #3162b9;
          color: #fff; }
        .gallery-page__wrapper .container .gallery-page__links .gallery-page__links-item:hover {
          background-color: #3162b9;
          color: #fff; }
    .gallery-page__wrapper .container .gallery-page__list {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item {
        max-width: calc((100% - 21px) / 4);
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        margin: 0 0 40px 0;
        -webkit-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out; }
        .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item.hidden {
          display: none; }
        .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item.active {
          display: -webkit-box !important;
          display: -ms-flexbox !important;
          display: flex !important; }
        .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-link {
          max-width: 100%;
          width: 100%;
          height: 191px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          margin-bottom: 30px; }
          .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-link .gallery-page__list-item-image {
            max-width: 100%;
            width: 100%;
            height: 191px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%; }
        .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-title {
          max-width: 100%;
          width: 100%;
          text-align: left;
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 700;
          line-height: 18px;
          text-decoration: none;
          height: 65px;
          padding: 0 10px; }
        .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-text {
          max-width: 100%;
          width: 100%;
          color: #737373;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          text-align: left;
          padding: 0 10px;
          margin-bottom: 10px; }
        .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-type {
          max-width: 100%;
          width: 100%;
          text-align: left;
          color: #3162b9;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          padding: 0 10px;
          text-decoration: underline; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
          box-shadow: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    -ms-transform: scale(0.75);
        transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    -ms-transform-origin: 0;
        transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    -ms-transform-origin: 100%;
        transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.price-page__wrapper {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  .price-page__wrapper .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
    .price-page__wrapper .container .price-page__title {
      max-width: 100%;
      width: 100%;
      text-align: left;
      color: #000;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      margin: 35px 0; }
      .price-page__wrapper .container .price-page__title strong {
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        margin-right: 20px;
        text-transform: uppercase; }
    .price-page__wrapper .container .price-page__links {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 35px; }
      .price-page__wrapper .container .price-page__links .price-page__links-row {
        max-width: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .price-page__wrapper .container .price-page__links .price-page__links-row .price-page__links-item {
          height: 32px;
          background-color: #e5e5e5;
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          text-decoration: none;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          margin-right: 10px;
          border-radius: 50px;
          padding: 0 10px;
          cursor: pointer;
          -webkit-transition: 0.1s ease-in-out;
          -o-transition: 0.1s ease-in-out;
          transition: 0.1s ease-in-out; }
          .price-page__wrapper .container .price-page__links .price-page__links-row .price-page__links-item.active {
            background-color: #3162b9;
            color: #fff; }
          .price-page__wrapper .container .price-page__links .price-page__links-row .price-page__links-item:hover {
            background-color: #3162b9;
            color: #fff; }
    .price-page__wrapper .container .table__wrapper {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      margin-bottom: 15px; }
      .price-page__wrapper .container .table__wrapper.hidden {
        display: none; }
      .price-page__wrapper .container .table__wrapper.active {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
      .price-page__wrapper .container .table__wrapper table.table__price {
        width: 100%;
        margin-top: 23px;
        border: 1px solid #cecdca; }
        .price-page__wrapper .container .table__wrapper table.table__price tr {
          border: 1px solid #cecdca; }
          .price-page__wrapper .container .table__wrapper table.table__price tr td {
            border: 1px solid #cecdca;
            text-align: center;
            vertical-align: middle; }
            .price-page__wrapper .container .table__wrapper table.table__price tr td .table__title {
              color: #000;
              font-family: "FuturaFuturisC", sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              max-width: 100%;
              width: 100%; }
        .price-page__wrapper .container .table__wrapper table.table__price thead {
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          height: 60px; }
          .price-page__wrapper .container .table__wrapper table.table__price thead tr {
            height: 30px; }
            .price-page__wrapper .container .table__wrapper table.table__price thead tr:first-of-type td {
              width: 25%; }
            .price-page__wrapper .container .table__wrapper table.table__price thead tr:nth-of-type(2) td {
              width: 143px; }
        .price-page__wrapper .container .table__wrapper table.table__price tbody tr td {
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 18px;
          font-weight: 400;
          line-height: 16px;
          height: 30px; }
        .price-page__wrapper .container .table__wrapper table.table__price tbody tr td[rowspan="3"] {
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 16px;
          height: 60px; }
        .price-page__wrapper .container .table__wrapper table.table__price tbody tr,
        .price-page__wrapper .container .table__wrapper table.table__price tbody tr td {
          -webkit-transition: 0.1s ease-in-out;
          -o-transition: 0.1s ease-in-out;
          transition: 0.1s ease-in-out; }
        .price-page__wrapper .container .table__wrapper table.table__price tbody .hover {
          background-color: #e6ecf6; }
      .price-page__wrapper .container .table__wrapper .table__subtitle {
        margin-top: 40px;
        max-width: 100%;
        width: 100%;
        text-align: left;
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px; }

.news-list-page {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start; }
  .news-list-page .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 50px 150px; }
    .news-list-page .container .news-item {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -webkit-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out;
      border-radius: 5px;
      padding: 20px 15px; }
      .news-list-page .container .news-item:hover {
        -webkit-transform: scale(1.025);
            -ms-transform: scale(1.025);
                transform: scale(1.025);
        -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }
      .news-list-page .container .news-item .news-date-time {
        color: #3162b9;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        max-width: 100%;
        width: 100%;
        text-align: left;
        margin-bottom: 18px; }
      .news-list-page .container .news-item .news-title-link {
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        max-width: 100%;
        width: 100%;
        text-align: left;
        margin-bottom: 12px;
        text-decoration: none; }
      .news-list-page .container .news-item .news-preview-text {
        color: #737373;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        max-width: 100%;
        width: 100%;
        text-align: left;
        margin-bottom: 25px; }
      .news-list-page .container .news-item .news-showmore-link {
        color: #3162b9;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        max-width: 100%;
        width: 100%;
        text-align: left; }
    .news-list-page .container .modern-page-navigation {
      margin-top: 25px;
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .news-list-page .container .modern-page-navigation * {
        border: none; }
      .news-list-page .container .modern-page-navigation .modern-page-title,
      .news-list-page .container .modern-page-navigation .modern-page-previous,
      .news-list-page .container .modern-page-navigation .modern-page-next {
        display: none; }
      .news-list-page .container .modern-page-navigation a {
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-decoration: none; }
      .news-list-page .container .modern-page-navigation .modern-page-current {
        color: #3162b9;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        text-decoration: underline; }

.news-item-page {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  min-height: 335px; }
  .news-item-page .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 50px 165px 0; }
    .news-item-page .container .news-item-page__date-time {
      color: #3162b9;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      max-width: 100%;
      width: 100%;
      text-align: left;
      margin-bottom: 18px; }
    .news-item-page .container .news-item-page__title {
      color: #000;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      max-width: 100%;
      width: 100%;
      text-align: left;
      margin-bottom: 18px;
      text-transform: uppercase; }
    .news-item-page .container .news-item-page__detail-text,
    .news-item-page .container .news-item-page__preview-text {
      color: #737373;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      max-width: 100%;
      width: 100%;
      text-align: left;
      margin-bottom: 18px; }
      .news-item-page .container .news-item-page__detail-text a,
      .news-item-page .container .news-item-page__preview-text a {
        color: #3162b9;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-decoration: none; }

.more-news {
  margin-top: 50px;
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 50px; }
  .more-news .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 0 150px; }
    .more-news .container .more-news__title {
      max-width: 100%;
      width: 100%;
      text-align: left;
      color: #000;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
      padding: 0 15px;
      margin-bottom: 15px; }
    .more-news .container .news-list {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
      .more-news .container .news-list .news-item {
        max-width: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        -webkit-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out;
        border-radius: 5px;
        padding: 20px 15px; }
        .more-news .container .news-list .news-item:hover {
          -webkit-transform: scale(1.025);
              -ms-transform: scale(1.025);
                  transform: scale(1.025);
          -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }
        .more-news .container .news-list .news-item .news-date-time {
          color: #3162b9;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
          max-width: 100%;
          width: 100%;
          text-align: left;
          margin-bottom: 18px; }
        .more-news .container .news-list .news-item a.title {
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          max-width: 100%;
          width: 100%;
          text-align: left;
          margin-bottom: 12px;
          text-decoration: none; }
        .more-news .container .news-list .news-item .preview-text {
          color: #737373;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          max-width: 100%;
          width: 100%;
          text-align: left;
          margin-bottom: 25px; }
        .more-news .container .news-list .news-item .showmore-link {
          color: #3162b9;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          max-width: 100%;
          width: 100%;
          text-align: left; }

.profiles-page__wrapper {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  min-height: calc(100vh - 589px); }
  .profiles-page__wrapper .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
    .profiles-page__wrapper .container .profiles-page__title {
      max-width: 100%;
      width: 100%;
      color: #000;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      text-transform: uppercase;
      margin: 50px 0 25px;
      text-align: left; }
    .profiles-page__wrapper .container .profiles-page__text {
      max-width: 100%;
      width: 100%;
      color: #000;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      margin-bottom: 40px; }
      .profiles-page__wrapper .container .profiles-page__text ul {
        list-style: disc !important;
        padding-left: 30px !important; }
      .profiles-page__wrapper .container .profiles-page__text ol {
        list-style: decimal !important;
        padding-left: 30px !important; }
    .profiles-page__wrapper .container .profiles-page__list-wrapper {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list-title {
        max-width: 100%;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        text-transform: uppercase; }
      .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list {
        max-width: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        margin-bottom: 15px; }
        .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list .profiles-page__list-item {
          max-width: 297px;
          width: 100%;
          margin-bottom: 44px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list .profiles-page__list-item:not(:nth-of-type(3n+3)) {
            margin-right: 33px; }
          .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list .profiles-page__list-item .profiles-page__list-item-link {
            max-width: 100%;
            width: 100%;
            height: 165px;
            margin-bottom: 26px;
            border-radius: 2px;
            border: 1px solid #e5e5e5;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-transition: 0.1s ease-in-out;
            -o-transition: 0.1s ease-in-out;
            transition: 0.1s ease-in-out; }
            .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list .profiles-page__list-item .profiles-page__list-item-link .preview_picture {
              max-width: 80%;
              width: 100%;
              height: 80%;
              background-position: 50% 50%;
              background-size: contain;
              background-repeat: no-repeat;
              -webkit-transition: 0.1s ease-in-out;
              -o-transition: 0.1s ease-in-out;
              transition: 0.1s ease-in-out; }
            .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list .profiles-page__list-item .profiles-page__list-item-link:hover {
              -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
              background-color: rgba(49, 98, 185, 0.05); }
              .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list .profiles-page__list-item .profiles-page__list-item-link:hover .preview_picture {
                max-width: 95%;
                height: 95%; }
          .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list .profiles-page__list-item .profiles-page__list-item-title {
            max-width: 100%;
            width: 100%;
            text-align: center;
            color: #000;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            text-transform: uppercase;
            text-decoration: none; }
      .profiles-page__wrapper .container .profiles-page__list-wrapper .news-list {
        max-width: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        margin-bottom: 25px; }
        .profiles-page__wrapper .container .profiles-page__list-wrapper .news-list .news-item a.title {
          max-width: 100%;
          width: 100%;
          text-align: center;
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-transform: none;
          text-decoration: none; }
    .profiles-page__wrapper .container .profiles-page__list-dop-links {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      margin-bottom: 25px; }
      .profiles-page__wrapper .container .profiles-page__list-dop-links span {
        max-width: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .profiles-page__wrapper .container .profiles-page__list-dop-links span a {
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-transform: none;
          text-decoration: none; }

.profile-item-page {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100vh - 589px); }
  .profile-item-page .profile-item-page__title {
    max-width: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #000;
    font-family: "FuturaFuturisC", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-transform: uppercase;
    margin-top: 50px; }
  .profile-item-page .profile-item-page__content {
    max-width: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start; }
    .profile-item-page .profile-item-page__content .profile-item-page__content-text {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding-top: 25px; }
      .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text {
        max-width: 100%;
        width: 100%;
        display: block;
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 25px; }
        .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text h3 {
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 600; }
        .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text b {
          font-weight: 600; }
        .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text p {
          margin-bottom: 15px; }
        .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text ul {
          list-style: disc !important;
          padding-left: 30px !important; }
        .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text ol {
          list-style: decimal !important;
          padding-left: 30px !important; }
        .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper {
          max-width: 100%;
          width: 100%;
          display: block;
          margin-top: 25px; }
          .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper .specs-link {
            color: #000;
            width: auto;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 40px;
            text-decoration: underline;
            padding-left: 45px;
            position: relative;
            height: 40px;
            display: none; }
            .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper .specs-link::before {
              position: absolute;
              content: '';
              left: 0;
              top: 50%;
              -webkit-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                      transform: translateY(-50%);
              width: 31px;
              height: 31px;
              background-image: url(/bitrix/templates/trendspb_enerpro/images/pdf.png);
              background-position: 50% 50%;
              background-repeat: no-repeat;
              background-size: contain; }
          .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper .price-link {
            width: auto;
            height: 40px;
            border-radius: 40px;
            background-color: #3162b9;
            color: #fff;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            display: block;
            padding: 10px 15px;
            text-decoration: none;
            -webkit-transition: 0.1s ease-in-out;
            -o-transition: 0.1s ease-in-out;
            transition: 0.1s ease-in-out;
            cursor: pointer;
            float: left; }
            .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper .price-link:hover {
              -webkit-transform: translateY(-5px) scale(1.05);
                  -ms-transform: translateY(-5px) scale(1.05);
                      transform: translateY(-5px) scale(1.05); }
        .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .profile-item-page__detail-picture {
          max-width: 471px;
          width: 100%;
          margin: 0 0 25px 25px;
          float: right;
          height: 390px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          background-position: 50% 50%;
          background-size: contain;
          background-repeat: no-repeat;
          -webkit-transition: 0.1s ease-in-out;
          -o-transition: 0.1s ease-in-out;
          transition: 0.1s ease-in-out; }
          .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .profile-item-page__detail-picture:hover {
            -webkit-box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.2); }
    .profile-item-page .profile-item-page__content .profile-item-page__preview-table {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .profile-item-page .profile-item-page__content .profile-item-page__preview-table table.profil-table {
        margin-bottom: 50px; }
        .profile-item-page .profile-item-page__content .profile-item-page__preview-table table.profil-table caption {
          text-align: left;
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 18px;
          font-weight: 700;
          line-height: 30px; }
        .profile-item-page .profile-item-page__content .profile-item-page__preview-table table.profil-table tr td {
          border: 1px solid #cecdca;
          text-align: center;
          vertical-align: middle;
          height: 31px; }
        .profile-item-page .profile-item-page__content .profile-item-page__preview-table table.profil-table thead tr td {
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 11px;
          font-weight: 700;
          line-height: 13px; }
        .profile-item-page .profile-item-page__content .profile-item-page__preview-table table.profil-table thead tr td[rowspan],
        .profile-item-page .profile-item-page__content .profile-item-page__preview-table table.profil-table thead tr td[colspan] {
          font-size: 14px;
          line-height: 16px; }
        .profile-item-page .profile-item-page__content .profile-item-page__preview-table table.profil-table tbody tr {
          -webkit-transition: 0.1s ease-in-out;
          -o-transition: 0.1s ease-in-out;
          transition: 0.1s ease-in-out; }
          .profile-item-page .profile-item-page__content .profile-item-page__preview-table table.profil-table tbody tr:hover {
            background-color: #e6ecf6; }
          .profile-item-page .profile-item-page__content .profile-item-page__preview-table table.profil-table tbody tr td {
            color: #000;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 15px; }
  .profile-item-page > .container > .news-list {
    max-width: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-bottom: 25px; }
    .profile-item-page > .container > .news-list .news-item a.title {
      max-width: 100%;
      width: 100%;
      text-align: center;
      color: #000;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-transform: none;
      text-decoration: none; }

.search-page__wrapper {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  min-height: calc(100vh - 252px); }
  .search-page__wrapper .container {
    padding: 50px 150px; }
    .search-page__wrapper .container h1 {
      color: #000;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      max-width: 100%;
      width: 100%;
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 25px; }
    .search-page__wrapper .container .search-page {
      max-width: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start; }
      .search-page__wrapper .container .search-page p {
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px; }
      .search-page__wrapper .container .search-page .search-page__form {
        max-width: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 0; }
        .search-page__wrapper .container .search-page .search-page__form input[name="q"] {
          width: 50%;
          height: 40px;
          -webkit-box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                  box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
          background-color: white;
          outline: none;
          border: 1px solid #3162b9;
          border-radius: 50px;
          padding-left: 15px;
          font-family: "FuturaFuturisC", sans-serif;
          line-height: 1;
          color: #737373;
          -webkit-transition: 0.5s ease-in-out;
          -o-transition: 0.5s ease-in-out;
          transition: 0.5s ease-in-out; }
          .search-page__wrapper .container .search-page .search-page__form input[name="q"]::-webkit-input-placeholder {
            color: #737373; }
          .search-page__wrapper .container .search-page .search-page__form input[name="q"]:-ms-input-placeholder {
            color: #737373; }
          .search-page__wrapper .container .search-page .search-page__form input[name="q"]::-ms-input-placeholder {
            color: #737373; }
          .search-page__wrapper .container .search-page .search-page__form input[name="q"]::placeholder {
            color: #737373; }
        .search-page__wrapper .container .search-page .search-page__form select[name="where"] {
          width: 25%;
          height: 40px;
          padding-left: 15px;
          -webkit-box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                  box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
          background-color: white;
          outline: none;
          border: 1px solid #3162b9;
          border-radius: 50px;
          font-family: "FuturaFuturisC", sans-serif;
          line-height: 1;
          color: #737373;
          -webkit-transition: 0.5s ease-in-out;
          -o-transition: 0.5s ease-in-out;
          transition: 0.5s ease-in-out; }
        .search-page__wrapper .container .search-page .search-page__form input[type="submit"] {
          width: 25%;
          height: 40px;
          -webkit-box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                  box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
          border-radius: 50px;
          background-color: #3162b9;
          color: #fff;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          text-decoration: none;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          -webkit-transition: 0.2s ease-in-out;
          -o-transition: 0.2s ease-in-out;
          transition: 0.2s ease-in-out;
          cursor: pointer;
          border: none;
          outline: none; }
      .search-page__wrapper .container .search-page .search-page__item {
        max-width: 100%;
        width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out;
        border-radius: 5px;
        padding: 20px 15px; }
        .search-page__wrapper .container .search-page .search-page__item:hover {
          -webkit-transform: scale(1.025);
              -ms-transform: scale(1.025);
                  transform: scale(1.025);
          -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); }
        .search-page__wrapper .container .search-page .search-page__item-title {
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 20px;
          max-width: 100%;
          width: 100%;
          text-align: left;
          text-decoration: none; }
        .search-page__wrapper .container .search-page .search-page__item-text {
          color: #737373;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          max-width: 100%;
          width: 100%;
          text-align: left;
          margin-top: 25px;
          margin-bottom: 25px; }
        .search-page__wrapper .container .search-page .search-page__item-date, .search-page__wrapper .container .search-page .search-page__item-path,
        .search-page__wrapper .container .search-page .search-page__item-path a {
          color: #3162b9;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          max-width: 100%;
          width: 100%;
          text-align: left; }
        .search-page__wrapper .container .search-page .search-page__item-date {
          display: none; }
      .search-page__wrapper .container .search-page .modern-page-navigation {
        margin-top: 25px;
        max-width: 100%;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        .search-page__wrapper .container .search-page .modern-page-navigation * {
          border: none; }
        .search-page__wrapper .container .search-page .modern-page-navigation .modern-page-title,
        .search-page__wrapper .container .search-page .modern-page-navigation .modern-page-previous,
        .search-page__wrapper .container .search-page .modern-page-navigation .modern-page-next {
          display: none; }
        .search-page__wrapper .container .search-page .modern-page-navigation a {
          color: #000;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          text-decoration: none; }
        .search-page__wrapper .container .search-page .modern-page-navigation .modern-page-current {
          color: #3162b9;
          font-family: "FuturaFuturisC", sans-serif;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          text-decoration: underline; }
      .search-page__wrapper .container .search-page .sort {
        max-width: 100%;
        width: 100%;
        display: none;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding: 0 15px;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px; }

.privacy-page__content {
  padding: 40px 0;
  font-family: "FuturaFuturisC", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px; }
  .privacy-page__content h1 {
    color: #000;
    font-family: "FuturaFuturisC", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    max-width: 100%;
    width: 100%;
    text-align: left;
    text-decoration: none; }

.not-found-page__wrapper {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100vh - 252px); }
  .not-found-page__wrapper .container {
    padding: 50px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .not-found-page__wrapper .container .not-found-page__title {
      color: #000;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      max-width: 100%;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 45px; }
    .not-found-page__wrapper .container .not-found-page__subtitle {
      color: #000;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      max-width: 100%;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 25px; }
    .not-found-page__wrapper .container .map-columns {
      max-width: 75%;
      width: 100%; }
      .not-found-page__wrapper .container .map-columns tbody tr td {
        padding: 0 25px; }
        .not-found-page__wrapper .container .map-columns tbody tr td ul li {
          text-align: center; }
          .not-found-page__wrapper .container .map-columns tbody tr td ul li a {
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px; }

.about-page__wrapper {
  max-width: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100vh - 252px); }
  .about-page__wrapper .container {
    padding: 50px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .about-page__wrapper .container .about-page__title {
      color: #000;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      max-width: 100%;
      width: 100%;
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 25px; }
    .about-page__wrapper .container .about-page__content {
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px; }
      .about-page__wrapper .container .about-page__content p {
        margin-bottom: 15px; }

.header__toggle,
.close-button,
.for-mobile {
  display: none; }

@media screen and (max-width: 425px) {
  header {
    height: 64px; }
    header .container .header__logo {
      margin: 0;
      max-width: 45%;
      width: 100%; }
      header .container .header__logo-link-image {
        max-width: 127px;
        width: 100%; }
    header .container .header__contacts {
      max-width: 40%;
      width: 100%; }
      header .container .header__contacts .header__contacts-phone a {
        font-size: 12px;
        font-weight: 700;
        line-height: 14px; }
      header .container .header__contacts .header__contacts-email a {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px; }
    header .container .header__toggle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      width: 19px;
      height: 19px;
      background-image: url(/bitrix/templates/trendspb_enerpro/images/svg/toggle.svg);
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat; }
    header .container #horizontal-multilevel-menu {
      display: none; }
    header .container #search_top {
      display: none; }
    header .container .toggle__menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      min-height: 100vh;
      overflow-y: scroll;
      overflow-x: hidden;
      background-color: #fff;
      z-index: -10000;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out; }
      header .container .toggle__menu.active {
        z-index: 10000;
        opacity: 1;
        visibility: visible;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        header .container .toggle__menu.active .close-button {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          width: 15px;
          height: 15px;
          position: absolute;
          top: 40px;
          left: 40px;
          background-image: url("../../images/svg/cancel.svg");
          background-position: 50% 50%;
          background-size: cover;
          background-repeat: no-repeat; }
        header .container .toggle__menu.active #horizontal-multilevel-menu {
          position: absolute;
          max-width: calc(100% - 40px);
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start;
          padding-left: 20px;
          top: 165px;
          padding-bottom: 100px; }
          header .container .toggle__menu.active #horizontal-multilevel-menu > li {
            min-height: 36px;
            height: auto; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li.for-mobile {
              display: list-item; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li > a {
              color: #000;
              font-size: 22px;
              font-weight: 400;
              line-height: 36px;
              min-height: 36px;
              height: auto; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li::after {
              bottom: unset;
              left: unset;
              -webkit-transform: translateY(-50%) rotate(-90deg);
                  -ms-transform: translateY(-50%) rotate(-90deg);
                      transform: translateY(-50%) rotate(-90deg);
              right: -30px;
              top: 18px; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li:hover::after {
              -webkit-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                      transform: translateY(-50%);
              right: 0; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li.parent-item {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
                  -ms-flex-direction: column;
                      flex-direction: column;
              -webkit-box-pack: start;
                  -ms-flex-pack: start;
                      justify-content: flex-start;
              -webkit-box-align: start;
                  -ms-flex-align: start;
                      align-items: flex-start; }
              header .container .toggle__menu.active #horizontal-multilevel-menu > li.parent-item > a {
                pointer-events: none !important;
                height: auto; }
              header .container .toggle__menu.active #horizontal-multilevel-menu > li.parent-item .parent::after {
                display: none; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu {
              position: relative;
              -webkit-box-shadow: none;
                      box-shadow: none;
              margin-top: 10px;
              border-bottom: 1px solid #e5e5e5;
              max-width: 90%;
              background-color: transparent; }
              header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li {
                -webkit-box-pack: start;
                    -ms-flex-pack: start;
                        justify-content: flex-start;
                min-width: unset;
                margin: 0 0 0 10px;
                padding: 5px;
                line-height: 16px;
                min-height: 16px;
                height: 100%; }
                header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li:hover {
                  background-color: transparent; }
                header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li a {
                  line-height: 16px;
                  min-height: 16px;
                  height: 100%; }
                header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item {
                  -webkit-box-orient: vertical;
                  -webkit-box-direction: normal;
                      -ms-flex-direction: column;
                          flex-direction: column;
                  -webkit-box-pack: start;
                      -ms-flex-pack: start;
                          justify-content: flex-start;
                  -webkit-box-align: start;
                      -ms-flex-align: start;
                          align-items: flex-start;
                  line-height: 16px;
                  min-height: 16px;
                  height: 100%; }
                  header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item a {
                    line-height: 16px;
                    min-height: 16px;
                    height: 100%; }
                    header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item a::after {
                      bottom: unset;
                      left: unset;
                      -webkit-transform: translateY(-50%);
                          -ms-transform: translateY(-50%);
                              transform: translateY(-50%);
                      right: -30px;
                      top: 8px; }
                    header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item a:hover::after {
                      right: 0; }
                  header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item ul.sub-menu-2 {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    position: relative;
                    right: 0;
                    margin: 10px 0 0 0;
                    -webkit-box-shadow: none;
                            box-shadow: none;
                    max-width: 90%;
                    background-color: transparent; }
                    header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item ul.sub-menu-2 li {
                      -webkit-box-pack: start;
                          -ms-flex-pack: start;
                              justify-content: flex-start;
                      min-width: unset;
                      min-height: unset;
                      margin: 0 0 0 10px;
                      padding: 5px; }
                      header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item ul.sub-menu-2 li:hover {
                        background-color: transparent; }
        header .container .toggle__menu.active #search_top {
          max-width: calc(100% - 40px);
          width: 100%;
          padding-left: 20px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          margin-top: 90px;
          height: auto; }
          header .container .toggle__menu.active #search_top form {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            max-width: 80%;
            width: 100%;
            position: relative; }
            header .container .toggle__menu.active #search_top form .search-suggest {
              position: unset;
              opacity: 1;
              visibility: visible;
              -webkit-transform: none;
                  -ms-transform: none;
                      transform: none;
              z-index: 50; }
            header .container .toggle__menu.active #search_top form .search-button {
              position: absolute;
              right: 10px;
              top: 50%;
              -webkit-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                      transform: translateY(-50%);
              z-index: 100; }
  .front-page-banner {
    height: 75vh;
    background-size: cover; }
    .front-page-banner .container {
      padding: 0 15px; }
      .front-page-banner .container .front-page-banner__title {
        max-width: 100%;
        width: 100%;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px; }
      .front-page-banner .container .front-page-banner__subtitle {
        max-width: 100%;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 25px; }
  .front-page-prefences {
    height: auto; }
    .front-page-prefences .container {
      padding-top: 40px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .front-page-prefences .container .front-page-prefences__item {
        max-width: 100%;
        padding: 0 !important;
        border: none !important;
        margin-bottom: 25px; }
        .front-page-prefences .container .front-page-prefences__item .front-page-prefences__item-title {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.7px;
          line-height: 16px;
          margin-bottom: 15px;
          text-transform: uppercase; }
        .front-page-prefences .container .front-page-prefences__item .front-page-prefences__item-text {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px; }
        .front-page-prefences .container .front-page-prefences__item:last-of-type {
          padding-bottom: 45px !important;
          border-bottom: 1px solid #e5e5e5 !important;
          margin-bottom: 0; }
  .front-page-about {
    height: unset; }
    .front-page-about .container {
      padding-top: 45px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .front-page-about .container .front-page-about__text .front-page-about__text-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        margin-bottom: 25px; }
      .front-page-about .container .front-page-about__text .front-page-about__text-subtitle,
      .front-page-about .container .front-page-about__text .front-page-about__text-content {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px; }
      .front-page-about .container .front-page-about__text .front-page-about__text-btn {
        margin-bottom: 40px;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px; }
      .front-page-about .container .front-page-about__pics {
        max-width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
        .front-page-about .container .front-page-about__pics .front-page-about__pics-item {
          max-width: 100%; }
          .front-page-about .container .front-page-about__pics .front-page-about__pics-item .front-page-about__pics-item-image {
            height: 160px;
            margin-bottom: 19px; }
          .front-page-about .container .front-page-about__pics .front-page-about__pics-item .front-page-about__pics-item-title {
            padding: 0; }
          .front-page-about .container .front-page-about__pics .front-page-about__pics-item .front-page-about__pics-item-text {
            padding: 0;
            margin-bottom: 25px; }
  .front-page-form {
    margin-bottom: 40px; }
    .front-page-form .container > div form {
      padding: 35px 15px 0; }
      .front-page-form .container > div form .form__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        margin-bottom: 13px; }
      .front-page-form .container > div form .form__wrapper .form__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        max-width: 100%;
        width: 100%; }
        .front-page-form .container > div form .form__wrapper .form__row .form__item {
          height: unset !important;
          margin-bottom: 25px; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.name {
            -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                    order: 1; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.phone {
            -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                    order: 2; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.email {
            -webkit-box-ordinal-group: 4;
                -ms-flex-order: 3;
                    order: 3; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.text {
            -webkit-box-ordinal-group: 5;
                -ms-flex-order: 4;
                    order: 4;
            margin-top: 0; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.captcha {
            -webkit-box-ordinal-group: 6;
                -ms-flex-order: 5;
                    order: 5;
            max-width: 100%; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.privacy {
            -webkit-box-ordinal-group: 7;
                -ms-flex-order: 6;
                    order: 6;
            max-width: 100%; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.submit-btn {
            max-width: 100%;
            -webkit-box-ordinal-group: 8;
                -ms-flex-order: 7;
                    order: 7; }
            .front-page-form .container > div form .form__wrapper .form__row .form__item.submit-btn input[type=submit] {
              display: block;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              text-align: center; }
  .front-page-gallery {
    margin-bottom: 30px; }
    .front-page-gallery .container .news-list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .front-page-gallery .container .news-list .grid-sizer,
      .front-page-gallery .container .news-list .gutter-sizer {
        display: none; }
      .front-page-gallery .container .news-list .news-item {
        margin-bottom: 6px; }
        .front-page-gallery .container .news-list .news-item:nth-of-type(3) {
          max-width: 100%;
          height: 198px; }
        .front-page-gallery .container .news-list .news-item:nth-of-type(4) {
          max-width: 49%;
          margin-right: 2%;
          height: 95px; }
        .front-page-gallery .container .news-list .news-item:nth-of-type(5) {
          max-width: 49%;
          height: 95px; }
        .front-page-gallery .container .news-list .news-item:nth-of-type(6), .front-page-gallery .container .news-list .news-item:nth-of-type(7), .front-page-gallery .container .news-list .news-item:nth-of-type(8), .front-page-gallery .container .news-list .news-item:nth-of-type(9), .front-page-gallery .container .news-list .news-item:nth-of-type(10), .front-page-gallery .container .news-list .news-item:nth-of-type(11) {
          display: none !important; }
  .front-page-news {
    margin-bottom: 25px; }
    .front-page-news .container .news-list {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; }
      .front-page-news .container .news-list .news-item {
        max-width: 100%;
        padding: 0 !important;
        margin: 0 0 25px 0 !important;
        border: none !important; }
        .front-page-news .container .news-list .news-item .news-title-link {
          min-height: unset; }
        .front-page-news .container .news-list .news-item:nth-of-type(2) {
          margin: 0 !important; }
        .front-page-news .container .news-list .news-item:nth-of-type(3), .front-page-news .container .news-list .news-item:nth-of-type(4) {
          display: none; }
  .front-page-contacts {
    margin-bottom: 0; }
    .front-page-contacts .container {
      -webkit-box-orient: vertical !important;
      -webkit-box-direction: normal !important;
          -ms-flex-direction: column !important;
              flex-direction: column !important;
      padding: 0; }
      .front-page-contacts .container .contacts__block {
        max-width: 100%;
        padding: 0 15px; }
      .front-page-contacts .container .contacts__map {
        max-width: 100%;
        padding: 0; }
  footer.footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: unset; }
    footer.footer .footer__wrapper {
      height: unset; }
      footer.footer .footer__wrapper .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: unset; }
        footer.footer .footer__wrapper .container .footer__menu {
          max-width: 100%;
          height: unset;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          margin-bottom: 25px;
          padding-top: 35px; }
          footer.footer .footer__wrapper .container .footer__menu ul {
            height: unset;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center; }
            footer.footer .footer__wrapper .container .footer__menu ul li {
              max-width: unset;
              width: auto;
              margin: 5px; }
        footer.footer .footer__wrapper .container .footer__contacts {
          max-width: 100%;
          height: unset;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          margin-bottom: 27px; }
          footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--adress,
          footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--phone,
          footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--email {
            max-width: 100%;
            width: 100%;
            margin-bottom: 10px; }
            footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--adress .footer__contacts-adress,
            footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--adress .footer__contacts-text,
            footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--phone .footer__contacts-adress,
            footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--phone .footer__contacts-text,
            footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--email .footer__contacts-adress,
            footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--email .footer__contacts-text {
              width: 100%;
              text-align: center; }
            footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--adress .footer__contacts-subtitle,
            footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--phone .footer__contacts-subtitle,
            footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--email .footer__contacts-subtitle {
              display: none; }
    footer.footer .copyrights {
      height: unset; }
      footer.footer .copyrights .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 15px; }
        footer.footer .copyrights .container .copyrights__year {
          max-width: 100%;
          margin-bottom: 10px; }
          footer.footer .copyrights .container .copyrights__year span {
            text-align: center;
            color: #cecdca;
            font-size: 11px;
            font-weight: 400;
            line-height: 13px; }
        footer.footer .copyrights .container .copyrights__trend {
          max-width: 100%;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center; }
          footer.footer .copyrights .container .copyrights__trend span,
          footer.footer .copyrights .container .copyrights__trend span a {
            max-width: 100%;
            text-align: center;
            color: #cecdca;
            font-size: 11px;
            font-weight: 400;
            line-height: 13px; }
    footer.footer .modals__wrapper {
      overflow: scroll; }
      footer.footer .modals__wrapper .modal__request {
        margin: 20px auto; }
        footer.footer .modals__wrapper .modal__request .container > div form {
          max-width: 100%;
          padding: 25px 10px;
          border-radius: 10px; }
          footer.footer .modals__wrapper .modal__request .container > div form > p {
            max-width: 100%;
            width: 100%; }
            footer.footer .modals__wrapper .modal__request .container > div form > p .errortext {
              color: red; }
            footer.footer .modals__wrapper .modal__request .container > div form > p .notetext {
              color: #3162b9; }
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item span {
            max-width: 35%; }
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item img {
            max-width: 40%; }
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item input,
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item select,
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item textarea {
            max-width: 60%; }
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item input[type=submit] {
            max-width: 100%; }
      footer.footer .modals__wrapper .modal__buy {
        margin: 20px auto; }
        footer.footer .modals__wrapper .modal__buy .container > div form {
          max-width: 100%;
          padding: 25px 10px;
          border-radius: 10px; }
          footer.footer .modals__wrapper .modal__buy .container > div form > p {
            max-width: 100%;
            width: 100%; }
            footer.footer .modals__wrapper .modal__buy .container > div form > p .errortext {
              color: red; }
            footer.footer .modals__wrapper .modal__buy .container > div form > p .notetext {
              color: #3162b9; }
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item span {
            max-width: 35%; }
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item img {
            max-width: 40%; }
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item input,
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item select,
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item textarea {
            max-width: 60%; }
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item input[type=submit] {
            max-width: 100%; } }

.page__subtitle-button {
  display: none; }

@media screen and (max-width: 425px) {
  .hangar-page__wrapper {
    padding-top: 40px; }
    .hangar-page__wrapper .container .page__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 21px; }
    .hangar-page__wrapper .container .page__sizes .page__sizes-links {
      max-width: 100%;
      width: 100%; }
      .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row {
        max-width: 100%;
        width: 100%;
        display: block;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        height: 44px;
        border-radius: 30px;
        border: 1px solid #cecdca;
        overflow: hidden;
        position: relative;
        -webkit-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out; }
        .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row::after {
          position: absolute;
          right: 22px;
          top: 22px;
          -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
                  transform: translateY(-50%);
          content: '';
          background-image: url(../../images/svg/caret-litle.svg);
          background-position: 50% 50%;
          background-size: contain;
          background-repeat: no-repeat;
          width: 9px;
          height: 6px;
          -webkit-transition: 0.1s ease-in-out;
          -o-transition: 0.1s ease-in-out;
          transition: 0.1s ease-in-out; }
        .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row.active {
          height: auto; }
          .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row.active::after {
            -webkit-transform: translateY(-50%) rotate(180deg);
                -ms-transform: translateY(-50%) rotate(180deg);
                    transform: translateY(-50%) rotate(180deg); }
        .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row .page__sizes-links-title {
          max-width: 100%;
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          height: 44px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          padding-left: 20px; }
        .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row .page__sizes-links-item {
          max-width: 100%;
          width: 100%;
          height: 44px;
          border-radius: 0; }
        .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row .cancel {
          max-width: 100%;
          width: 100%;
          height: 44px;
          line-height: 44px;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
    .hangar-page__wrapper .container .page__sizes-button {
      display: none; }
    .hangar-page__wrapper .container .page__subtitle .page__subtitle-button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      max-width: 100%;
      width: 100%; }
      .hangar-page__wrapper .container .page__subtitle .page__subtitle-button .page__subtitle-button-item {
        width: 100%;
        height: 40px;
        border-radius: 40px;
        background-color: #737373;
        color: #fff;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        text-decoration: none;
        -webkit-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out;
        cursor: pointer; }
        .hangar-page__wrapper .container .page__subtitle .page__subtitle-button .page__subtitle-button-item:hover {
          -webkit-transform: translateY(-5px) scale(1.05);
              -ms-transform: translateY(-5px) scale(1.05);
                  transform: translateY(-5px) scale(1.05);
          background-color: #3162b9; }
    .hangar-page__wrapper .container .page__content .page__content-item {
      max-width: 100%; }
      .hangar-page__wrapper .container .page__content .page__content-item .page__content-item-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
        .hangar-page__wrapper .container .page__content .page__content-item .page__content-item-wrapper .page__content-item-descr {
          max-width: 100%; }
          .hangar-page__wrapper .container .page__content .page__content-item .page__content-item-wrapper .page__content-item-descr ul {
            margin-top: 25px; }
        .hangar-page__wrapper .container .page__content .page__content-item .page__content-item-wrapper .page__content-item-image {
          max-width: 100%;
          margin-top: 25px; }
          .hangar-page__wrapper .container .page__content .page__content-item .page__content-item-wrapper .page__content-item-image .active {
            -webkit-transform: scale(1.3);
                -ms-transform: scale(1.3);
                    transform: scale(1.3); }
      .hangar-page__wrapper .container .page__content .page__content-item .page__content-item-footer {
        height: auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 20px 15px; }
        .hangar-page__wrapper .container .page__content .page__content-item .page__content-item-footer .page__content-item-footer-price {
          max-width: 100%; }
        .hangar-page__wrapper .container .page__content .page__content-item .page__content-item-footer .page__content-item-footer-link {
          max-width: 100%;
          width: 100%;
          margin-top: 20px; }
          .hangar-page__wrapper .container .page__content .page__content-item .page__content-item-footer .page__content-item-footer-link .button {
            max-width: 100%;
            width: 100%;
            text-align: center; }
    .hangar-page__wrapper .container .form {
      margin-bottom: 40px; }
      .hangar-page__wrapper .container .form > div form {
        padding: 35px 15px 0; }
        .hangar-page__wrapper .container .form > div form > p {
          max-width: 100%;
          width: 100%; }
          .hangar-page__wrapper .container .form > div form > p .errortext {
            color: red; }
          .hangar-page__wrapper .container .form > div form > p .notetext {
            color: #3162b9; }
        .hangar-page__wrapper .container .form > div form .form__title {
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          text-transform: uppercase;
          margin-bottom: 13px; }
        .hangar-page__wrapper .container .form > div form .form__wrapper .form__row {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          max-width: 100%;
          width: 100%; }
          .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item {
            height: unset !important;
            margin-bottom: 25px; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.name {
              -webkit-box-ordinal-group: 2;
                  -ms-flex-order: 1;
                      order: 1; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.phone {
              -webkit-box-ordinal-group: 3;
                  -ms-flex-order: 2;
                      order: 2; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.email {
              -webkit-box-ordinal-group: 4;
                  -ms-flex-order: 3;
                      order: 3; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.captcha {
              -webkit-box-ordinal-group: 5;
                  -ms-flex-order: 4;
                      order: 4;
              max-width: 100%; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.privacy {
              -webkit-box-ordinal-group: 6;
                  -ms-flex-order: 5;
                      order: 5;
              max-width: 100%; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.submit-btn {
              max-width: 100%;
              -webkit-box-ordinal-group: 7;
                  -ms-flex-order: 6;
                      order: 6; } }

@media screen and (max-width: 425px) {
  .contacts-page__wrapper .container {
    margin-top: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
    .contacts-page__wrapper .container .contacts__content {
      max-width: 100%; }
      .contacts-page__wrapper .container .contacts__content .contacts__content-title {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px; }
      .contacts-page__wrapper .container .contacts__content .contacts__content-item {
        margin-bottom: 25px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
        .contacts-page__wrapper .container .contacts__content .contacts__content-item:last-of-type {
          margin-top: 0; }
        .contacts-page__wrapper .container .contacts__content .contacts__content-item span {
          max-width: 100%;
          width: 100%;
          text-align: left;
          font-weight: bold; }
    .contacts-page__wrapper .container .contacts__form {
      max-width: 100%;
      margin-top: 0; }
      .contacts-page__wrapper .container .contacts__form > div form > p {
        max-width: 100%;
        width: 100%; }
        .contacts-page__wrapper .container .contacts__form > div form > p .errortext {
          color: red; }
        .contacts-page__wrapper .container .contacts__form > div form > p .notetext {
          color: #3162b9; }
      .contacts-page__wrapper .container .contacts__form > div form .form-ask .form-ask__item {
        max-width: 100%; }
        .contacts-page__wrapper .container .contacts__form > div form .form-ask .form-ask__item.button input[type=submit] {
          width: 100%; } }

.gallery-page__links-title {
  display: none; }

@media screen and (max-width: 425px) {
  .gallery-page__wrapper .container .gallery-page__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px; }
  .gallery-page__wrapper .container .gallery-page__links {
    max-width: 100%;
    width: 100%;
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    height: 44px;
    border-radius: 30px;
    border: 1px solid #cecdca;
    overflow: hidden;
    position: relative;
    -webkit-transition: 0.1s ease-in-out;
    -o-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out; }
    .gallery-page__wrapper .container .gallery-page__links::after {
      position: absolute;
      right: 22px;
      top: 22px;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      content: '';
      background-image: url(../../images/svg/caret-litle.svg);
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat;
      width: 9px;
      height: 6px;
      -webkit-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out; }
    .gallery-page__wrapper .container .gallery-page__links.active {
      height: auto; }
      .gallery-page__wrapper .container .gallery-page__links.active::after {
        -webkit-transform: translateY(-50%) rotate(180deg);
            -ms-transform: translateY(-50%) rotate(180deg);
                transform: translateY(-50%) rotate(180deg); }
    .gallery-page__wrapper .container .gallery-page__links .gallery-page__links-title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      max-width: 100%;
      width: 100%;
      height: 44px;
      border-radius: 0;
      color: #000;
      font-family: "FuturaFuturisC", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      padding-left: 20px; }
    .gallery-page__wrapper .container .gallery-page__links .gallery-page__links-item {
      max-width: 100%;
      width: 100%;
      height: 44px;
      border-radius: 0; }
  .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item {
    max-width: 100%; }
    .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-link {
      margin-bottom: 5px; }
    .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-title {
      height: unset;
      margin-bottom: 5px; }
    .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-text {
      margin-bottom: 5px; }
  .gallery-page__wrapper .container .form {
    margin-bottom: 40px; }
    .gallery-page__wrapper .container .form > div form {
      padding: 35px 15px 0; }
      .gallery-page__wrapper .container .form > div form > p {
        max-width: 100%;
        width: 100%; }
        .gallery-page__wrapper .container .form > div form > p .errortext {
          color: red; }
        .gallery-page__wrapper .container .form > div form > p .notetext {
          color: #3162b9; }
      .gallery-page__wrapper .container .form > div form .form__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        margin-bottom: 13px; }
      .gallery-page__wrapper .container .form > div form .form__wrapper .form__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        max-width: 100%;
        width: 100%; }
        .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item {
          height: unset !important;
          margin-bottom: 25px; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.name {
            -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                    order: 1; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.phone {
            -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                    order: 2; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.email {
            -webkit-box-ordinal-group: 4;
                -ms-flex-order: 3;
                    order: 3; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.captcha {
            -webkit-box-ordinal-group: 5;
                -ms-flex-order: 4;
                    order: 4;
            max-width: 100%; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.privacy {
            -webkit-box-ordinal-group: 6;
                -ms-flex-order: 5;
                    order: 5;
            max-width: 100%; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.submit-btn {
            max-width: 100%;
            -webkit-box-ordinal-group: 7;
                -ms-flex-order: 6;
                    order: 6; }
  .gallery-page-item__wrapper .container {
    padding-top: 40px; }
    .gallery-page-item__wrapper .container .news-detail h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 21px; }
    .gallery-page-item__wrapper .container .news-detail .news-detail-wrapper .news-detail__item {
      max-width: 100%;
      margin: 0 0 7px 0 !important; } }

.price-page__links-title {
  display: none; }

@media screen and (max-width: 425px) {
  .price-page__wrapper .container .price-page__title strong {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px; }
  .price-page__wrapper .container .price-page__links {
    max-width: 100%;
    width: 100%; }
    .price-page__wrapper .container .price-page__links .price-page__links-row {
      max-width: 100%;
      width: 100%;
      display: block;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
      height: 44px;
      border-radius: 30px;
      border: 1px solid #cecdca;
      overflow: hidden;
      position: relative;
      -webkit-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out; }
      .price-page__wrapper .container .price-page__links .price-page__links-row::after {
        position: absolute;
        right: 22px;
        top: 22px;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
        content: '';
        background-image: url(../../images/svg/caret-litle.svg);
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        width: 9px;
        height: 6px;
        -webkit-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out; }
      .price-page__wrapper .container .price-page__links .price-page__links-row.active {
        height: auto; }
        .price-page__wrapper .container .price-page__links .price-page__links-row.active::after {
          -webkit-transform: translateY(-50%) rotate(180deg);
              -ms-transform: translateY(-50%) rotate(180deg);
                  transform: translateY(-50%) rotate(180deg); }
      .price-page__wrapper .container .price-page__links .price-page__links-row .price-page__links-title {
        max-width: 100%;
        width: 100%;
        height: 44px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        color: #000;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        padding-left: 20px; }
      .price-page__wrapper .container .price-page__links .price-page__links-row .price-page__links-item {
        max-width: 100%;
        width: 100%;
        height: 44px;
        border-radius: 0; }
      .price-page__wrapper .container .price-page__links .price-page__links-row .cancel {
        max-width: 100%;
        width: 100%;
        height: 44px;
        line-height: 44px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
  .price-page__wrapper .container .table__wrapper table.table__price thead {
    font-size: 12px;
    line-height: 14px; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:first-of-type td:nth-of-type(1) {
      width: 15%; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:first-of-type td:nth-of-type(2) {
      width: 20%; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:first-of-type td:nth-of-type(3) {
      width: 20%; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:first-of-type td:nth-of-type(4) {
      width: 45%; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:nth-of-type(2) td {
      width: 22.5%; }
  .price-page__wrapper .container .table__wrapper table.table__price tbody tr td {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px; }
  .price-page__wrapper .container .form {
    margin-bottom: 40px; }
    .price-page__wrapper .container .form > div form {
      padding: 35px 15px 0; }
      .price-page__wrapper .container .form > div form > p {
        max-width: 100%;
        width: 100%; }
        .price-page__wrapper .container .form > div form > p .errortext {
          color: red; }
        .price-page__wrapper .container .form > div form > p .notetext {
          color: #3162b9; }
      .price-page__wrapper .container .form > div form .form__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        margin-bottom: 13px; }
      .price-page__wrapper .container .form > div form .form__wrapper .form__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        max-width: 100%;
        width: 100%; }
        .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item {
          height: unset !important;
          margin-bottom: 25px; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.name {
            -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                    order: 1; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.phone {
            -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                    order: 2; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.email {
            -webkit-box-ordinal-group: 4;
                -ms-flex-order: 3;
                    order: 3; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.captcha {
            -webkit-box-ordinal-group: 5;
                -ms-flex-order: 4;
                    order: 4;
            max-width: 100%; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.privacy {
            -webkit-box-ordinal-group: 6;
                -ms-flex-order: 5;
                    order: 5;
            max-width: 100%; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.submit-btn {
            max-width: 100%;
            -webkit-box-ordinal-group: 7;
                -ms-flex-order: 6;
                    order: 6; } }

@media screen and (max-width: 425px) {
  .profiles-page__wrapper .container .price-page__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-top: 40px; }
  .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column; }
  .profile-item-page .profile-item-page__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-top: 40px; }
  .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text img {
    max-width: 100%;
    height: auto; }
  .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .profile-item-page__detail-picture {
    margin: 0 auto 25px; }
  .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper .specs-link {
    font-size: 14px;
    padding-left: 30px; }
    .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper .specs-link:before {
      width: 20px;
      height: 20px; }
  .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper .price-link {
    width: 100%;
    text-align: center;
    margin-top: 15px; }
  .profile-item-page .profile-item-page__content .profile-item-page__preview-table {
    overflow-x: scroll; }
    .profile-item-page .profile-item-page__content .profile-item-page__preview-table .profil-table {
      width: 100% !important;
      overflow-x: scroll;
      margin-bottom: 20px !important; }
      .profile-item-page .profile-item-page__content .profile-item-page__preview-table .profil-table caption {
        font-size: 14px !important; } }

@media screen and (max-width: 425px) {
  .news-list-page .container {
    padding: 40px 15px; }
  .news-item-page .container {
    padding: 40px 15px; }
    .news-item-page .container .news-item-page__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 21px; }
  .more-news .container {
    padding: 0 15px; } }

@media screen and (max-width: 425px) {
  .search-page__wrapper .container {
    padding: 40px 15px; }
    .search-page__wrapper .container .search-page__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 21px; }
    .search-page__wrapper .container .search-page .search-page__form {
      padding: 0;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .search-page__wrapper .container .search-page .search-page__form input[name=q] {
        max-width: 100%;
        width: 100%;
        margin-bottom: 10px; }
      .search-page__wrapper .container .search-page .search-page__form select[name=where] {
        max-width: 49%;
        width: 100%; }
      .search-page__wrapper .container .search-page .search-page__form input[type=submit] {
        max-width: 49%;
        width: 100%; } }

@media screen and (max-width: 425px) {
  .breadcrumbs__wrapper .container {
    height: 100%; }
    .breadcrumbs__wrapper .container .bx-breadcrumb {
      overflow-x: scroll;
      height: 100%; }
      .breadcrumbs__wrapper .container .bx-breadcrumb .bx-breadcrumb-item {
        min-width: -webkit-max-content;
        min-width: -moz-max-content;
        min-width: max-content;
        height: 100%; } }

.header__toggle,
.close-button,
.for-mobile {
  display: none; }

@media (min-width: 426px) and (max-width: 768px) {
  header {
    height: 64px; }
    header .container .header__logo {
      margin: 0;
      max-width: 45%;
      width: 100%; }
      header .container .header__logo-link-image {
        max-width: 127px;
        width: 100%; }
    header .container .header__contacts {
      max-width: 40%;
      width: 100%; }
      header .container .header__contacts .header__contacts-phone a {
        font-size: 12px;
        font-weight: 700;
        line-height: 14px; }
      header .container .header__contacts .header__contacts-email a {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px; }
    header .container .header__toggle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      width: 19px;
      height: 19px;
      background-image: url(/bitrix/templates/trendspb_enerpro/images/svg/toggle.svg);
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat; }
    header .container #horizontal-multilevel-menu {
      display: none; }
    header .container #search_top {
      display: none; }
    header .container .toggle__menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      min-height: 100vh;
      overflow-y: scroll;
      overflow-x: hidden;
      background-color: #fff;
      z-index: -10000;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out; }
      header .container .toggle__menu.active {
        z-index: 10000;
        opacity: 1;
        visibility: visible;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        header .container .toggle__menu.active .close-button {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          width: 15px;
          height: 15px;
          position: absolute;
          top: 40px;
          left: 40px;
          background-image: url("../../images/svg/cancel.svg");
          background-position: 50% 50%;
          background-size: cover;
          background-repeat: no-repeat; }
        header .container .toggle__menu.active #horizontal-multilevel-menu {
          position: absolute;
          max-width: 100%;
          -webkit-box-flex: 0;
              -ms-flex: 0 1 100%;
                  flex: 0 1 100%;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          top: 165px;
          padding-bottom: 100px; }
          header .container .toggle__menu.active #horizontal-multilevel-menu > li {
            min-height: 36px;
            height: auto;
            margin: 0;
            -webkit-box-flex: 0;
                -ms-flex: 0 1 auto;
                    flex: 0 1 auto; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li.for-mobile {
              display: list-item; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li > a {
              color: #000;
              font-size: 22px;
              font-weight: 400;
              line-height: 36px;
              min-height: 36px;
              height: auto; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li::after {
              bottom: unset;
              left: unset;
              -webkit-transform: translateY(-50%) rotate(-90deg);
                  -ms-transform: translateY(-50%) rotate(-90deg);
                      transform: translateY(-50%) rotate(-90deg);
              right: -30px;
              top: 18px; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li:hover::after {
              -webkit-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                      transform: translateY(-50%);
              right: 0; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li.parent-item {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
                  -ms-flex-direction: column;
                      flex-direction: column;
              -webkit-box-pack: start;
                  -ms-flex-pack: start;
                      justify-content: flex-start;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center; }
              header .container .toggle__menu.active #horizontal-multilevel-menu > li.parent-item > a {
                pointer-events: none !important;
                height: auto; }
              header .container .toggle__menu.active #horizontal-multilevel-menu > li.parent-item .parent::after {
                display: none; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu {
              position: relative;
              -webkit-box-shadow: none;
                      box-shadow: none;
              margin: 10px 0;
              background-color: transparent;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              -webkit-box-flex: 0;
                  -ms-flex: 0 1 auto;
                      flex: 0 1 auto; }
              header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li {
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                min-width: unset;
                margin: 5px 0;
                padding: 0;
                line-height: 16px;
                min-height: 16px;
                -webkit-box-flex: 0;
                    -ms-flex: 0 1 auto;
                        flex: 0 1 auto;
                height: auto; }
                header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li:hover {
                  background-color: transparent; }
                header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li a {
                  line-height: 16px;
                  min-height: 16px;
                  height: 100%;
                  text-align: center;
                  padding: 5px;
                  color: #737373 !important; }
                header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item {
                  display: block;
                  -webkit-box-orient: vertical;
                  -webkit-box-direction: normal;
                      -ms-flex-direction: column;
                          flex-direction: column;
                  -webkit-box-pack: start;
                      -ms-flex-pack: start;
                          justify-content: flex-start;
                  -webkit-box-align: center;
                      -ms-flex-align: center;
                          align-items: center;
                  line-height: 16px;
                  min-height: 16px;
                  height: auto; }
                  header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item a {
                    line-height: 16px;
                    min-height: 16px;
                    height: 100%;
                    text-align: center; }
                    header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item a::after {
                      bottom: unset;
                      left: unset;
                      -webkit-transform: translateY(-50%);
                          -ms-transform: translateY(-50%);
                              transform: translateY(-50%);
                      right: -30px;
                      top: 8px; }
                    header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item a:hover::after {
                      right: 0; }
                  header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item ul.sub-menu-2 {
                    right: 0;
                    display: block;
                    position: unset;
                    -webkit-box-shadow: none;
                            box-shadow: none;
                    margin: 5px 0;
                    background-color: transparent;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;
                    -webkit-box-flex: 0;
                        -ms-flex: 0 1 auto;
                            flex: 0 1 auto;
                    height: auto; }
                    header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item ul.sub-menu-2 li {
                      -webkit-box-pack: center;
                          -ms-flex-pack: center;
                              justify-content: center;
                      min-width: unset;
                      margin: 5px 0;
                      padding: 0;
                      line-height: 16px;
                      min-height: 16px;
                      height: 100%;
                      -webkit-box-flex: 0;
                          -ms-flex: 0 1 auto;
                              flex: 0 1 auto; }
                      header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item ul.sub-menu-2 li:hover {
                        background-color: transparent; }
                      header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item ul.sub-menu-2 li a {
                        line-height: 16px;
                        min-height: 16px;
                        height: 100%;
                        text-align: center;
                        padding: 5px; }
        header .container .toggle__menu.active #search_top {
          max-width: 300px;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          margin-top: 90px;
          height: auto; }
          header .container .toggle__menu.active #search_top form {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            max-width: 100%;
            width: 100%;
            position: relative; }
            header .container .toggle__menu.active #search_top form .search-suggest {
              position: unset;
              opacity: 1;
              visibility: visible;
              -webkit-transform: none;
                  -ms-transform: none;
                      transform: none;
              z-index: 50; }
            header .container .toggle__menu.active #search_top form .search-button {
              position: absolute;
              right: 10px;
              top: 50%;
              -webkit-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                      transform: translateY(-50%);
              z-index: 100; }
  .front-page-banner {
    height: 371px;
    background-size: cover; }
    .front-page-banner .container {
      padding: 0 15px; }
      .front-page-banner .container .front-page-banner__title {
        max-width: 100%;
        width: 100%;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px; }
      .front-page-banner .container .front-page-banner__subtitle {
        max-width: 100%;
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-bottom: 35px; }
  .front-page-prefences {
    min-height: 266px;
    height: auto; }
    .front-page-prefences .container {
      padding-top: 40px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      display: -webkit-flex; }
      .front-page-prefences .container .front-page-prefences__item {
        display: -webkit-flex;
        max-width: 48%;
        margin-bottom: 25px;
        -webkit-box-flex: 0;
            -ms-flex: 0 1 50%;
                flex: 0 1 50%; }
        .front-page-prefences .container .front-page-prefences__item:nth-of-type(3) {
          padding: 0 25px 0 0; }
        .front-page-prefences .container .front-page-prefences__item:nth-of-type(2) {
          border: none; }
        .front-page-prefences .container .front-page-prefences__item .front-page-prefences__item-title {
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.7px;
          line-height: 16px;
          margin-bottom: 15px;
          text-transform: uppercase; }
        .front-page-prefences .container .front-page-prefences__item .front-page-prefences__item-text {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px; }
  .front-page-about {
    min-height: 710px;
    height: auto; }
    .front-page-about .container {
      padding-top: 40px;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .front-page-about .container .front-page-about__text {
        max-width: 100%;
        width: 100%;
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto; }
        .front-page-about .container .front-page-about__text .front-page-about__text-title {
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          text-transform: uppercase;
          margin-bottom: 25px; }
        .front-page-about .container .front-page-about__text .front-page-about__text-subtitle,
        .front-page-about .container .front-page-about__text .front-page-about__text-content {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px; }
        .front-page-about .container .front-page-about__text .front-page-about__text-btn {
          margin-bottom: 40px;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px; }
      .front-page-about .container .front-page-about__pics {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
        max-width: 100%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; }
        .front-page-about .container .front-page-about__pics .front-page-about__pics-item {
          max-width: unset;
          -webkit-box-flex: 0;
              -ms-flex: 0 1 calc((100% - 14px) / 3);
                  flex: 0 1 calc((100% - 14px) / 3); }
          .front-page-about .container .front-page-about__pics .front-page-about__pics-item .front-page-about__pics-item-image {
            height: 237px;
            margin-bottom: 19px; }
          .front-page-about .container .front-page-about__pics .front-page-about__pics-item .front-page-about__pics-item-title {
            padding: 0; }
          .front-page-about .container .front-page-about__pics .front-page-about__pics-item .front-page-about__pics-item-text {
            padding: 0;
            margin-bottom: 25px; }
  .front-page-form {
    margin-bottom: 40px; }
    .front-page-form .container > div form {
      padding: 35px 15px 0; }
      .front-page-form .container > div form .form__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        margin-bottom: 13px; }
      .front-page-form .container > div form .form__wrapper .form__row {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        max-width: 100%;
        width: 100%; }
        .front-page-form .container > div form .form__wrapper .form__row .form__item {
          height: 40px;
          margin-bottom: 15px;
          max-width: unset;
          -webkit-box-flex: 0;
              -ms-flex: 0 1 calc((100% - 26px) / 2);
                  flex: 0 1 calc((100% - 26px) / 2); }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.name {
            -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                    order: 1; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.phone {
            -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                    order: 2; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.text {
            -webkit-box-flex: 0;
                -ms-flex: 0 1 100%;
                    flex: 0 1 100%;
            -webkit-box-ordinal-group: 4;
                -ms-flex-order: 3;
                    order: 3;
            margin-top: 0; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.email {
            -webkit-box-ordinal-group: 5;
                -ms-flex-order: 4;
                    order: 4; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.submit-btn {
            max-width: unset;
            -webkit-box-flex: 0;
                -ms-flex: 0 1 calc((100% - 26px) / 2);
                    flex: 0 1 calc((100% - 26px) / 2);
            -webkit-box-ordinal-group: 6;
                -ms-flex-order: 5;
                    order: 5; }
            .front-page-form .container > div form .form__wrapper .form__row .form__item.submit-btn input[type=submit] {
              display: block;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              text-align: center; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.captcha {
            -webkit-box-ordinal-group: 7;
                -ms-flex-order: 6;
                    order: 6;
            -webkit-box-flex: 0;
                -ms-flex: 0 1 100%;
                    flex: 0 1 100%;
            margin: 10px 0 0 10px;
            max-width: unset; }
            .front-page-form .container > div form .form__wrapper .form__row .form__item.captcha .inputtext {
              max-width: 100%; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.privacy {
            -webkit-box-ordinal-group: 8;
                -ms-flex-order: 7;
                    order: 7;
            -webkit-box-flex: 0;
                -ms-flex: 0 1 100%;
                    flex: 0 1 100%; }
  .front-page-gallery {
    margin-bottom: 30px; }
    .front-page-gallery .container .news-list .grid-sizer {
      max-width: 24%;
      width: 100%; }
    .front-page-gallery .container .news-list .gutter-sizer {
      width: 1%; }
    .front-page-gallery .container .news-list .news-item {
      margin-bottom: 6px;
      max-width: 24%; }
      .front-page-gallery .container .news-list .news-item:nth-of-type(3) {
        max-width: 49%; }
      .front-page-gallery .container .news-list .news-item:nth-of-type(6) {
        max-width: 49%; }
      .front-page-gallery .container .news-list .news-item:nth-of-type(9), .front-page-gallery .container .news-list .news-item:nth-of-type(10), .front-page-gallery .container .news-list .news-item:nth-of-type(11) {
        display: none !important; }
  .front-page-news {
    margin-bottom: 25px; }
    .front-page-news .container .news-list .news-item {
      max-width: 50%;
      margin: 0;
      padding-bottom: 25px !important; }
      .front-page-news .container .news-list .news-item .news-title-link {
        min-height: unset; }
      .front-page-news .container .news-list .news-item:nth-of-type(2) {
        border: none; }
      .front-page-news .container .news-list .news-item:nth-of-type(3), .front-page-news .container .news-list .news-item:nth-of-type(4) {
        display: none; }
  .front-page-contacts {
    margin-bottom: 0; }
    .front-page-contacts .container {
      padding: 0; }
      .front-page-contacts .container .contacts__block {
        max-width: 35%;
        padding: 0 15px; }
      .front-page-contacts .container .contacts__map {
        max-width: 65%;
        padding: 0; }
  footer.footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: unset; }
    footer.footer .footer__wrapper {
      height: unset; }
      footer.footer .footer__wrapper .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: unset; }
        footer.footer .footer__wrapper .container .footer__menu {
          max-width: 100%;
          height: unset;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          padding: 25px 75px 15px; }
          footer.footer .footer__wrapper .container .footer__menu ul {
            height: unset;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center; }
            footer.footer .footer__wrapper .container .footer__menu ul li {
              max-width: unset;
              width: auto;
              margin: 5px 10px; }
        footer.footer .footer__wrapper .container .footer__contacts {
          max-width: 100%;
          height: unset;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          margin-bottom: 15px; }
          footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--adress {
            max-width: 75%;
            width: 100%;
            margin-bottom: 10px;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center; }
            footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--adress .footer__contacts-adress {
              width: auto; }
          footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--email {
            max-width: unset;
            width: auto;
            margin-bottom: 10px; }
          footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--phone {
            max-width: unset;
            width: auto;
            margin-bottom: 10px;
            margin-right: 10px; }
          footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-adress,
          footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-text {
            width: 100%;
            text-align: center; }
    footer.footer .copyrights {
      height: unset; }
      footer.footer .copyrights .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 15px; }
        footer.footer .copyrights .container .copyrights__year {
          max-width: 100%;
          margin-bottom: 10px;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center; }
          footer.footer .copyrights .container .copyrights__year span {
            text-align: center;
            color: #cecdca;
            font-size: 11px;
            font-weight: 400;
            line-height: 13px; }
        footer.footer .copyrights .container .copyrights__trend {
          max-width: 100%;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center; }
          footer.footer .copyrights .container .copyrights__trend span,
          footer.footer .copyrights .container .copyrights__trend span a {
            max-width: 100%;
            text-align: center;
            color: #cecdca;
            font-size: 11px;
            font-weight: 400;
            line-height: 13px; }
    footer.footer .modals__wrapper {
      overflow: scroll; }
      footer.footer .modals__wrapper .modal__request {
        margin: 20px auto; }
        footer.footer .modals__wrapper .modal__request .container > div form {
          max-width: 100%;
          padding: 25px 10px;
          border-radius: 10px; }
          footer.footer .modals__wrapper .modal__request .container > div form > p {
            max-width: 100%;
            width: 100%; }
            footer.footer .modals__wrapper .modal__request .container > div form > p .errortext {
              color: red; }
            footer.footer .modals__wrapper .modal__request .container > div form > p .notetext {
              color: #3162b9; }
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item span {
            max-width: 35%; }
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item img {
            max-width: 40%; }
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item input,
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item select,
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item textarea {
            max-width: 60%; }
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item input[type=submit] {
            max-width: 100%; }
      footer.footer .modals__wrapper .modal__buy {
        margin: 20px auto; }
        footer.footer .modals__wrapper .modal__buy .container > div form {
          max-width: 100%;
          padding: 25px 10px;
          border-radius: 10px; }
          footer.footer .modals__wrapper .modal__buy .container > div form > p {
            max-width: 100%;
            width: 100%; }
            footer.footer .modals__wrapper .modal__buy .container > div form > p .errortext {
              color: red; }
            footer.footer .modals__wrapper .modal__buy .container > div form > p .notetext {
              color: #3162b9; }
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item span {
            max-width: 35%; }
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item img {
            max-width: 40%; }
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item input,
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item select,
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item textarea {
            max-width: 60%; }
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item input[type=submit] {
            max-width: 100%; } }

.price-page__links-title {
  display: none; }

@media (min-width: 426px) and (max-width: 768px) {
  .price-page__wrapper .container .price-page__title strong {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px; }
  .price-page__wrapper .container .price-page__links {
    max-width: 100%;
    width: 100%; }
    .price-page__wrapper .container .price-page__links .price-page__links-row {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .price-page__wrapper .container .price-page__links .price-page__links-row .price-page__links-item {
        margin-bottom: 10px; }
  .price-page__wrapper .container .table__wrapper table.table__price thead {
    font-size: 12px;
    line-height: 14px; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:first-of-type td:nth-of-type(1) {
      width: 15%; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:first-of-type td:nth-of-type(2) {
      width: 20%; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:first-of-type td:nth-of-type(3) {
      width: 20%; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:first-of-type td:nth-of-type(4) {
      width: 45%; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:nth-of-type(2) td {
      width: 22.5%; }
  .price-page__wrapper .container .table__wrapper table.table__price tbody tr td {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px; }
  .price-page__wrapper .container .form {
    margin-bottom: 40px; }
    .price-page__wrapper .container .form > div form {
      padding: 35px 15px 0; }
      .price-page__wrapper .container .form > div form > p {
        max-width: 100%;
        width: 100%; }
        .price-page__wrapper .container .form > div form > p .errortext {
          color: red; }
        .price-page__wrapper .container .form > div form > p .notetext {
          color: #3162b9; }
      .price-page__wrapper .container .form > div form .form__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        margin-bottom: 13px; }
      .price-page__wrapper .container .form > div form .form__wrapper .form__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        max-width: 100%;
        width: 100%; }
        .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item {
          height: unset !important;
          margin-bottom: 25px; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.name {
            -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                    order: 1; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.phone {
            -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                    order: 2; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.email {
            -webkit-box-ordinal-group: 4;
                -ms-flex-order: 3;
                    order: 3; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.captcha {
            -webkit-box-ordinal-group: 5;
                -ms-flex-order: 4;
                    order: 4;
            max-width: 100%; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.privacy {
            -webkit-box-ordinal-group: 6;
                -ms-flex-order: 5;
                    order: 5;
            max-width: 100%; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.submit-btn {
            max-width: 100%;
            -webkit-box-ordinal-group: 7;
                -ms-flex-order: 6;
                    order: 6; } }

.page__subtitle-button {
  display: none; }

@media (min-width: 426px) and (max-width: 768px) {
  .hangar-page__wrapper {
    padding-top: 40px; }
    .hangar-page__wrapper .container .page__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 21px; }
    .hangar-page__wrapper .container .page__sizes {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .hangar-page__wrapper .container .page__sizes .page__sizes-links {
        width: auto;
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
        margin-bottom: 10px; }
        .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row .page__sizes-links-title {
          display: none; }
        .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row .cancel {
          margin-right: 0; }
      .hangar-page__wrapper .container .page__sizes .page__sizes-button {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
        max-width: 200px;
        margin-bottom: 10px; }
        .hangar-page__wrapper .container .page__sizes .page__sizes-button .page__sizes-button-item {
          height: 32px; }
    .hangar-page__wrapper .container .page__subtitle .page__subtitle-button {
      display: none;
      max-width: 100%;
      width: 100%; }
      .hangar-page__wrapper .container .page__subtitle .page__subtitle-button .page__subtitle-button-item {
        width: 100%;
        height: 40px;
        border-radius: 40px;
        background-color: #737373;
        color: #fff;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        text-decoration: none;
        -webkit-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out;
        cursor: pointer; }
        .hangar-page__wrapper .container .page__subtitle .page__subtitle-button .page__subtitle-button-item:hover {
          -webkit-transform: translateY(-5px) scale(1.05);
              -ms-transform: translateY(-5px) scale(1.05);
                  transform: translateY(-5px) scale(1.05);
          background-color: #3162b9; }
    .hangar-page__wrapper .container .page__content .page__content-item {
      max-width: 100%; }
      .hangar-page__wrapper .container .page__content .page__content-item .page__content-item-wrapper .page__content-item-image .active {
        -webkit-transform: scale(1.5);
            -ms-transform: scale(1.5);
                transform: scale(1.5); }
    .hangar-page__wrapper .container .form {
      margin-bottom: 40px; }
      .hangar-page__wrapper .container .form > div form {
        padding: 35px 15px 0; }
        .hangar-page__wrapper .container .form > div form > p {
          max-width: 100%;
          width: 100%; }
          .hangar-page__wrapper .container .form > div form > p .errortext {
            color: red; }
          .hangar-page__wrapper .container .form > div form > p .notetext {
            color: #3162b9; }
        .hangar-page__wrapper .container .form > div form .form__title {
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          text-transform: uppercase;
          margin-bottom: 13px; }
        .hangar-page__wrapper .container .form > div form .form__wrapper .form__row {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          max-width: 100%;
          width: 100%; }
          .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item {
            height: unset !important;
            margin-bottom: 25px; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.name {
              -webkit-box-ordinal-group: 2;
                  -ms-flex-order: 1;
                      order: 1; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.phone {
              -webkit-box-ordinal-group: 3;
                  -ms-flex-order: 2;
                      order: 2; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.email {
              -webkit-box-ordinal-group: 4;
                  -ms-flex-order: 3;
                      order: 3; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.captcha {
              -webkit-box-ordinal-group: 5;
                  -ms-flex-order: 4;
                      order: 4;
              max-width: 100%; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.privacy {
              -webkit-box-ordinal-group: 6;
                  -ms-flex-order: 5;
                      order: 5;
              max-width: 100%; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.submit-btn {
              max-width: 100%;
              -webkit-box-ordinal-group: 7;
                  -ms-flex-order: 6;
                      order: 6; } }

@media (min-width: 426px) and (max-width: 768px) {
  .breadcrumbs__wrapper .container {
    height: 100%; }
    .breadcrumbs__wrapper .container .bx-breadcrumb {
      overflow-x: scroll;
      height: 100%; }
      .breadcrumbs__wrapper .container .bx-breadcrumb .bx-breadcrumb-item {
        min-width: -webkit-max-content;
        min-width: -moz-max-content;
        min-width: max-content;
        height: 100%; } }

@media (min-width: 426px) and (max-width: 768px) {
  .contacts-page__wrapper .container {
    margin-top: 40px; }
    .contacts-page__wrapper .container .contacts__content {
      max-width: 49%; }
      .contacts-page__wrapper .container .contacts__content .contacts__content-title {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px; }
    .contacts-page__wrapper .container .contacts__form {
      max-width: 49%;
      margin-top: 0; }
      .contacts-page__wrapper .container .contacts__form > div form > p {
        max-width: 100%;
        width: 100%; }
        .contacts-page__wrapper .container .contacts__form > div form > p .errortext {
          color: red; }
        .contacts-page__wrapper .container .contacts__form > div form > p .notetext {
          color: #3162b9; }
      .contacts-page__wrapper .container .contacts__form > div form .form-ask .form-ask__item {
        max-width: 100%; }
        .contacts-page__wrapper .container .contacts__form > div form .form-ask .form-ask__item.button input[type=submit] {
          width: 100%; } }

@media (min-width: 426px) and (max-width: 768px) {
  .profiles-page__wrapper .container .price-page__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-top: 40px; }
  .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list .profiles-page__list-item {
      -webkit-box-flex: 0;
          -ms-flex: 0 1 297px;
              flex: 0 1 297px;
      margin-right: 0 !important; }
      .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list .profiles-page__list-item:nth-of-type(2n + 1) {
        margin-right: 33px !important; }
  .profile-item-page .profile-item-page__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-top: 40px; }
  .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text img {
    max-width: 100%;
    height: auto; }
  .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .profile-item-page__detail-picture {
    max-width: 375px;
    margin: 0 0 25px 15px; }
  .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper .specs-link {
    font-size: 14px;
    padding-left: 30px; }
    .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper .specs-link:before {
      width: 20px;
      height: 20px; }
  .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper .price-link {
    max-width: 40%;
    width: 100%;
    text-align: center;
    margin: 0;
    float: left; }
  .profile-item-page .profile-item-page__content .profile-item-page__preview-table .profil-table {
    width: 100% !important;
    overflow-x: scroll;
    margin-bottom: 20px !important; }
    .profile-item-page .profile-item-page__content .profile-item-page__preview-table .profil-table caption {
      font-size: 14px !important; } }

.gallery-page__links-title {
  display: none; }

@media (min-width: 426px) and (max-width: 768px) {
  .gallery-page__wrapper .container .gallery-page__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px; }
  .gallery-page__wrapper .container .gallery-page__links {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .gallery-page__wrapper .container .gallery-page__links .gallery-page__links-item {
      margin-bottom: 10px; }
  .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item {
    max-width: unset;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 49%;
            flex: 0 1 49%; }
    .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-link {
      margin-bottom: 5px; }
    .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-title {
      height: unset;
      margin-bottom: 5px; }
    .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-text {
      margin-bottom: 5px; }
  .gallery-page__wrapper .container .form {
    margin-bottom: 40px; }
    .gallery-page__wrapper .container .form > div form {
      padding: 35px 15px 0; }
      .gallery-page__wrapper .container .form > div form > p {
        max-width: 100%;
        width: 100%; }
        .gallery-page__wrapper .container .form > div form > p .errortext {
          color: red; }
        .gallery-page__wrapper .container .form > div form > p .notetext {
          color: #3162b9; }
      .gallery-page__wrapper .container .form > div form .form__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        margin-bottom: 13px; }
      .gallery-page__wrapper .container .form > div form .form__wrapper .form__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        max-width: 100%;
        width: 100%; }
        .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item {
          height: unset !important;
          margin-bottom: 25px; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.name {
            -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                    order: 1; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.phone {
            -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                    order: 2; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.email {
            -webkit-box-ordinal-group: 4;
                -ms-flex-order: 3;
                    order: 3; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.captcha {
            -webkit-box-ordinal-group: 5;
                -ms-flex-order: 4;
                    order: 4;
            max-width: 100%; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.privacy {
            -webkit-box-ordinal-group: 6;
                -ms-flex-order: 5;
                    order: 5;
            max-width: 100%; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.submit-btn {
            max-width: 100%;
            -webkit-box-ordinal-group: 7;
                -ms-flex-order: 6;
                    order: 6; }
  .gallery-page-item__wrapper .container {
    padding-top: 40px; }
    .gallery-page-item__wrapper .container .news-detail h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 21px; }
    .gallery-page-item__wrapper .container .news-detail .news-detail-wrapper {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .gallery-page-item__wrapper .container .news-detail .news-detail-wrapper .news-detail__item {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 49%;
                flex: 0 1 49%;
        max-width: unset;
        margin: 0 0 5px 0 !important;
        height: 250px; } }

@media (min-width: 426px) and (max-width: 768px) {
  .news-list-page .container {
    padding: 40px 15px; }
  .news-item-page .container {
    padding: 40px 15px; }
    .news-item-page .container .news-item-page__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 21px; }
  .more-news .container {
    padding: 0 15px; } }

.header__toggle,
.close-button,
.for-mobile {
  display: none; }

@media (min-width: 769px) and (max-width: 1200px) {
  header {
    height: 64px; }
    header .container .header__logo {
      margin: 0;
      max-width: 45%;
      width: 100%; }
      header .container .header__logo-link-image {
        max-width: 127px;
        width: 100%; }
    header .container .header__contacts {
      max-width: 40%;
      width: 100%; }
      header .container .header__contacts .header__contacts-phone a {
        font-size: 12px;
        font-weight: 700;
        line-height: 14px; }
      header .container .header__contacts .header__contacts-email a {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px; }
    header .container .header__toggle {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      width: 19px;
      height: 19px;
      background-image: url(/bitrix/templates/trendspb_enerpro/images/svg/toggle.svg);
      background-position: 50% 50%;
      background-size: contain;
      background-repeat: no-repeat; }
    header .container #horizontal-multilevel-menu {
      display: none; }
    header .container #search_top {
      display: none; }
    header .container .toggle__menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      min-height: 100vh;
      overflow-y: scroll;
      overflow-x: hidden;
      background-color: #fff;
      z-index: -10000;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: 0.1s ease-in-out;
      -o-transition: 0.1s ease-in-out;
      transition: 0.1s ease-in-out; }
      header .container .toggle__menu.active {
        z-index: 10000;
        opacity: 1;
        visibility: visible;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        header .container .toggle__menu.active .close-button {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          width: 15px;
          height: 15px;
          position: absolute;
          top: 40px;
          left: 40px;
          background-image: url("../../images/svg/cancel.svg");
          background-position: 50% 50%;
          background-size: cover;
          background-repeat: no-repeat; }
        header .container .toggle__menu.active #horizontal-multilevel-menu {
          position: absolute;
          max-width: 100%;
          -webkit-box-flex: 0;
              -ms-flex: 0 1 100%;
                  flex: 0 1 100%;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          top: 165px;
          padding-bottom: 100px; }
          header .container .toggle__menu.active #horizontal-multilevel-menu > li {
            min-height: 36px;
            height: auto;
            margin: 0;
            -webkit-box-flex: 0;
                -ms-flex: 0 1 auto;
                    flex: 0 1 auto; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li.for-mobile {
              display: list-item; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li > a {
              color: #000;
              font-size: 22px;
              font-weight: 400;
              line-height: 36px;
              min-height: 36px;
              height: auto; }
              header .container .toggle__menu.active #horizontal-multilevel-menu > li > a.root-item-selected {
                color: #3162b9; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li::after {
              bottom: unset;
              left: unset;
              -webkit-transform: translateY(-50%) rotate(-90deg);
                  -ms-transform: translateY(-50%) rotate(-90deg);
                      transform: translateY(-50%) rotate(-90deg);
              right: -30px;
              top: 18px; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li:hover::after {
              -webkit-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                      transform: translateY(-50%);
              right: 0; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li.parent-item {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
                  -ms-flex-direction: column;
                      flex-direction: column;
              -webkit-box-pack: start;
                  -ms-flex-pack: start;
                      justify-content: flex-start;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center; }
              header .container .toggle__menu.active #horizontal-multilevel-menu > li.parent-item > a {
                pointer-events: none !important;
                height: auto; }
              header .container .toggle__menu.active #horizontal-multilevel-menu > li.parent-item .parent::after {
                display: none; }
            header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu {
              position: relative;
              -webkit-box-shadow: none;
                      box-shadow: none;
              margin: 10px 0;
              background-color: transparent;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              -webkit-box-flex: 0;
                  -ms-flex: 0 1 auto;
                      flex: 0 1 auto; }
              header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li {
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                min-width: unset;
                margin: 5px 0;
                padding: 0;
                line-height: 16px;
                min-height: 16px;
                -webkit-box-flex: 0;
                    -ms-flex: 0 1 auto;
                        flex: 0 1 auto;
                height: auto; }
                header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li:hover {
                  background-color: transparent; }
                header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li a {
                  line-height: 16px;
                  min-height: 16px;
                  height: 100%;
                  text-align: center;
                  padding: 5px; }
                header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item {
                  display: block;
                  -webkit-box-orient: vertical;
                  -webkit-box-direction: normal;
                      -ms-flex-direction: column;
                          flex-direction: column;
                  -webkit-box-pack: start;
                      -ms-flex-pack: start;
                          justify-content: flex-start;
                  -webkit-box-align: center;
                      -ms-flex-align: center;
                          align-items: center;
                  line-height: 16px;
                  min-height: 16px;
                  height: auto; }
                  header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item a {
                    line-height: 16px;
                    min-height: 16px;
                    height: 100%;
                    text-align: center; }
                    header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item a::after {
                      bottom: unset;
                      left: unset;
                      -webkit-transform: translateY(-50%);
                          -ms-transform: translateY(-50%);
                              transform: translateY(-50%);
                      right: -30px;
                      top: 8px; }
                    header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item a:hover::after {
                      right: 0; }
                  header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item ul.sub-menu-2 {
                    right: 0;
                    display: block;
                    position: unset;
                    -webkit-box-shadow: none;
                            box-shadow: none;
                    margin: 5px 0;
                    background-color: transparent;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;
                    -webkit-box-flex: 0;
                        -ms-flex: 0 1 auto;
                            flex: 0 1 auto;
                    height: auto; }
                    header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item ul.sub-menu-2 li {
                      -webkit-box-pack: center;
                          -ms-flex-pack: center;
                              justify-content: center;
                      min-width: unset;
                      margin: 5px 0;
                      padding: 0;
                      line-height: 16px;
                      min-height: 16px;
                      height: 100%;
                      -webkit-box-flex: 0;
                          -ms-flex: 0 1 auto;
                              flex: 0 1 auto; }
                      header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item ul.sub-menu-2 li:hover {
                        background-color: transparent; }
                      header .container .toggle__menu.active #horizontal-multilevel-menu > li ul.sub-menu li.sub-item.parent-item ul.sub-menu-2 li a {
                        line-height: 16px;
                        min-height: 16px;
                        height: 100%;
                        text-align: center;
                        padding: 5px; }
        header .container .toggle__menu.active #search_top {
          max-width: 300px;
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -webkit-box-pack: start;
              -ms-flex-pack: start;
                  justify-content: flex-start;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          margin-top: 90px;
          height: auto; }
          header .container .toggle__menu.active #search_top form {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            max-width: 100%;
            width: 100%;
            position: relative; }
            header .container .toggle__menu.active #search_top form .search-suggest {
              position: unset;
              opacity: 1;
              visibility: visible;
              -webkit-transform: none;
                  -ms-transform: none;
                      transform: none;
              z-index: 50; }
            header .container .toggle__menu.active #search_top form .search-button {
              position: absolute;
              right: 10px;
              top: 50%;
              -webkit-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                      transform: translateY(-50%);
              z-index: 100; }
  .front-page-about {
    height: unset; }
    .front-page-about .container {
      padding-top: 40px;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .front-page-about .container .front-page-about__text {
        max-width: 100%;
        width: 100%;
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto; }
        .front-page-about .container .front-page-about__text .front-page-about__text-title {
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          text-transform: uppercase;
          margin-bottom: 25px; }
        .front-page-about .container .front-page-about__text .front-page-about__text-subtitle,
        .front-page-about .container .front-page-about__text .front-page-about__text-content {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px; }
        .front-page-about .container .front-page-about__text .front-page-about__text-btn {
          margin-bottom: 40px;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px; }
      .front-page-about .container .front-page-about__pics {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
        max-width: 100%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row; }
        .front-page-about .container .front-page-about__pics .front-page-about__pics-item {
          max-width: calc((100% - 14px) / 3); }
          .front-page-about .container .front-page-about__pics .front-page-about__pics-item .front-page-about__pics-item-image {
            height: 237px;
            margin-bottom: 19px; }
          .front-page-about .container .front-page-about__pics .front-page-about__pics-item .front-page-about__pics-item-title {
            padding: 0; }
          .front-page-about .container .front-page-about__pics .front-page-about__pics-item .front-page-about__pics-item-text {
            padding: 0;
            margin-bottom: 25px; }
  .front-page-form {
    margin-bottom: 40px; }
    .front-page-form .container > div form {
      padding: 35px 15px 0; }
      .front-page-form .container > div form .form__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        margin-bottom: 13px; }
      .front-page-form .container > div form .form__wrapper .form__row {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        max-width: 100%;
        width: 100%; }
        .front-page-form .container > div form .form__wrapper .form__row .form__item {
          height: 40px;
          margin-bottom: 15px;
          max-width: unset;
          -webkit-box-flex: 0;
              -ms-flex: 0 1 calc((100% - 26px) / 2);
                  flex: 0 1 calc((100% - 26px) / 2); }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.name {
            -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                    order: 1; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.phone {
            -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                    order: 2; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.text {
            -webkit-box-flex: 0;
                -ms-flex: 0 1 100%;
                    flex: 0 1 100%;
            -webkit-box-ordinal-group: 4;
                -ms-flex-order: 3;
                    order: 3;
            margin-top: 0; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.email {
            -webkit-box-ordinal-group: 5;
                -ms-flex-order: 4;
                    order: 4; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.submit-btn {
            max-width: unset;
            -webkit-box-flex: 0;
                -ms-flex: 0 1 calc((100% - 26px) / 2);
                    flex: 0 1 calc((100% - 26px) / 2);
            -webkit-box-ordinal-group: 6;
                -ms-flex-order: 5;
                    order: 5; }
            .front-page-form .container > div form .form__wrapper .form__row .form__item.submit-btn input[type=submit] {
              display: block;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              text-align: center; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.captcha {
            -webkit-box-ordinal-group: 7;
                -ms-flex-order: 6;
                    order: 6;
            -webkit-box-flex: 0;
                -ms-flex: 0 1 100%;
                    flex: 0 1 100%;
            margin: 10px 0 0 10px;
            max-width: unset; }
            .front-page-form .container > div form .form__wrapper .form__row .form__item.captcha .inputtext {
              max-width: 100%; }
          .front-page-form .container > div form .form__wrapper .form__row .form__item.privacy {
            -webkit-box-ordinal-group: 8;
                -ms-flex-order: 7;
                    order: 7;
            -webkit-box-flex: 0;
                -ms-flex: 0 1 100%;
                    flex: 0 1 100%; }
  .front-page-gallery {
    margin-bottom: 30px; }
    .front-page-gallery .container .news-list .grid-sizer {
      max-width: 24%;
      width: 100%; }
    .front-page-gallery .container .news-list .gutter-sizer {
      width: 1%; }
    .front-page-gallery .container .news-list .news-item {
      margin-bottom: 6px;
      max-width: 24%; }
      .front-page-gallery .container .news-list .news-item:nth-of-type(3), .front-page-gallery .container .news-list .news-item:nth-of-type(6) {
        max-width: 49%; }
      .front-page-gallery .container .news-list .news-item:nth-of-type(9), .front-page-gallery .container .news-list .news-item:nth-of-type(10), .front-page-gallery .container .news-list .news-item:nth-of-type(11) {
        display: none !important; }
  .front-page-contacts {
    margin-bottom: 0; }
    .front-page-contacts .container {
      padding: 0; }
      .front-page-contacts .container .contacts__block {
        max-width: 35%;
        padding: 0 15px; }
      .front-page-contacts .container .contacts__map {
        max-width: 65%;
        padding: 0; }
  footer.footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: unset; }
    footer.footer .footer__wrapper {
      height: unset; }
      footer.footer .footer__wrapper .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: unset; }
        footer.footer .footer__wrapper .container .footer__menu {
          max-width: 100%;
          height: unset;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          padding: 25px 75px 15px; }
          footer.footer .footer__wrapper .container .footer__menu ul {
            height: unset;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center; }
            footer.footer .footer__wrapper .container .footer__menu ul li {
              max-width: unset;
              width: auto;
              margin: 5px 10px; }
        footer.footer .footer__wrapper .container .footer__contacts {
          max-width: 100%;
          height: unset;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          margin-bottom: 15px; }
          footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--adress {
            max-width: 75%;
            width: 100%;
            margin-bottom: 10px;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center; }
            footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--adress .footer__contacts-adress {
              width: auto; }
          footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--email {
            max-width: unset;
            width: auto;
            margin-bottom: 10px; }
          footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-item--phone {
            max-width: unset;
            width: auto;
            margin-bottom: 10px;
            margin-right: 10px; }
          footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-adress,
          footer.footer .footer__wrapper .container .footer__contacts .footer__contacts-text {
            width: 100%;
            text-align: center; }
    footer.footer .copyrights {
      height: unset; }
      footer.footer .copyrights .container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 15px; }
        footer.footer .copyrights .container .copyrights__year {
          max-width: 100%;
          margin-bottom: 10px;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center; }
          footer.footer .copyrights .container .copyrights__year span {
            text-align: center;
            color: #cecdca;
            font-size: 11px;
            font-weight: 400;
            line-height: 13px; }
        footer.footer .copyrights .container .copyrights__trend {
          max-width: 100%;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center; }
          footer.footer .copyrights .container .copyrights__trend span,
          footer.footer .copyrights .container .copyrights__trend span a {
            max-width: 100%;
            text-align: center;
            color: #cecdca;
            font-size: 11px;
            font-weight: 400;
            line-height: 13px; }
    footer.footer .modals__wrapper {
      overflow: scroll; }
      footer.footer .modals__wrapper .modal__request {
        margin: 20px auto; }
        footer.footer .modals__wrapper .modal__request .container > div form {
          max-width: 100%;
          padding: 25px 10px;
          border-radius: 10px; }
          footer.footer .modals__wrapper .modal__request .container > div form > p {
            max-width: 100%;
            width: 100%; }
            footer.footer .modals__wrapper .modal__request .container > div form > p .errortext {
              color: red; }
            footer.footer .modals__wrapper .modal__request .container > div form > p .notetext {
              color: #3162b9; }
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item span {
            max-width: 35%; }
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item img {
            max-width: 40%; }
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item input,
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item select,
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item textarea {
            max-width: 60%; }
          footer.footer .modals__wrapper .modal__request .container > div form .form-ask .form-ask__item input[type=submit] {
            max-width: 100%; }
      footer.footer .modals__wrapper .modal__buy {
        margin: 20px auto; }
        footer.footer .modals__wrapper .modal__buy .container > div form {
          max-width: 100%;
          padding: 25px 10px;
          border-radius: 10px; }
          footer.footer .modals__wrapper .modal__buy .container > div form > p {
            max-width: 100%;
            width: 100%; }
            footer.footer .modals__wrapper .modal__buy .container > div form > p .errortext {
              color: red; }
            footer.footer .modals__wrapper .modal__buy .container > div form > p .notetext {
              color: #3162b9; }
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item span {
            max-width: 35%; }
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item img {
            max-width: 40%; }
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item input,
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item select,
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item textarea {
            max-width: 60%; }
          footer.footer .modals__wrapper .modal__buy .container > div form .form-ask .form-ask__item input[type=submit] {
            max-width: 100%; } }

.price-page__links-title {
  display: none; }

@media (min-width: 769px) and (max-width: 1200px) {
  .price-page__wrapper .container .price-page__title strong {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px; }
  .price-page__wrapper .container .price-page__links {
    max-width: 100%;
    width: 100%; }
    .price-page__wrapper .container .price-page__links .price-page__links-row {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .price-page__wrapper .container .price-page__links .price-page__links-row .price-page__links-item {
        margin-bottom: 10px; }
  .price-page__wrapper .container .table__wrapper table.table__price thead {
    font-size: 12px;
    line-height: 14px; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:first-of-type td:nth-of-type(1) {
      width: 15%; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:first-of-type td:nth-of-type(2) {
      width: 20%; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:first-of-type td:nth-of-type(3) {
      width: 20%; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:first-of-type td:nth-of-type(4) {
      width: 45%; }
    .price-page__wrapper .container .table__wrapper table.table__price thead tr:nth-of-type(2) td {
      width: 22.5%; }
  .price-page__wrapper .container .table__wrapper table.table__price tbody tr td {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px; }
  .price-page__wrapper .container .form {
    margin-bottom: 40px; }
    .price-page__wrapper .container .form > div form {
      padding: 35px 15px 0; }
      .price-page__wrapper .container .form > div form > p {
        max-width: 100%;
        width: 100%; }
        .price-page__wrapper .container .form > div form > p .errortext {
          color: red; }
        .price-page__wrapper .container .form > div form > p .notetext {
          color: #3162b9; }
      .price-page__wrapper .container .form > div form .form__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        margin-bottom: 13px; }
      .price-page__wrapper .container .form > div form .form__wrapper .form__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        max-width: 100%;
        width: 100%; }
        .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item {
          height: unset !important;
          margin-bottom: 25px; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.name {
            -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                    order: 1; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.phone {
            -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                    order: 2; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.email {
            -webkit-box-ordinal-group: 4;
                -ms-flex-order: 3;
                    order: 3; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.captcha {
            -webkit-box-ordinal-group: 5;
                -ms-flex-order: 4;
                    order: 4;
            max-width: 100%; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.privacy {
            -webkit-box-ordinal-group: 6;
                -ms-flex-order: 5;
                    order: 5;
            max-width: 100%; }
          .price-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.submit-btn {
            max-width: 100%;
            -webkit-box-ordinal-group: 7;
                -ms-flex-order: 6;
                    order: 6; } }

@media (min-width: 769px) and (max-width: 1200px) {
  .profiles-page__wrapper .container .price-page__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-top: 40px; }
  .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list .profiles-page__list-item {
      -webkit-box-flex: 0;
          -ms-flex: 0 1 297px;
              flex: 0 1 297px;
      margin-right: 0 !important; }
      .profiles-page__wrapper .container .profiles-page__list-wrapper .profiles-page__list .profiles-page__list-item:nth-of-type(2n + 1) {
        margin-right: 33px !important; }
  .profile-item-page .profile-item-page__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    margin-top: 40px; }
  .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text img {
    max-width: 100%;
    height: auto; }
  .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .profile-item-page__detail-picture {
    max-width: 375px;
    margin: 0 0 25px 15px; }
  .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper .specs-link {
    font-size: 14px;
    padding-left: 30px; }
    .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper .specs-link:before {
      width: 20px;
      height: 20px; }
  .profile-item-page .profile-item-page__content .profile-item-page__content-text .profile-item-page__detail-text .preview-links-wrapper .price-link {
    max-width: 40%;
    width: 100%;
    text-align: center;
    margin: 0;
    float: left; }
  .profile-item-page .profile-item-page__content .profile-item-page__preview-table .profil-table {
    width: 100% !important;
    overflow-x: scroll;
    margin-bottom: 20px !important; }
    .profile-item-page .profile-item-page__content .profile-item-page__preview-table .profil-table caption {
      font-size: 14px !important; } }

.page__subtitle-button {
  display: none; }

@media (min-width: 769px) and (max-width: 1200px) {
  .hangar-page__wrapper {
    padding-top: 40px; }
    .hangar-page__wrapper .container .page__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 21px; }
    .hangar-page__wrapper .container .page__sizes {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      .hangar-page__wrapper .container .page__sizes .page__sizes-links {
        width: auto;
        margin-bottom: 10px; }
        .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row .page__sizes-links-title {
          display: none; }
        .hangar-page__wrapper .container .page__sizes .page__sizes-links .page__sizes-links-row .cancel {
          margin-right: 0; }
      .hangar-page__wrapper .container .page__sizes .page__sizes-button {
        max-width: 200px;
        margin-bottom: 10px; }
        .hangar-page__wrapper .container .page__sizes .page__sizes-button .page__sizes-button-item {
          height: 32px; }
    .hangar-page__wrapper .container .page__content .page__content-item {
      -webkit-box-flex: 0;
          -ms-flex: 0 1 calc((100% - 10px)/2);
              flex: 0 1 calc((100% - 10px)/2); }
    .hangar-page__wrapper .container .page__subtitle .page__subtitle-button {
      display: none;
      max-width: 100%;
      width: 100%; }
      .hangar-page__wrapper .container .page__subtitle .page__subtitle-button .page__subtitle-button-item {
        width: 100%;
        height: 40px;
        border-radius: 40px;
        background-color: #737373;
        color: #fff;
        font-family: "FuturaFuturisC", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        text-decoration: none;
        -webkit-transition: 0.1s ease-in-out;
        -o-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out;
        cursor: pointer; }
        .hangar-page__wrapper .container .page__subtitle .page__subtitle-button .page__subtitle-button-item:hover {
          -webkit-transform: translateY(-5px) scale(1.05);
              -ms-transform: translateY(-5px) scale(1.05);
                  transform: translateY(-5px) scale(1.05);
          background-color: #3162b9; }
    .hangar-page__wrapper .container .form {
      margin-bottom: 40px; }
      .hangar-page__wrapper .container .form > div form {
        padding: 35px 15px 0; }
        .hangar-page__wrapper .container .form > div form .form__title {
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          text-transform: uppercase;
          margin-bottom: 13px; }
        .hangar-page__wrapper .container .form > div form .form__wrapper .form__row {
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-direction: row;
                  flex-direction: row;
          -ms-flex-wrap: wrap;
              flex-wrap: wrap;
          max-width: 100%;
          width: 100%; }
          .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item {
            height: 40px;
            margin-bottom: 15px;
            max-width: calc((100% - 26px) / 2); }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.name {
              -webkit-box-ordinal-group: 2;
                  -ms-flex-order: 1;
                      order: 1; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.phone {
              -webkit-box-ordinal-group: 3;
                  -ms-flex-order: 2;
                      order: 2; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.text {
              max-width: 100%;
              -webkit-box-ordinal-group: 4;
                  -ms-flex-order: 3;
                      order: 3;
              margin-top: 0; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.email {
              -webkit-box-ordinal-group: 5;
                  -ms-flex-order: 4;
                      order: 4; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.submit-btn {
              max-width: calc((100% - 26px) / 2);
              -webkit-box-ordinal-group: 6;
                  -ms-flex-order: 5;
                      order: 5; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.captcha {
              -webkit-box-ordinal-group: 7;
                  -ms-flex-order: 6;
                      order: 6;
              max-width: 100%;
              margin: 10px 0 0 10px; }
              .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.captcha .inputtext {
                max-width: 100%; }
            .hangar-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.privacy {
              -webkit-box-ordinal-group: 8;
                  -ms-flex-order: 7;
                      order: 7;
              max-width: 100%; } }

.gallery-page__links-title {
  display: none; }

@media (min-width: 769px) and (max-width: 1200px) {
  .gallery-page__wrapper .container .gallery-page__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px; }
  .gallery-page__wrapper .container .gallery-page__links {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .gallery-page__wrapper .container .gallery-page__links .gallery-page__links-item {
      margin-bottom: 10px; }
  .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item {
    max-width: unset;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 33%;
            flex: 0 1 33%; }
    .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-link {
      margin-bottom: 5px; }
    .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-title {
      height: unset;
      margin-bottom: 5px; }
    .gallery-page__wrapper .container .gallery-page__list .gallery-page__list-item .gallery-page__list-item-text {
      margin-bottom: 5px; }
  .gallery-page__wrapper .container .form {
    margin-bottom: 40px; }
    .gallery-page__wrapper .container .form > div form {
      padding: 35px 15px 0; }
      .gallery-page__wrapper .container .form > div form > p {
        max-width: 100%;
        width: 100%; }
        .gallery-page__wrapper .container .form > div form > p .errortext {
          color: red; }
        .gallery-page__wrapper .container .form > div form > p .notetext {
          color: #3162b9; }
      .gallery-page__wrapper .container .form > div form .form__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        margin-bottom: 13px; }
      .gallery-page__wrapper .container .form > div form .form__wrapper .form__row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        max-width: 100%;
        width: 100%; }
        .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item {
          height: unset !important;
          margin-bottom: 25px; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.name {
            -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                    order: 1; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.phone {
            -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                    order: 2; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.email {
            -webkit-box-ordinal-group: 4;
                -ms-flex-order: 3;
                    order: 3; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.captcha {
            -webkit-box-ordinal-group: 5;
                -ms-flex-order: 4;
                    order: 4;
            max-width: 100%; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.privacy {
            -webkit-box-ordinal-group: 6;
                -ms-flex-order: 5;
                    order: 5;
            max-width: 100%; }
          .gallery-page__wrapper .container .form > div form .form__wrapper .form__row .form__item.submit-btn {
            max-width: 100%;
            -webkit-box-ordinal-group: 7;
                -ms-flex-order: 6;
                    order: 6; }
  .gallery-page-item__wrapper .container {
    padding-top: 40px; }
    .gallery-page-item__wrapper .container .news-detail h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 21px; }
    .gallery-page-item__wrapper .container .news-detail .news-detail-wrapper {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      .gallery-page-item__wrapper .container .news-detail .news-detail-wrapper .news-detail__item {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 49%;
                flex: 0 1 49%;
        max-width: unset;
        margin: 0 0 5px 0 !important;
        height: 250px; } }

@media (min-width: 769px) and (max-width: 1200px) {
  .contacts-page__wrapper .container {
    margin-top: 40px; }
    .contacts-page__wrapper .container .contacts__content {
      max-width: unset;
      -webkit-box-flex: 0;
          -ms-flex: 0 1 49%;
              flex: 0 1 49%; }
      .contacts-page__wrapper .container .contacts__content .contacts__content-title {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px; }
    .contacts-page__wrapper .container .contacts__form {
      max-width: unset;
      -webkit-box-flex: 0;
          -ms-flex: 0 1 49%;
              flex: 0 1 49%;
      margin-top: 0; }
      .contacts-page__wrapper .container .contacts__form > div form > p {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
        width: 100%; }
        .contacts-page__wrapper .container .contacts__form > div form > p .errortext {
          color: red; }
        .contacts-page__wrapper .container .contacts__form > div form > p .notetext {
          color: #3162b9; }
      .contacts-page__wrapper .container .contacts__form > div form .form-ask .form-ask__item {
        max-width: 100%;
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto; }
        .contacts-page__wrapper .container .contacts__form > div form .form-ask .form-ask__item.button input[type=submit] {
          width: 100%;
          -webkit-box-flex: 0;
              -ms-flex: 0 1 auto;
                  flex: 0 1 auto; } }
