.page__subtitle-button {
  display: none;
}

@media (min-width: 426px) and (max-width: 768px) {
  .hangar-page__wrapper {
    padding-top: 40px;

    .container {
      .page__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
      }

      .page__sizes {
        flex-wrap: wrap;

        .page__sizes-links {
          width: auto;
          flex: 0 1 auto;
          margin-bottom: 10px;

          .page__sizes-links-row {
            .page__sizes-links-title {
              display: none;
            }

            .cancel {
              margin-right: 0;
            }
          }
        }

        .page__sizes-button {
          flex: 0 1 auto;
          max-width: 200px;
          margin-bottom: 10px;

          .page__sizes-button-item {
            height: 32px;
          }
        }
      }

      .page__subtitle {
        .page__subtitle-button {
          display: none;
          max-width: 100%;
          width: 100%;

          .page__subtitle-button-item {
            width: 100%;
            height: 40px;
            border-radius: 40px;
            background-color: #737373;
            color: #fff;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            transition: $transition-01;
            cursor: pointer;

            &:hover {
              transform: translateY(-5px) scale(1.05);
              background-color: #3162b9;
            }
          }
        }
      }

      .page__content {
        .page__content-item {
          max-width: 100%;

          .page__content-item-wrapper {
            .page__content-item-image {
              .active {
                transform: scale(1.5);
              }
            }
          }
        }
      }

      .form {
        margin-bottom: 40px;

        & > div {
          form {
            padding: 35px 15px 0;

            & > p {
              max-width: 100%;
              width: 100%;

              .errortext {
                color: red;
              }

              .notetext {
                color: #3162b9;
              }
            }

            .form__title {
              font-size: 18px;
              font-weight: 700;
              line-height: 21px;
              text-transform: uppercase;
              margin-bottom: 13px;
            }

            .form__wrapper {
              .form__row {
                flex-direction: column;
                max-width: 100%;
                width: 100%;

                .form__item {
                  height: unset !important;
                  margin-bottom: 25px;

                  &.name {
                    order: 1;
                  }

                  &.phone {
                    order: 2;
                  }

                  &.email {
                    order: 3;
                  }

                  &.captcha {
                    order: 4;
                    max-width: 100%;
                  }

                  &.privacy {
                    order: 5;
                    max-width: 100%;
                  }

                  &.submit-btn {
                    max-width: 100%;
                    order: 6;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}