.hangar-page__wrapper {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .page__title {
      max-width: 100%;
      width: 100%;
      text-align: left;
      color: #000;
      font-family: $main-font;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      text-transform: uppercase;
      margin-bottom: 25px;
    }

    .page__sizes {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;

      .page__sizes-button {
        max-width: 240px;
        width: 100%;

        .page__sizes-button-item {
          width: 100%;
          height: 40px;
          border-radius: 40px;
          background-color: #737373;
          color: #fff;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          transition: $transition-01;
          cursor: pointer;

          &:hover {
            transform: translateY(-5px) scale(1.05);
            background-color: #3162b9;
          }
        }
      }

      .page__sizes-links {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .page__sizes-links-row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .page__sizes-links-title {
            color: #000;
            font-family: $main-font;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            margin-right: 20px;
          }

          .cancel {
            color: #000;
            font-family: $main-font;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            margin-right: 20px;
            text-decoration: underline;
            cursor: pointer;
            display: none;

            &.active {
              display: flex;
            }
          }

          .page__sizes-links-item {
            width: 66px;
            height: 32px;
            background-color: #e5e5e5;
            color: #000;
            font-family: $main-font;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            border-radius: 50px;
            transition: $transition-01;

            &.active {
              background-color: #3162b9;
              color: #fff;
            }

            &:hover {
              background-color: #3162b9;
              color: #fff;
            }
          }
        }
      }
    }

    .page__subtitle {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 35px;

      .page__subtitle-item {
        max-width: 100%;
        width: 100%;
        color: #000;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 15px;
      }
    }

    .form {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;

      & > div {
        max-width: 100%;
        width: 100%;

        form {
          max-width: 100%;
          width: 100%;
          border: 3px solid #3162b9;
          min-height: 220px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 15px 28px;

          & > p {
            max-width: 100%;
            width: 100%;

            .errortext {
              color: red;
              font-family: $main-font;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
            }

            .notetext {
              font-family: $main-font;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              color: #3162b9;
            }
          }

          .form__title {
            color: #000;
            font-family: $main-font;
            font-size: 20px;
            font-weight: 700;
            line-height: 23px;
            margin-bottom: 10px;
            max-width: 100%;
            width: 100%;
            text-align: left;
            text-transform: uppercase;
          }

          .form__subtitle {
            color: #000;
            font-family: $main-font;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            margin-bottom: 20px;
            max-width: 100%;
            width: 100%;
            text-align: left;
          }

          .form__wrapper {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            max-width: 100%;
            width: 100%;

            .form__row {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              max-width: 100%;
              width: 100%;
              flex-wrap: wrap;

              .form__item {
                max-width: 270px;
                width: 100%;
                position: relative;
                height: 25px;

                &-label {
                  color: #000;
                  font-family: $main-font;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19px;
                  position: absolute;
                  transition: 0.2s;
                  top: 50%;
                  left: 0;
                  z-index: 5;
                  transform: translateY(-50%);

                  .form-required {
                    color: #3162b9;
                  }

                  &.active {
                    font-size: 10px;
                    top: 0;
                    line-height: 1;
                    transform: translateY(-100%);
                  }
                }

                .inputtext {
                  max-width: 100%;
                  width: 100%;
                  border: none;
                  border-bottom: 2px solid #3162b9;
                  height: 100%;
                  outline: none;
                  font-family: $main-font;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19px;
                  position: relative;
                  z-index: 5;
                  background-color: transparent;
                }

                &.submit-btn {
                  max-width: 200px;
                  width: 100%;
                  height: 40px;

                  input[type="submit"] {
                    max-width: 100%;
                    width: 100%;
                    height: 40px;
                    box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                    border-radius: 50px;
                    background-color: #3162b9;
                    color: #fff;
                    font-family: $main-font;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    text-decoration: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    cursor: pointer;
                    transition: 0.2s ease-in-out;
                    outline: none;

                    &:hover {
                      transform: translateY(-5px) scale(1.05);
                    }
                  }
                }

                &.captcha {
                  max-width: 50%;
                  width: 100%;
                  height: 40px;
                  display: none;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: flex-end;
                  margin-top: 10px;

                  &.active {
                    display: flex;
                  }

                  .inputtext {
                    max-width: 270px;
                    margin-left: 10px;
                  }
                }

                &.privacy {
                  max-width: 100%;
                  width: 100%;
                  color: #737373;
                  font-family: $main-font;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 14px;
                  padding-top: 20px;

                  a {
                    color: #000000;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                  }
                }

                &.text {
                  max-width: 100%;
                  width: 100%;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  margin-top: 10px;

                  textarea {
                    max-width: 100%;
                    width: 100%;
                    border: none;
                    border-bottom: 2px solid #3162b9;
                    height: 100%;
                    outline: none;
                    font-family: $main-font;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                    position: relative;
                    z-index: 5;
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }

    .page__content {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 40px;

      &-item {
        max-width: calc((100% - 10px) / 2);
        width: 100%;
        border: 1px solid #e5e5e5;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;
        transition: $transition-01;

        &.hidden {
          display: none;
        }

        &.active {
          display: flex;
        }

        &:hover {
          box-shadow: $box-shadow;
        }

        .page__content-item-title {
          max-width: 100%;
          width: 100%;
          color: #000;
          font-family: $main-font;
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          margin-bottom: 15px;
          text-transform: uppercase;
          margin-top: 23px;
          padding: 0 18px;
        }

        .page__content-item-wrapper {
          max-width: 100%;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 0 18px;
          margin-bottom: 25px;

          .page__content-item-descr {
            max-width: 45%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            p {
              color: #000;
              font-family: $main-font;
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
            }

            ul {
              margin-top: 35px;
              font-family: $main-font;
              font-size: 14px;
              font-weight: 700;
              line-height: 19px;

              li {
                font-weight: 400;
              }
            }
          }

          .page__content-item-image {
            max-width: 55%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;

            img {
              max-width: 100%;
              width: 100%;
              transition: $transition-01;
              cursor: pointer;

              &.active {
                transform: scale(2);
                box-shadow: $box-shadow;
                border-radius: 5px;
                overflow: hidden;
              }
            }
          }
        }

        .page__content-item-footer {
          max-width: 100%;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 77px;
          border-top: 1px solid #e5e5e5;
          background-color: #f5f5f5;
          padding: 0 18px;

          .page__content-item-footer-price {
            max-width: 355px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            .price {
              strong {
                color: #000;
                font-family: $main-font;
                font-size: 16px;
                font-weight: 700;
                line-height: 22px;
                margin-right: 20px;
              }

              .price-count {
                color: #3162b9;
                font-family: $main-font;
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;
              }
            }

            .small-text {
              color: #737373;
              font-family: $main-font;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
            }
          }

          .page__content-item-footer-link {
            .button {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              width: 177px;
              height: 40px;
              box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
              border-radius: 50px;
              background-color: #3162b9;
              color: #fff;
              font-family: $main-font;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              text-decoration: none;
              cursor: pointer;
              transition: $transition-01;

              &:hover {
                transform: translateY(-5px) scale(1.05);
              }
            }
          }
        }
      }
    }
  }
}