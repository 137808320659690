.about-page__wrapper {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 252px);

  .container {
    padding: 50px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .about-page__title {
      color: #000;
      font-family: $main-font;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      max-width: 100%;
      width: 100%;
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 25px;
    }

    .about-page__content {
      font-family: $main-font;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;

      p {
        margin-bottom: 15px;
      }
    }
  }
}