.privacy-page__content {
  padding: 40px 0;
  font-family: $main-font;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;

  h1 {
    color: #000;
    font-family: $main-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    max-width: 100%;
    width: 100%;
    text-align: left;
    text-decoration: none;
  }
}