.gallery-page__links-title {
  display: none;
}

@media (min-width: 769px) and (max-width: 1200px) {
  .gallery-page__wrapper {
    .container {
      .gallery-page__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
      }

      .gallery-page__links {
        flex-wrap: wrap;

        .gallery-page__links-item {
          margin-bottom: 10px;
        }
      }

      .gallery-page__list {
        .gallery-page__list-item {
          max-width: unset;
          flex: 0 1 33%;

          .gallery-page__list-item-link {
            margin-bottom: 5px;
          }

          .gallery-page__list-item-title {
            height: unset;
            margin-bottom: 5px;
          }

          .gallery-page__list-item-text {
            margin-bottom: 5px;
          }
        }
      }

      .form {
        margin-bottom: 40px;

        & > div {
          form {
            padding: 35px 15px 0;

            & > p {
              max-width: 100%;
              width: 100%;

              .errortext {
                color: red;
              }

              .notetext {
                color: #3162b9;
              }
            }

            .form__title {
              font-size: 18px;
              font-weight: 700;
              line-height: 21px;
              text-transform: uppercase;
              margin-bottom: 13px;
            }

            .form__wrapper {
              .form__row {
                flex-direction: column;
                max-width: 100%;
                width: 100%;

                .form__item {
                  height: unset !important;
                  margin-bottom: 25px;

                  &.name {
                    order: 1;
                  }

                  &.phone {
                    order: 2;
                  }

                  &.email {
                    order: 3;
                  }

                  &.captcha {
                    order: 4;
                    max-width: 100%;
                  }

                  &.privacy {
                    order: 5;
                    max-width: 100%;
                  }

                  &.submit-btn {
                    max-width: 100%;
                    order: 6;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .gallery-page-item__wrapper {
    .container {
      padding-top: 40px;

      .news-detail {
        h3 {
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
        }

        .news-detail-wrapper {
          justify-content: space-between;

          .news-detail__item {
            flex: 0 1 49%;
            max-width: unset;
            margin: 0 0 5px 0 !important;
            height: 250px;
          }
        }
      }
    }
  }
}