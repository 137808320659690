@media (min-width: 769px) and (max-width: 1200px) {
  .profiles-page__wrapper {
    .container {
      .price-page__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        margin-top: 40px;
      }

      .profiles-page__list-wrapper {
        .profiles-page__list {
          flex-direction: row;

          .profiles-page__list-item {
            flex: 0 1 297px;
            margin-right: 0 !important;

            &:nth-of-type(2n + 1) {
              margin-right: 33px !important;
            }
          }
        }
      }
    }
  }

  .profile-item-page {
    .profile-item-page__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 21px;
      margin-top: 40px;
    }

    .profile-item-page__content {
      .profile-item-page__content-text {
        .profile-item-page__detail-text {
          img {
            max-width: 100%;
            height: auto;
          }

          .profile-item-page__detail-picture {
            max-width: 375px;
            margin: 0 0 25px 15px;
          }

          .preview-links-wrapper {
            .specs-link {
              font-size: 14px;
              padding-left: 30px;

              &:before {
                width: 20px;
                height: 20px;
              }
            }

            .price-link {
              max-width: 40%;
              width: 100%;
              text-align: center;
              margin: 0;
              float: left;
            }
          }
        }
      }

      .profile-item-page__preview-table {
        .profil-table {
          width: 100% !important;
          overflow-x: scroll;
          margin-bottom: 20px !important;

          caption {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}