.front-page-about {
  max-width: 100%;
  width: 100%;
  height: 530px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .container {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .front-page-about__text {
      height: 100%;
      max-width: 475px;
      width: 100%;
      display: flex;
      flex-direction: column !important;
      justify-content: center !important;
      align-items: flex-start;

      &-title {
        color: #000;
        font-family: $main-font;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        margin-bottom: 30px;
        text-transform: uppercase;
      }

      &-subtitle {
        color: #000;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 20px;
      }

      &-content {
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: $main;
        margin-bottom: 40px;
      }

      &-btn {
        width: 194px;
        height: 40px;
        box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
        border-radius: 50px;
        background-color: #3162b9;
        color: #fff;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
          transform: translateY(-5px) scale(1.05);
        }
      }
    }

    .front-page-about__pics {
      max-width: 665px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .front-page-about__pics-item {
        max-width: 32%;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        transition: $transition;
        border-radius: 2px;
        overflow: hidden;

        &:hover {
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }

        &-image {
          max-width: 100%;
          width: 100%;
          height: 394px;
          -webkit-background-size: cover;
          background-size: cover;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          margin-bottom: 25px;

          &.manufacturing {
            background-image: url('/bitrix/templates/trendspb_enerpro/images/2.jpg');
          }

          &.projecting {
            background-image: url('/bitrix/templates/trendspb_enerpro/images/3.jpg');
          }

          &.mounting {
            background-image: url('/bitrix/templates/trendspb_enerpro/images/4.jpg');
          }
        }

        &-title {
          max-width: 100%;
          width: 100%;
          color: #000;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          text-align: left;
          padding-left: 10px;
          margin-bottom: 10px;
          text-decoration: none;
        }

        &-text {
          max-width: 100%;
          width: 100%;
          color: $main;
          font-family: $main-font;
          font-size: 15px;
          font-weight: 400;
          line-height: 19px;
          text-align: left;
          padding-left: 10px;
          margin-bottom: 15px;
        }
      }
    }
  }
}