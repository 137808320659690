.breadcrumbs__wrapper {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 51px;
  background-color: #e5e5e5;

  .container {
    .bx-breadcrumb {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      .bx-breadcrumb-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: #737373;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        position: relative;

        a {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-right: 15px;
          position: relative;
          color: #737373;
          text-decoration: none;

          &::after {
            content: '/';
            position: absolute;
            right: -12px;
            top: 0;
            font-size: 16px;
            color: #737373;
            font-family: $main-font;
          }
        }

        span.last {
          text-decoration: underline !important;
        }
      }
    }
  }
}