.header__toggle,
.close-button,
.for-mobile {
  display: none;
}

@media (min-width: 769px) and (max-width: 1200px) {
  header {
    height: 64px;

    .container {
      .header__logo {
        margin: 0;
        max-width: 45%;
        width: 100%;

        &-link {
          &-image {
            max-width: 127px;
            width: 100%;
          }
        }
      }

      .header__contacts {
        max-width: 40%;
        width: 100%;

        .header__contacts-phone a {
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
        }

        .header__contacts-email a {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
        }
      }

      .header__toggle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 19px;
        height: 19px;
        background-image: url(/bitrix/templates/trendspb_enerpro/images/svg/toggle.svg);
        background-position: 50% 50%;
        -webkit-background-size: contain;
        background-size: contain;
        background-repeat: no-repeat;
      }

      #horizontal-multilevel-menu {
        display: none;
      }

      #search_top {
        display: none;
      }

      .toggle__menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        min-height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
        background-color: #fff;
        z-index: -10000;
        opacity: 0;
        visibility: hidden;
        transition: $transition-01;

        &.active {
          z-index: 10000;
          opacity: 1;
          visibility: visible;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          .close-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 40px;
            left: 40px;
            background-image: url('../../images/svg/cancel.svg');
            background-position: 50% 50%;
            -webkit-background-size: cover;
            background-size: cover;
            background-repeat: no-repeat;
          }

          #horizontal-multilevel-menu {
            position: absolute;
            max-width: 100%;
            flex: 0 1 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            top: 165px;
            padding-bottom: 100px;

            & > li {
              min-height: 36px;
              height: auto;
              margin: 0;
              flex: 0 1 auto;

              &.for-mobile {
                display: list-item;
              }

              & > a {
                color: #000;
                font-size: 22px;
                font-weight: 400;
                line-height: 36px;
                min-height: 36px;
                height: auto;

                &.root-item-selected {
                  color: #3162b9;
                }
              }

              &::after {
                bottom: unset;
                left: unset;
                transform: translateY(-50%) rotate(-90deg);
                right: -30px;
                top: 18px;
              }

              &:hover {
                &::after {
                  transform: translateY(-50%);
                  right: 0;
                }
              }

              &.parent-item {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                & > a {
                  pointer-events: none !important;
                  height: auto;
                }

                .parent::after {
                  display: none;
                }
              }

              ul.sub-menu {
                position: relative;
                box-shadow: none;
                margin: 10px 0;
                background-color: transparent;
                align-items: center;
                flex: 0 1 auto;

                li {
                  justify-content: center;
                  min-width: unset;
                  margin: 5px 0;
                  padding: 0;
                  line-height: 16px;
                  min-height: 16px;
                  flex: 0 1 auto;
                  height: auto;

                  &:hover {
                    background-color: transparent;
                  }

                  a {
                    line-height: 16px;
                    min-height: 16px;
                    height: 100%;
                    text-align: center;
                    padding: 5px;
                  }

                  &.sub-item.parent-item {
                    display: block;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    line-height: 16px;
                    min-height: 16px;
                    height: auto;

                    a {
                      line-height: 16px;
                      min-height: 16px;
                      height: 100%;
                      text-align: center;

                      &::after {
                        bottom: unset;
                        left: unset;
                        transform: translateY(-50%);
                        right: -30px;
                        top: 8px;
                      }

                      &:hover {
                        &::after {
                          right: 0;
                        }
                      }
                    }

                    ul.sub-menu-2 {
                      right: 0;
                      display: block;
                      position: unset;
                      box-shadow: none;
                      margin: 5px 0;
                      background-color: transparent;
                      align-items: center;
                      flex: 0 1 auto;
                      height: auto;

                      li {
                        justify-content: center;
                        min-width: unset;
                        margin: 5px 0;
                        padding: 0;
                        line-height: 16px;
                        min-height: 16px;
                        height: 100%;
                        flex: 0 1 auto;

                        &:hover {
                          background-color: transparent;
                        }

                        a {
                          line-height: 16px;
                          min-height: 16px;
                          height: 100%;
                          text-align: center;
                          padding: 5px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          #search_top {
            max-width: 300px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 90px;
            height: auto;

            form {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              max-width: 100%;
              width: 100%;
              position: relative;

              .search-suggest {
                position: unset;
                opacity: 1;
                visibility: visible;
                transform: none;
                z-index: 50;
              }

              .search-button {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 100;
              }
            }
          }
        }
      }
    }
  }

  .front-page-about {
    height: unset;

    .container {
      padding-top: 40px;
      flex-wrap: wrap;

      .front-page-about__text {
        max-width: 100%;
        width: 100%;
        flex: 0 1 auto;

        .front-page-about__text-title {
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          text-transform: uppercase;
          margin-bottom: 25px;
        }

        .front-page-about__text-subtitle,
        .front-page-about__text-content {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
        }

        .front-page-about__text-btn {
          margin-bottom: 40px;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
        }
      }

      .front-page-about__pics {
        flex: 0 1 auto;
        max-width: 100%;
        flex-direction: row;

        .front-page-about__pics-item {
          max-width: calc((100% - 14px) / 3);

          .front-page-about__pics-item-image {
            height: 237px;
            margin-bottom: 19px;
          }

          .front-page-about__pics-item-title {
            padding: 0;
          }

          .front-page-about__pics-item-text {
            padding: 0;
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  .front-page-form {
    margin-bottom: 40px;

    .container {
      & > div {
        form {
          padding: 35px 15px 0;

          .form__title {
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;
            text-transform: uppercase;
            margin-bottom: 13px;
          }

          .form__wrapper {
            .form__row {
              flex-direction: row;
              flex-wrap: wrap;
              max-width: 100%;
              width: 100%;

              .form__item {
                height: 40px;
                margin-bottom: 15px;
                max-width: unset;
                flex: 0 1 calc((100% - 26px) / 2);

                &.name {
                  order: 1;
                }

                &.phone {
                  order: 2;
                }

                &.text {
                  flex: 0 1 100%;
                  order: 3;
                  margin-top: 0;
                }

                &.email {
                  order: 4;
                }

                &.submit-btn {
                  max-width: unset;
                  flex: 0 1 calc((100% - 26px) / 2);
                  order: 5;

                  input[type=submit] {
                    display: block;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    text-align: center;
                  }
                }

                &.captcha {
                  order: 6;
                  flex: 0 1 100%;
                  margin: 10px 0 0 10px;
                  max-width: unset;

                  .inputtext {
                    max-width: 100%;
                  }
                }

                &.privacy {
                  order: 7;
                  flex: 0 1 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  .front-page-gallery {
    margin-bottom: 30px;

    .container {
      .news-list {

        .grid-sizer {
          max-width: 24%;
          width: 100%;
        }

        .gutter-sizer {
          width: 1%;
        }

        .news-item {
          margin-bottom: 6px;
          max-width: 24%;

          &:nth-of-type(3),
          &:nth-of-type(6) {
            max-width: 49%;
          }

          &:nth-of-type(9),
          &:nth-of-type(10),
          &:nth-of-type(11) {
            display: none !important;
          }
        }
      }
    }
  }

  .front-page-contacts {
    margin-bottom: 0;

    .container {
      padding: 0;

      .contacts__block {
        max-width: 35%;
        padding: 0 15px;
      }

      .contacts__map {
        max-width: 65%;
        padding: 0;
      }
    }
  }

  footer.footer {
    flex-direction: column;
    height: unset;

    .footer__wrapper {
      height: unset;

      .container {
        flex-direction: column;
        height: unset;

        .footer__menu {
          max-width: 100%;
          height: unset;
          flex-direction: column;
          padding: 25px 75px 15px;

          ul {
            height: unset;
            flex-direction: row;
            justify-content: center;

            li {
              max-width: unset;
              width: auto;
              margin: 5px 10px;
            }
          }
        }

        .footer__contacts {
          max-width: 100%;
          height: unset;
          justify-content: center;
          margin-bottom: 15px;

          .footer__contacts-item--adress {
            max-width: 75%;
            width: 100%;
            margin-bottom: 10px;
            justify-content: center;

            .footer__contacts-adress {
              width: auto;
            }
          }

          .footer__contacts-item--email {
            max-width: unset;
            width: auto;
            margin-bottom: 10px;
          }

          .footer__contacts-item--phone {
            max-width: unset;
            width: auto;
            margin-bottom: 10px;
            margin-right: 10px;
          }

          .footer__contacts-adress,
          .footer__contacts-text {
            width: 100%;
            text-align: center;
          }
        }
      }
    }

    .copyrights {
      height: unset;

      .container {
        flex-direction: column;
        padding: 15px;

        .copyrights__year {
          max-width: 100%;
          margin-bottom: 10px;
          justify-content: center;

          span {
            text-align: center;
            color: #cecdca;
            font-size: 11px;
            font-weight: 400;
            line-height: 13px;
          }
        }

        .copyrights__trend {
          max-width: 100%;
          justify-content: center;

          span,
          span a {
            max-width: 100%;
            text-align: center;
            color: #cecdca;
            font-size: 11px;
            font-weight: 400;
            line-height: 13px;
          }
        }
      }
    }

    .modals__wrapper {
      overflow: scroll;

      .modal__request {
        margin: 20px auto;

        .container {
          & > div {
            form {
              max-width: 100%;
              padding: 25px 10px;
              border-radius: 10px;

              & > p {
                max-width: 100%;
                width: 100%;

                .errortext {
                  color: red;
                }

                .notetext {
                  color: #3162b9;
                }
              }

              .form-ask {
                .form-ask__item {
                  span {
                    max-width: 35%;
                  }

                  img {
                    max-width: 40%;
                  }

                  input,
                  select,
                  textarea {
                    max-width: 60%;
                  }

                  input[type=submit] {
                    max-width: 100%;
                  }
                }
              }
            }
          }
        }
      }

      .modal__buy {
        margin: 20px auto;

        .container {
          & > div {
            form {
              max-width: 100%;
              padding: 25px 10px;
              border-radius: 10px;

              & > p {
                max-width: 100%;
                width: 100%;

                .errortext {
                  color: red;
                }

                .notetext {
                  color: #3162b9;
                }
              }

              .form-ask {
                .form-ask__item {
                  span {
                    max-width: 35%;
                  }

                  img {
                    max-width: 40%;
                  }

                  input,
                  select,
                  textarea {
                    max-width: 60%;
                  }

                  input[type=submit] {
                    max-width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}