.gallery-page__links-title {
  display: none;
}

@media screen and (max-width: 425px) {
  .gallery-page__wrapper {
    .container {
      .gallery-page__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
      }

      .gallery-page__links {
        max-width: 100%;
        width: 100%;
        display: block;
        flex-direction: column;
        align-items: flex-start;
        height: 44px;
        border-radius: 30px;
        border: 1px solid #cecdca;
        overflow: hidden;
        position: relative;
        transition: $transition-01;

        &::after {
          position: absolute;
          right: 22px;
          top: 22px;
          transform: translateY(-50%);
          content: '';
          background-image: url(../../images/svg/caret-litle.svg);
          background-position: 50% 50%;
          -webkit-background-size: contain;
          background-size: contain;
          background-repeat: no-repeat;
          width: 9px;
          height: 6px;
          transition: $transition-01;
        }

        &.active {
          height: auto;

          &::after {
            transform: translateY(-50%) rotate(180deg);
          }
        }

        .gallery-page__links-title {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          max-width: 100%;
          width: 100%;
          height: 44px;
          border-radius: 0;
          color: #000;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          padding-left: 20px;
        }

        .gallery-page__links-item {
          max-width: 100%;
          width: 100%;
          height: 44px;
          border-radius: 0;
        }
      }

      .gallery-page__list {
        .gallery-page__list-item {
          max-width: 100%;

          .gallery-page__list-item-link {
            margin-bottom: 5px;
          }

          .gallery-page__list-item-title {
            height: unset;
            margin-bottom: 5px;
          }

          .gallery-page__list-item-text {
            margin-bottom: 5px;
          }
        }
      }

      .form {
        margin-bottom: 40px;

        & > div {
          form {
            padding: 35px 15px 0;

            & > p {
              max-width: 100%;
              width: 100%;

              .errortext {
                color: red;
              }

              .notetext {
                color: #3162b9;
              }
            }

            .form__title {
              font-size: 18px;
              font-weight: 700;
              line-height: 21px;
              text-transform: uppercase;
              margin-bottom: 13px;
            }

            .form__wrapper {
              .form__row {
                flex-direction: column;
                max-width: 100%;
                width: 100%;

                .form__item {
                  height: unset !important;
                  margin-bottom: 25px;

                  &.name {
                    order: 1;
                  }

                  &.phone {
                    order: 2;
                  }

                  &.email {
                    order: 3;
                  }

                  &.captcha {
                    order: 4;
                    max-width: 100%;
                  }

                  &.privacy {
                    order: 5;
                    max-width: 100%;
                  }

                  &.submit-btn {
                    max-width: 100%;
                    order: 6;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .gallery-page-item__wrapper {
    .container {
      padding-top: 40px;

      .news-detail {
        h3 {
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
        }

        .news-detail-wrapper {
          .news-detail__item {
            max-width: 100%;
            margin: 0 0 7px 0 !important;
          }
        }
      }
    }
  }
}