@media screen and (max-width: 425px) {
  .search-page__wrapper {
    .container {
      padding: 40px 15px;

      .search-page__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
      }

      .search-page {
        .search-page__form {
          padding: 0;
          flex-wrap: wrap;

          input[name=q] {
            max-width: 100%;
            width: 100%;
            margin-bottom: 10px;
          }

          select[name=where] {
            max-width: 49%;
            width: 100%;
          }

          input[type=submit] {
            max-width: 49%;
            width: 100%;
          }
        }
      }
    }
  }
}