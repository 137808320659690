@media (min-width: 426px) and (max-width: 768px) {
  .news-list-page {
    .container {
      padding: 40px 15px;
    }
  }

  .news-item-page {
    .container {
      padding: 40px 15px;

      .news-item-page__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
      }
    }
  }

  .more-news {
    .container {
      padding: 0 15px;
    }
  }
}