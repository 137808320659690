.gallery-page__wrapper {
  max-width: 100%;
  width: 100%;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .gallery-page__title {
      max-width: 100%;
      width: 100%;
      text-align: left;
      color: #000;
      font-family: $main-font;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      margin: 35px 0;
      text-transform: uppercase;
    }

    .gallery-page__links {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 35px;

      .gallery-page__links-item {
        height: 32px;
        background-color: #e5e5e5;
        color: #000;
        font-family: $main-font;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-decoration: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        border-radius: 50px;
        transition: $transition-01;
        padding: 0 15px;

        &.active {
          background-color: #3162b9;
          color: #fff;
        }

        &:hover {
          background-color: #3162b9;
          color: #fff;
        }
      }
    }

    .gallery-page__list {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      .gallery-page__list-item {
        max-width: calc((100% - 21px) / 4);
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 40px 0;
        transition: $transition-01;

        &.hidden {
          display: none;
        }

        &.active {
          display: flex !important;
        }

        .gallery-page__list-item-link {
          max-width: 100%;
          width: 100%;
          height: 191px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-bottom: 30px;

          .gallery-page__list-item-image {
            max-width: 100%;
            width: 100%;
            height: 191px;
            -webkit-background-size: cover;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 50%;
          }
        }

        .gallery-page__list-item-title {
          max-width: 100%;
          width: 100%;
          text-align: left;
          color: #000;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 700;
          line-height: 18px;
          text-decoration: none;
          height: 65px;
          padding: 0 10px;
        }

        .gallery-page__list-item-text {
          max-width: 100%;
          width: 100%;
          color: #737373;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          text-align: left;
          padding: 0 10px;
          margin-bottom: 10px;
        }

        .gallery-page__list-item-type {
          max-width: 100%;
          width: 100%;
          text-align: left;
          color: #3162b9;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          padding: 0 10px;
          text-decoration: underline;
        }
      }
    }
  }
}

