.profile-item-page {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 589px);

  .profile-item-page__title {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    font-family: $main-font;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-transform: uppercase;
    margin-top: 50px;
  }

  .profile-item-page__content {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .profile-item-page__content-text {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 25px;

      .profile-item-page__detail-text {
        max-width: 100%;
        width: 100%;
        display: block;
        color: #000;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 25px;

        h3 {
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: 600;
        }

        b {
          font-weight: 600;
        }

        p {
          margin-bottom: 15px;
        }
        
        ul {
          list-style: disc !important;;
          padding-left: 30px !important;;
        }
      
        ol {
          list-style: decimal !important;;
          padding-left: 30px !important;
        }

        .preview-links-wrapper {
          max-width: 100%;
          width: 100%;
          display: block;
          margin-top: 25px;

          .specs-link {
            color: #000;
            width: auto;
            font-family: $main-font;
            font-size: 16px;
            font-weight: 400;
            line-height: 40px;
            text-decoration: underline;
            padding-left: 45px;
            position: relative;
            height: 40px;
            display: none;

            &::before {
              position: absolute;
              content: '';
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 31px;
              height: 31px;
              background-image: url(/bitrix/templates/trendspb_enerpro/images/pdf.png);
              background-position: 50% 50%;
              background-repeat: no-repeat;
              -webkit-background-size: contain;
              background-size: contain;
            }
          }

          .price-link {
            width: auto;
            height: 40px;
            border-radius: 40px;
            background-color: #3162b9;
            color: #fff;
            font-family: "FuturaFuturisC", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            display: block;
            padding: 10px 15px;
            text-decoration: none;
            transition: $transition-01;
            cursor: pointer;
            float: left;

            &:hover {
              transform: translateY(-5px) scale(1.05);
            }
          }
        }

        .profile-item-page__detail-picture {
          max-width: 471px;
          width: 100%;
          margin: 0 0 25px 25px;
          float: right;
          height: 390px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-position: 50% 50%;
          -webkit-background-size: contain;
          background-size: contain;
          background-repeat: no-repeat;
          transition: $transition-01;

          &:hover {
            box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }

    .profile-item-page__preview-table {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      table.profil-table {
        margin-bottom: 50px;

        caption {
          text-align: left;
          color: #000;
          font-family: $main-font;
          font-size: 18px;
          font-weight: 700;
          line-height: 30px;
        }

        tr {
          td {
            border: 1px solid #cecdca;
            text-align: center;
            vertical-align: middle;
            height: 31px;
          }
        }
        thead {
          tr {
            td {
              color: #000;
              font-family: $main-font;
              font-size: 11px;
              font-weight: 700;
              line-height: 13px;
            }

            td[rowspan],
            td[colspan] {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }

        tbody {
          tr {
            transition: $transition-01;

            &:hover {
              background-color: #e6ecf6;
            }

            td {
              color: #000;
              font-family: $main-font;
              font-size: 13px;
              font-weight: 400;
              line-height: 15px;
            }
          }
        }
      }
    }
  }

  & > .container > .news-list {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 25px;

    .news-item {
      a.title {
        max-width: 100%;
        width: 100%;
        text-align: center;
        color: #000;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-transform: none;
        text-decoration: none;
      }
    }
  }
}
