@media (min-width: 769px) and (max-width: 1200px) {
  .contacts-page__wrapper {
    .container {
      margin-top: 40px;

      .contacts__content {
        max-width: unset;
        flex: 0 1 49%;

        .contacts__content-title {
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
        }
      }

      .contacts__form {
        max-width: unset;
        flex: 0 1 49%;
        margin-top: 0;

        & > div {
          form {
            & > p {
              flex: 0 1 auto;
              width: 100%;

              .errortext {
                color: red;
              }

              .notetext {
                color: #3162b9;
              }
            }

            .form-ask {
              .form-ask__item {
                max-width: 100%;
                flex: 0 1 auto;

                &.button {
                  input[type=submit] {
                    width: 100%;
                    flex: 0 1 auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}