.front-page-gallery {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .front-page-gallery__heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      max-width: 100%;
      width: 100%;
      margin-bottom: 25px;

      &-title {
        color: #000;
        font-family: $main-font;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        text-transform: uppercase;
      }

      &-link {
        color: #3162b9;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-decoration: underline;
      }
    }

    .news-list {
      display: grid;
      max-width: 100%;
      width: 100%;

      .grid-sizer {
        width: 15%;
      }

      .gutter-sizer {
        width: 2%;
      }

      .news-item {
        max-width: 15%;
        width: 100%;
        height: 127px;
        margin-bottom: 15px;

        /*&:nth-of-type(3n+3) {
          max-width: 32%;
          width: 100%;
          height: 263px;
        }*/

        &:nth-of-type(0),
        &:nth-of-type(3),
        &:nth-of-type(6),
        &:nth-of-type(9),
        &:nth-of-type(12) {
          max-width: 32%;
          width: 100%;
          height: 263px;
        }

        a {
          width: 100%;
          height: 100%;

          .preview_picture {
            width: 100%;
            height: 100%;
            background-position: 50% 50%;
            -webkit-background-size: 100%;
            background-size: 100%;
            background-repeat: no-repeat;
            transition: $transition;
          }
        }

        &:hover {
          a .preview_picture {
            -webkit-background-size: 105%;
            background-size: 105%;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}