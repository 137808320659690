.contacts-page__wrapper {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 252px);

  .contacts__map {
    max-width: 100%;
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
    margin-bottom: 50px;

    .contacts__content {
      max-width: 50%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .contacts__content-title {
        max-width: 100%;
        width: 100%;
        color: #000;
        font-family: $main-font;
        font-size: 20px;
        font-weight: 700;
        line-height: 25px;
        margin-bottom: 15px;
        text-align: left;
        text-transform: none;
      }

      .contacts__content-item {
        max-width: 100%;
        width: 100%;
        text-align: left;
        color: #000;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;

        &:last-of-type {
          margin-top: 35px;
        }

        a {
          color: #000;
          text-decoration: none;
        }
      }
    }

    .contacts__form {
      max-width: 50%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 40px;

        form {
          max-width: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          & > p {
            max-width: 100%;
            width: 100%;

            .errortext {
              font-family: $main-font;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
            }

            .notetext {
              font-family: $main-font;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              color: #3162b9;
            }
          }

          .form-ask {
            max-width: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
          }

          .form-ask__item {
            max-width: calc((100% - 18px) / 2);
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 16px;

            &.textarea {
              max-width: 100%;

              .inputtextarea {
                max-width: 100%;
                width: 100%;
                border-radius: 5px;
                border: 1px solid #e5e5e5;
                background-color: transparent;
                padding-left: 12px;
                outline: none;
                color: #000;
                font-family: $main-font;
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
              }
            }

            &.privacy {
              display: inline;
              max-width: 100%;
              color: #737373;
              font-family: $main-font;
              font-size: 12px;
              font-weight: 400;
              line-height: 14px;

              a {
                color: #000;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
              }
            }

            span {
              color: #000;
              font-family: $main-font;
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;

              font .form-required {
                color: #3162b9;
              }
            }

            .inputtext {
              max-width: 100%;
              width: 100%;
              height: 33px;
              border-radius: 5px;
              border: 1px solid #e5e5e5;
              background-color: transparent;
              padding-left: 12px;
              outline: none;
              color: #000;
              font-family: $main-font;
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
            }

            &.button {
              align-items: flex-end;

              input[type="submit"] {
                width: 248px;
                height: 40px;
                box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                border-radius: 50px;
                background-color: #3162b9;
                color: #fff;
                font-family: $main-font;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                text-decoration: none;
                border: none;
                outline: none;
              }
            }
          }
        }
    }
  }
}