.price-page__links-title {
  display: none;
}

@media screen and (max-width: 425px) {
  .price-page__wrapper {
    .container {
      .price-page__title {
        strong {
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
        }
      }

      .price-page__links {
        max-width: 100%;
        width: 100%;

        .price-page__links-row {
          max-width: 100%;
          width: 100%;
          display: block;
          flex-direction: column;
          align-items: flex-start;
          height: 44px;
          border-radius: 30px;
          border: 1px solid #cecdca;
          overflow: hidden;
          position: relative;
          transition: $transition-01;

          &::after {
            position: absolute;
            right: 22px;
            top: 22px;
            transform: translateY(-50%);
            content: '';
            background-image: url(../../images/svg/caret-litle.svg);
            background-position: 50% 50%;
            -webkit-background-size: contain;
            background-size: contain;
            background-repeat: no-repeat;
            width: 9px;
            height: 6px;
            transition: $transition-01;
          }

          &.active {
            height: auto;

            &::after {
              transform: translateY(-50%) rotate(180deg);
            }
          }

          .price-page__links-title {
            max-width: 100%;
            width: 100%;
            height: 44px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            color: #000;
            font-family: $main-font;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            padding-left: 20px;
          }

          .price-page__links-item {
            max-width: 100%;
            width: 100%;
            height: 44px;
            border-radius: 0;
          }

          .cancel {
            max-width: 100%;
            width: 100%;
            height: 44px;
            line-height: 44px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .table__wrapper {
        table.table__price {
          thead {
            font-size: 12px;
            line-height: 14px;

            tr:first-of-type {
              td:nth-of-type(1) {
                width: 15%;
              }

              td:nth-of-type(2) {
                width: 20%;
              }

              td:nth-of-type(3) {
                width: 20%;
              }

              td:nth-of-type(4) {
                width: 45%;
              }
            }

            tr:nth-of-type(2) {
              td {
                width: 22.5%;
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
              }
            }
          }
        }
      }

      .form {
        margin-bottom: 40px;

        & > div {
          form {
            padding: 35px 15px 0;

            & > p {
              max-width: 100%;
              width: 100%;

              .errortext {
                color: red;
              }

              .notetext {
                color: #3162b9;
              }
            }

            .form__title {
              font-size: 18px;
              font-weight: 700;
              line-height: 21px;
              text-transform: uppercase;
              margin-bottom: 13px;
            }

            .form__wrapper {
              .form__row {
                flex-direction: column;
                max-width: 100%;
                width: 100%;

                .form__item {
                  height: unset !important;
                  margin-bottom: 25px;

                  &.name {
                    order: 1;
                  }

                  &.phone {
                    order: 2;
                  }

                  &.email {
                    order: 3;
                  }

                  &.captcha {
                    order: 4;
                    max-width: 100%;
                  }

                  &.privacy {
                    order: 5;
                    max-width: 100%;
                  }

                  &.submit-btn {
                    max-width: 100%;
                    order: 6;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}