.gallery-page-item__wrapper {
  max-width: 100%;
  width: 100%;
  min-height: calc(100vh - 307px);

  .container {
    padding-top: 50px;

    .news-detail {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 55px;

      h3 {
        max-width: 100%;
        width: 100%;
        text-align: left;
        color: #000;
        font-family: $main-font;
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;
        text-transform: uppercase;
        margin-bottom: 25px;
      }

      .news-detail-wrapper {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .news-detail__item {
          max-width: calc((100% - 21px) / 4);
          width: 100%;
          margin-bottom: 7px;
          height: 191px;
          overflow: hidden;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          -webkit-background-size: cover;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          cursor: pointer;

          &:not(:nth-of-type(4n+4)) {
            margin-right: 7px;
          }
        }
      }

      .news-detail__text {
        max-width: 100%;
        width: 100%;
        margin-top: 25px;
        text-align: left;
        color: #737373;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
}

