.profiles-page__wrapper {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: calc(100vh - 589px);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .profiles-page__title {
      max-width: 100%;
      width: 100%;
      color: #000;
      font-family: $main-font;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      text-transform: uppercase;
      margin: 50px 0 25px;
      text-align: left;
    }

    .profiles-page__text {
      max-width: 100%;
      width: 100%;
      color: #000;
      font-family: $main-font;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      margin-bottom: 40px;
      
      ul {
        list-style: disc !important;;
        padding-left: 30px !important;;
      }
      
      ol {
        list-style: decimal !important;;
        padding-left: 30px !important;
      }
    }

    .profiles-page__list-wrapper {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .profiles-page__list-title {
        max-width: 100%;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        color: #000;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        text-transform: uppercase;
      }

      .profiles-page__list {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 15px;

        .profiles-page__list-item {
          max-width: 297px;
          width: 100%;
          margin-bottom: 44px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          &:not(:nth-of-type(3n+3)) {
            margin-right: 33px;
          }

          .profiles-page__list-item-link {
            max-width: 100%;
            width: 100%;
            height: 165px;
            margin-bottom: 26px;
            border-radius: 2px;
            border: 1px solid #e5e5e5;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: $transition-01;

            .preview_picture {
              max-width: 80%;
              width: 100%;
              height: 80%;
              background-position: 50% 50%;
              -webkit-background-size: contain;
              background-size: contain;
              background-repeat: no-repeat;
              transition: $transition-01;
            }

            &:hover {
              box-shadow: $box-shadow;
              background-color: rgba(49, 98, 185, 0.05);

              .preview_picture {
                max-width: 95%;
                height: 95%;
              }
            }
          }

          .profiles-page__list-item-title {
            max-width: 100%;
            width: 100%;
            text-align: center;
            color: #000;
            font-family: $main-font;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            text-transform: uppercase;
            text-decoration: none;
          }
        }
      }

      .news-list {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 25px;

        .news-item {
          a.title {
            max-width: 100%;
            width: 100%;
            text-align: center;
            color: #000;
            font-family: $main-font;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-transform: none;
            text-decoration: none;
          }
        }
      }
    }

    .profiles-page__list-dop-links {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 25px;

      span {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        a {
          color: #000;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-transform: none;
          text-decoration: none;
        }
      }
    }
  }
}