@media screen and (max-width: 425px) {
  .contacts-page__wrapper {
    .container {
      margin-top: 40px;
      flex-direction: column;

      .contacts__content {
        max-width: 100%;

        .contacts__content-title {
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
        }

        .contacts__content-item {
          margin-bottom: 25px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;

          &:last-of-type {
            margin-top: 0;
          }

          span {
            max-width: 100%;
            width: 100%;
            text-align: left;
            font-weight: bold;
          }
        }
      }

      .contacts__form {
        max-width: 100%;
        margin-top: 0;

        & > div {
          form {
            & > p {
              max-width: 100%;
              width: 100%;

              .errortext {
                color: red;
              }

              .notetext {
                color: #3162b9;
              }
            }

            .form-ask {
              .form-ask__item {
                max-width: 100%;

                &.button {
                  input[type=submit] {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}