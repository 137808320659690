.footer {
  max-width: 100%;
  width: 100%;
  height: 150px;
  background-color: #0c1d3b;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .footer__wrapper {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: calc(100% - 33px);

    .container {
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .footer__menu {
        max-width: 56%;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        ul {
          max-width: 100%;
          width: 100%;
          height: 50%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;

          li {
            max-width: 25%;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            a {
              color: #cecdca;
              font-family: $main-font;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              text-decoration: none;

              &:visited {
                color: #cecdca;
                text-decoration: none;
              }
            }
          }
        }
      }

      .footer__contacts {
        max-width: 44%;
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .footer__contacts-item--adress {
          max-width: 100%;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .footer__contacts-subtitle {
            color: #cecdca;
            font-family: $main-font;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            margin-right: 12px;
          }

          .footer__contacts-adress {
            color: #cecdca;
            font-family: $main-font;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
          }
        }

        .footer__contacts-item--phone {
          max-width: 50%;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .footer__contacts-subtitle {
            color: #cecdca;
            font-family: $main-font;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            margin-right: 12px;
          }

          .footer__contacts-text a {
            color: #3162b9;
            font-family: $main-font;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            text-decoration: none;
          }
        }

        .footer__contacts-item--email {
          max-width: 50%;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .footer__contacts-subtitle {
            color: #cecdca;
            font-family: $main-font;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            margin-right: 12px;
          }

          .footer__contacts-text a {
            color: #3162b9;
            font-family: $main-font;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
          }
        }
      }
    }
  }

  .copyrights {
    max-width: 100%;
    width: 100%;
    border-top: 1px solid #737373;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 33px;

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .copyrights__year {
        max-width: 60%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: #cecdca;
        font-family: $main-font;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }

      .copyrights__trend {
        max-width: 40%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        color: #cecdca;
        font-family: $main-font;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;

        a {
          color: #cecdca;
          text-decoration: none;
        }
      }
    }
  }
}

@import 'footer-modal';