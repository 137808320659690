.front-page-prefences {
  max-width: 100%;
  width: 100%;
  height: 208px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;

    .front-page-prefences__item {
      max-width: 25%;
      width: 100%;
      display: flex;
      flex-direction: column !important;
      justify-content: center;
      align-items: flex-start;
      height: 60%;
      padding: 0 25px 0 25px;

      &:first-of-type {
        padding: 0 25px 0 0;
      }

      &:not(:last-of-type) {
        border-right: 1px solid #e5e5e5;
      }

      .front-page-prefences__item-title {
        font-family: $main-font;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.7px;
        line-height: 16px;
        margin-bottom: 20px;
        text-transform: uppercase;
      }

      .front-page-prefences__item-text {
        color: $main;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
}