@media (min-width: 426px) and (max-width: 768px) {
  .breadcrumbs__wrapper {
    .container {
      height: 100%;
      .bx-breadcrumb {
        overflow-x: scroll;
        height: 100%;

        .bx-breadcrumb-item {
          min-width: max-content;
          height: 100%;
        }
      }
    }
  }
}