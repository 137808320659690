* {
  box-sizing: border-box;
}

.container {
  max-width: 1200px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}
