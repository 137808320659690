.news-item-page {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 335px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 50px 165px 0;

    .news-item-page__date-time {
      color: #3162b9;
      font-family: $main-font;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      max-width: 100%;
      width: 100%;
      text-align: left;
      margin-bottom: 18px;
    }

    .news-item-page__title {
      color: #000;
      font-family: $main-font;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      max-width: 100%;
      width: 100%;
      text-align: left;
      margin-bottom: 18px;
      text-transform: uppercase;
    }

    .news-item-page__detail-text,
    .news-item-page__preview-text {
      color: #737373;
      font-family: $main-font;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      max-width: 100%;
      width: 100%;
      text-align: left;
      margin-bottom: 18px;

      a {
        color: #3162b9;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        text-decoration: none;
      }
    }
  }
}

.more-news {
  margin-top: 50px;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 150px;

    .more-news__title {
      max-width: 100%;
      width: 100%;
      text-align: left;
      color: #000;
      font-family: $main-font;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
      padding: 0 15px;
      margin-bottom: 15px;
    }

    .news-list {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .news-item {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        transition: $transition-01;
        border-radius: 5px;
        padding: 20px 15px;

        &:hover {
          transform: scale(1.025);
          box-shadow: $box-shadow;
        }

        .news-date-time {
          color: #3162b9;
          font-family: $main-font;
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
          max-width: 100%;
          width: 100%;
          text-align: left;
          margin-bottom: 18px;
        }

        a.title {
          color: #000;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          max-width: 100%;
          width: 100%;
          text-align: left;
          margin-bottom: 12px;
          text-decoration: none;
        }

        .preview-text {
          color: #737373;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          max-width: 100%;
          width: 100%;
          text-align: left;
          margin-bottom: 25px;
        }

        .showmore-link {
          color: #3162b9;
          font-family: $main-font;
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          max-width: 100%;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}