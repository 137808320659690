.modals__wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);

  .modal__buy {
    margin: 200px auto 0;
    display: none;

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      & > div {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        form {
          max-width: 50%;
          width: 100%;
          background-color: #fff;
          padding: 25px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          position: relative;

          .close {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 10px;
            height: 10px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-image: url(/bitrix/templates/trendspb_enerpro/images/svg/cancel.svg);
            background-position: 50% 50%;
            -webkit-background-size: 100%;
            background-size: 100%;
            background-repeat: no-repeat;
          }

          & > p {
            max-width: 100%;
            width: 100%;

            .errortext {
              font-family: $main-font;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
            }

            .notetext {
              font-family: $main-font;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              color: #3162b9;
            }
          }

          .form-title {
            max-width: 100%;
            width: 100%;
            text-align: left;
            color: #000;
            font-family: $main-font;
            font-size: 20px;
            font-weight: 700;
            line-height: 23px;
            margin-bottom: 15px;
          }

          .form-descr {
            max-width: 100%;
            width: 100%;
            text-align: left;
            color: #000;
            font-family: $main-font;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            margin-bottom: 15px;
          }

          .form-ask {
            max-width: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-top: 15px;

            .form-ask__item {
              max-width: 100%;
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 15px;
              min-height: 30px;

              &.captcha {
                display: none;

                &.active {
                  display: flex;
                }
              }

              br {
                display: none;
              }

              img {
                max-width: 23%;
                width: 100%;
                height: 100%;
              }

              span {
                max-width: 25%;
                width: 100%;
                text-align: left;
                color: #000;
                font-family: $main-font;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;

                font {
                  color: #3162b9;
                }
              }

              select,
              input,
              textarea {
                max-width: 75%;
                width: 100%;
                border: none;
                border-bottom: 2px solid #3162b9;
                height: 100%;
                outline: none;
                font-family: "FuturaFuturisC", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                position: relative;
                z-index: 5;
                background-color: transparent;
              }

              input {
                padding-left: 7px;
              }

              input[type="submit"] {
                max-width: 50%;
                width: 100%;
                height: 40px;
                box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                border-radius: 50px;
                background-color: #3162b9;
                color: #fff;
                font-family: "FuturaFuturisC", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                text-decoration: none;
                display: block;
                text-align: center;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: none;
                cursor: pointer;
                outline: none;
                margin: 25px auto 0;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }

  .modal__request {
    margin: 100px auto 0;
    display: none;

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      & > div {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        form {
          max-width: 50%;
          width: 100%;
          background-color: #fff;
          padding: 25px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          position: relative;

          .close {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 10px;
            height: 10px;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background-image: url(/bitrix/templates/trendspb_enerpro/images/svg/cancel.svg);
            background-position: 50% 50%;
            -webkit-background-size: 100%;
            background-size: 100%;
            background-repeat: no-repeat;
          }

          & > p {
            max-width: 100%;
            width: 100%;

            .errortext {
              font-family: $main-font;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
            }

            .notetext {
              font-family: $main-font;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
              color: #3162b9;
            }
          }

          .form-title {
            max-width: 100%;
            width: 100%;
            text-align: left;
            color: #000;
            font-family: $main-font;
            font-size: 20px;
            font-weight: 700;
            line-height: 23px;
            margin-bottom: 15px;
          }

          .form-descr {
            max-width: 100%;
            width: 100%;
            text-align: left;
            color: #000;
            font-family: $main-font;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            margin-bottom: 15px;
          }

          .form-ask {
            max-width: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-top: 15px;

            .form-ask__item {
              max-width: 100%;
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 15px;
              min-height: 30px;

              &.captcha {
                display: none;

                &.active {
                  display: flex;
                }
              }

              br {
                display: none;
              }

              img {
                max-width: 23%;
                width: 100%;
                height: 100%;
              }

              span {
                max-width: 25%;
                width: 100%;
                text-align: left;
                color: #000;
                font-family: $main-font;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;

                font {
                  .form-required {
                    color: #3162b9 !important;
                  }
                }
              }

              select,
              input,
              textarea {
                max-width: 75%;
                width: 100%;
                border: none;
                border-bottom: 2px solid #3162b9;
                height: 100%;
                outline: none;
                font-family: "FuturaFuturisC", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                position: relative;
                z-index: 5;
                background-color: transparent;
              }

              input {
                padding-left: 7px;
              }

              input[type="submit"] {
                max-width: 50%;
                width: 100%;
                height: 40px;
                box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
                border-radius: 50px;
                background-color: #3162b9;
                color: #fff;
                font-family: "FuturaFuturisC", sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                text-decoration: none;
                display: block;
                text-align: center;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: none;
                cursor: pointer;
                outline: none;
                margin: 25px auto 0;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}