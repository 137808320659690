.search-page__wrapper {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: calc(100vh - 252px);

  .container {
    padding: 50px 150px;

    h1 {
      color: #000;
      font-family: $main-font;
      font-size: 20px;
      font-weight: 700;
      line-height: 23px;
      max-width: 100%;
      width: 100%;
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 25px;
    }

    .search-page {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      p {
        font-family: $main-font;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
      }

      .search-page__form {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0;

        input[name="q"] {
          width: 50%;
          height: 40px;
          box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
          background-color: rgba(255, 255, 255, 1);
          outline: none;
          border: 1px solid #3162b9;
          border-radius: 50px;
          padding-left: 15px;
          font-family: $main-font;
          line-height: 1;
          color: $main;
          transition: $transition;

          &::placeholder {
            color: $main;
          }
        }

        select[name="where"] {
          width: 25%;
          height: 40px;
          padding-left: 15px;
          box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
          background-color: rgba(255, 255, 255, 1);
          outline: none;
          border: 1px solid #3162b9;
          border-radius: 50px;
          font-family: $main-font;
          line-height: 1;
          color: $main;
          transition: $transition;
        }

        input[type="submit"] {
          width: 25%;
          height: 40px;
          box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
          border-radius: 50px;
          background-color: #3162b9;
          color: #fff;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          text-decoration: none;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          transition: 0.2s ease-in-out;
          cursor: pointer;
          border: none;
          outline: none;
        }
      }

      .search-page__item {
        max-width: 100%;
        width: 100%;
        flex-direction: column;
        transition: $transition-01;
        border-radius: 5px;
        padding: 20px 15px;

        &:hover {
          transform: scale(1.025);
          box-shadow: $box-shadow;
        }

        &-title {
          color: #000;
          font-family: $main-font;
          font-size: 20px;
          font-weight: 500;
          line-height: 20px;
          max-width: 100%;
          width: 100%;
          text-align: left;
          text-decoration: none;
        }

        &-text {
          color: #737373;
          font-family: $main-font;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          max-width: 100%;
          width: 100%;
          text-align: left;
          margin-top: 25px;
          margin-bottom: 25px;
        }

        &-date,
        &-path,
        &-path a {
          color: #3162b9;
          font-family: $main-font;
          font-size: 14px;
          font-weight: 700;
          line-height: 19px;
          max-width: 100%;
          width: 100%;
          text-align: left;
        }

        &-date {
          display: none;
        }
      }

      .modern-page-navigation {
        margin-top: 25px;
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        & * {
          border: none;
        }

        .modern-page-title,
        .modern-page-previous,
        .modern-page-next {
          display: none;
        }

        a {
          color: #000;
          font-family: $main-font;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          text-decoration: none;
        }

        .modern-page-current {
          color: #3162b9;
          font-family: $main-font;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
          text-decoration: underline;
        }
      }

      .sort {
        max-width: 100%;
        width: 100%;
        display: none;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0 15px;
        font-family: $main-font;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
      }
    }
  }
}