.news-list-page {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 150px;

    .news-item {
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      transition: $transition-01;
      border-radius: 5px;
      padding: 20px 15px;

      &:hover {
        transform: scale(1.025);
        box-shadow: $box-shadow;
      }

      .news-date-time {
        color: #3162b9;
        font-family: $main-font;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        max-width: 100%;
        width: 100%;
        text-align: left;
        margin-bottom: 18px;
      }

      .news-title-link {
        color: #000;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        max-width: 100%;
        width: 100%;
        text-align: left;
        margin-bottom: 12px;
        text-decoration: none;
      }

      .news-preview-text {
        color: #737373;
        font-family: $main-font;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        max-width: 100%;
        width: 100%;
        text-align: left;
        margin-bottom: 25px;
      }

      .news-showmore-link {
        color: #3162b9;
        font-family: $main-font;
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        max-width: 100%;
        width: 100%;
        text-align: left;
      }
    }

    .modern-page-navigation {
      margin-top: 25px;
      max-width: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      & * {
        border: none;
      }

      .modern-page-title,
      .modern-page-previous,
      .modern-page-next {
        display: none;
      }

      a {
        color: #000;
        font-family: $main-font;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-decoration: none;
      }

      .modern-page-current {
        color: #3162b9;
        font-family: $main-font;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        text-decoration: underline;
      }
    }
  }
}